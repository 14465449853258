export const bengaliLangData = {
  MEDICAL_INFO_SYSTEM: 'মেডিকেল ইনফরমেশন সিস্টেম',
  ACTIVE_EVENTS: 'সক্রিয় ইভেন্টগুলি',
  SELECT_AN_EVENT_TO_BROWSE_USERS: 'ব্যবহারকারীদের ব্রাউজ করতে একটি ইভেন্ট নির্বাচন করুন।',
  BACK_TO_THE_EVENT_SELECTION: 'ইভেন্ট নির্বাচন ফিরে',
  IF_YOU_FORGOT_YOUR_PASSWORD: 'যদি আপনি নিজের পাসওয়ার্ড ভুলে যান',
  LOGIN: 'প্রবেশ করুন',
  RESET_IT_HERE: 'এটি এখানে পুনরায় সেট করুন',
  CHANGE_LANGUAGE_TO: 'ভাষা পরিবর্তন করুন',
  TEST_SYSTEM: 'পরীক্ষা পদ্ধতি',
  HELP: 'সাহায্যের',
  THIS_IS_TEST_ENV: 'এটি একটি পরীক্ষার পরিবেশ, আপনি যদি সত্যিকারের তদন্ত সন্নিবেশ করতে চান তবে যান',
  USER_OR_PASS_IS_INCORRECT: 'ব্যাবহারকারীর নাম অথবা গোপন নাম্বারটি ভুল',
  OPS: 'অপস',
  WE_DID_NOT_FIND: 'আপনি যা খুঁজছিলেন তা আমরা খুঁজে পাইনি',
  VIDEO_TUTORIALS: 'ভিডিও টিউটোরিয়াল',
  STILL_DIDNT_FIND: 'আপনি যা সন্ধান করছেন তা এখনও খুঁজে পায়নি,',
  ASK_THE_SUPPORT_TEAM: 'সমর্থন দল জিজ্ঞাসা করুন',
  LOGGED_IN_AS: 'লগ ইন করুন',
  MY_PROFILE: 'আমার প্রোফাইল',
  LOGOUT: 'প্রস্থান',
  RETRIEVING_YOUR_ACCESS_INFO: 'আপনার অ্যাক্সেসের তথ্য পুনরুদ্ধার করা হচ্ছে',
  LOGGED_IN_CAN_CLOSE: 'লগ ইন, আপনি এখন এই ট্যাবটি বন্ধ করতে পারেন',
  TOKEN_HAS_NOT_BEEN_SAVED:
    'টোকেন সঠিকভাবে সংরক্ষণ করা যায় নি, দয়া করে সহায়তার সাথে যোগাযোগ করুন',
  PASSWORD_RESET: 'পাসওয়ার্ড পুনরায় সেট করুন',
  RESET_PASSWORD: 'পাসওয়ার্ড রিসেট করুন',
  INSERT_YOUR_EMAIL_TO_RESET_PASS:
    'আপনার পাসওয়ার্ড পুনরায় সেট করতে দয়া করে আপনার ইমেল প্রবেশ করান',
  CANCEL: 'বাতিল',
  CANCEL_UPPERCASE: 'বাতিল',
  RETURN_TO_LOGIN: 'লগইন ফিরে',
  USERNAME: 'ব্যবহারকারীর নাম',
  USERNAME_IS_REQUIRED: 'ইউজার নাম দরকার',
  PASSWORD: 'পাসওয়ার্ড',
  PASSWORD_IS_REQUIRED: 'পাসওয়ার্ড প্রয়োজন',
  FIRST_NAME: 'নামের প্রথম অংশ',
  LAST_NAME: 'নামের শেষাংশ',
  REGISTER: 'নিবন্ধন',
  LOADING: 'বোঝাই',
  INSERT_NEW_PASS: 'দয়া করে আপনার নতুন পাসওয়ার্ড sertোকান',
  VERIFYING_TOKEN: 'টোকেন যাচাই করা হচ্ছে',
  PASSWORD_DO_NOT_MATCH: 'পাসওয়ার্ড মিলছে না',
  PASS_RESET_SUCCESSFUL: 'পাসওয়ার্ড পুনরায় সেট করা সফল',
  SAVE_NEW_PASS: 'নতুন পাসওয়ার্ড সংরক্ষণ করুন',
  GO_TO_INBOX: 'ইনবক্সে যান',
  SELECT_ALL: 'সমস্ত নির্বাচন করুন',
  DESELECT_ALL: 'সব গুলো অনির্বাচিত কর',
  DO_NOT_SHOW_AGAIN: 'আবার প্রদর্শন করো না',
  INQUIRY: 'অনুসন্ধান',
  ATTACHMENT_WITH_THIS_INQUIRY: '> এই তদন্তের সাথে সংযুক্তি',
  USER_DETAILS: 'ব্যবহারকারীর বৃত্যান্ত',
  CLOSE: 'ঘনিষ্ঠ',
  TEAMS: 'দলসমূহ',
  TEAM: 'টীম',
  SPECIALIZATION_IS: 'বিশেষত্ব হয়',
  REQUIRED: 'প্রয়োজনীয়',
  RESULTS_PER_PAGE: 'প্রতি পৃষ্ঠায় ফলাফল',
  RESULTS_FOUND: 'ফলাফল পাওয়া গেছে',
  EXPORT: 'রপ্তানি',
  PERSONAL_SETTINGS: 'ব্যাক্তিগত সেটিংস',
  TEAM_SETTINGS: 'টিম সেটিংস',
  USER_SETTINGS_OF: 'ব্যবহারকারী সেটিং অফ',
  SET_TEMPLATE: 'টেম্পলেট সেট করুন',
  NOTIFICATIONS_WILL_BE_OVERWRITTEN: 'বিজ্ঞপ্তিগুলি বর্তমানগুলির সাথে ওভাররাইট করা হবে',
  PREVIEW: 'পূর্বরূপ',
  PREVIEW_AND_SEND: 'প্রিভিউ এবং পাঠান',
  NOTIFICATIONS: 'বিজ্ঞপ্তিগুলি',
  ROLE: 'ভূমিকা',
  TEMPLATE_NAME: 'টেম্পলেট নাম',
  SAVE_THIS_USER_AS_A_TEMPLATE: 'এই ব্যবহারকারীকে একটি টেম্পলেট হিসাবে সংরক্ষণ করুন',
  ADD_A_TEMPLATE_TO_YOUR_USER:
    'আপনার ব্যবহারকারীর সাথে একটি টেম্পলেট যুক্ত করুন এবং বিজ্ঞপ্তিগুলির জন্য ভূমিকা এবং পছন্দগুলি অনুলিপি করুন',
  AUTH_AND_NOTIFY_TEMPLATE: 'অনুমোদন এবং বিজ্ঞপ্তি টেম্পলেট',
  MY_ROLES: 'আমার ভূমিকা',
  STATUS: 'স্থিতি',
  THIS_ROLE_IS_TEMP: 'এই ভূমিকাটি অস্থায়ী, এই ব্যবহারকারীর ডেপুটি হওয়ার কারণে এটি দেওয়া হয়েছে',
  CREATED: 'CREATED',
  APPROVED_REJECTED: 'অনুমোদন দেওয়া / বাতিল',
  APPROVED_REJECTED_BY: 'অনুমোদিত / প্রত্যাখ্যানিত',
  ARCHIVE: 'সংরক্ষণাগার',
  SAVE: 'সংরক্ষণ',
  IMMEDIATE_EFFECT: 'তাত্ক্ষণিক প্রভাব',
  OUT_OF_OFFICE_DEPUTY: 'অফিস ডিফল্টির বাইরে',
  AUTO_ASSIGN_TO: 'স্বয়ং অ্যাসাইনগ টু',
  GEOGRAPHY: 'ভূগোল',
  ALIGNMENT: 'শ্রেণীবিন্যাস',
  Alignments: 'alignments',
  Address: 'ঠিকানা',
  PHONE_FAX: 'ফোন / ফ্যাক্স',
  Email: 'ইমেইল',
  Contacts: 'যোগাযোগ',
  WORK_INFO: 'কাজের তথ্য',
  LAST_NAME_IS: 'শেষ নাম',
  PERSONAL_INFO: 'ব্যক্তিগত তথ্য',
  USER: 'ব্যবহারকারী',
  ID: 'আইডি',
  UPDATED_FROM: 'থেকে আপডেট',
  ON: 'চালু',
  CRM: 'সিআরএম',
  IMPORTED_FROM: 'থেকে আমদানি করা',
  CHANGE_PASS: 'পাসওয়ার্ড পরিবর্তন করুন',
  NOTIFICATION_IN_TEMPLATE: 'টেমপ্লেটে বিজ্ঞপ্তি',
  CONTINUE: 'চালিয়ে',
  DONT_SHOW_AGAIN: 'এই বার্তাটি আবার দেখাবেন না',
  USING_THE_TABS_ON_THE: 'স্ক্রিনের উপরের ট্যাবগুলি ব্যবহার করে',
  PERSONAL_PROFILE_SETTINGS: 'ব্যক্তিগত প্রোফাইল সেটিংস',
  AND_YOUR: 'এবং তোমার',
  YOU_CAN_SWITCH_BETWEEN: 'আপনি আপনার মধ্যে পরিবর্তন করতে পারেন',
  WELCOME_TO_YOUR_USER_PROFILE: 'আপনার ব্যবহারকারী প্রোফাইলে আপনাকে স্বাগতম',
  SAVE_THIS_USER_AS: 'এই ব্যবহারকারীকে টেম্পলেট হিসাবে সংরক্ষণ করুন',
  INCLUDE_NOTIFY_SETTINGS: 'বিজ্ঞপ্তি সেটিংস অন্তর্ভুক্ত',
  INCLUDE_ROLES_SETTINGS: 'ভূমিকা সেটিংস অন্তর্ভুক্ত',
  ACTIVE_NOTIFICATIONS: 'সক্রিয় বিজ্ঞপ্তি',
  SET_THIS_USER_AS_A_: 'এই ব্যবহারকারী অনুমোদন এবং বিজ্ঞপ্তিগুলিকে নতুন টেম্পলেট হিসাবে সেট করুন',
  REQUEST_AUTH: 'অনুমোদনের অনুরোধ',
  REQUEST: 'অনুরোধ',
  FEEDBACK_SUPPORT: 'মতামত এবং সমর্থন',
  IF_YOU_STILL_CANNOT_FIND_WHAT_:
    'আপনি যা খুঁজছেন তা যদি এখনও খুঁজে না পান তবে সহায়তা দলকে জিজ্ঞাসা করুন! এছাড়াও ভবিষ্যতের উন্নতির জন্য আপনার কাছে যদি কোনও পরামর্শ থাকে তবে এটি সঠিক জায়গা।',
  TEMPLATE: 'টেমপ্লেট',
  SIGNATURE: 'স্বাক্ষর',
  Disclaimers: 'দাবিত্যাগ',
  Greetings: 'গ্রিটিংস',
  CUSTOMIZE: 'কাস্টোমাইসড',
  SAVE_UPPERCASE: 'সংরক্ষণ',
  DELETE: 'মুছে ফেলা',
  RESET_TO_DEFAULT: 'খেলতে হবে পুনরায় সেট করুন',
  AFTER_SIGNATURE: 'স্বাক্ষর পরে',
  BEFORE_SIGNATURE: 'স্বাক্ষরের আগে',
  AFTER_ANSWER: 'উত্তর পরে',
  BEFORE_ANSWER: 'উত্তর দেওয়ার আগে',
  AFTER_GREET: 'শুভেচ্ছার পরে',
  BEFORE_GREET: 'শুভেচ্ছা আগে',
  Any: 'কোন',
  HCP: 'HCP',
  NO_HCP: 'এইচসিপি নেই',
  Position: 'অবস্থান',
  ON_LABEL: 'লেবেলে',
  OFF_LABEL: 'লেবেল বন্ধ',
  YOU_ARE_TRYING_TO_CUSTOMIZE: 'আপনি টেম্পলেটটি কাস্টমাইজ করার চেষ্টা করছেন',
  YOU_DO_NOT_HAVE_THE_PERMISSON:
    'আপনার এটি করার অনুমতি নেই। আপনি যদি এগিয়ে যেতে চান তবে টেমপ্লেটের একটি অনুলিপি তৈরি হবে এবং আপনি সেখানে পরিবর্তনগুলি করতে পারবেন।',
  CREATE_COPY: 'কপি তৈরি করুন',
  RECEIVED_ON: 'পেয়েছি',
  SENDER_EMAIL: 'প্রেরক ই-মেইল',
  SUBJECT: 'বিষয়',
  UNSPAM: 'Unspam',
  UPLOAD: 'আপলোড',
  APPROVED: 'অনুমোদিত',
  DRAFT: 'খসড়া',
  SELECT_STATUS: 'স্থিতি নির্বাচন করুন',
  UPLOAD_AS: 'হিসাবে আপলোড করুন',
  TITLE: 'শিরোনাম',
  REPOSITORY: 'সংগ্রহস্থলের প্রয়োগ',
  BROWSE: 'ব্রাউজ',
  URL: 'URL টি',
  APPROVED_ON: 'অনুমোদিত হয়েছে',
  AUTH_HOLDER: 'অনুমোদনের ধারক',
  AUTH_NUMBERS: 'অনুমোদনের নম্বর',
  APPROVED_FOR: 'জন্য অনুমোদিত',
  SELECT_ONE_REGION: 'একটি অঞ্চল নির্বাচন করুন',
  OWNED_BY: 'মালিক',
  KEYWORDS: 'কীওয়ার্ড',
  TOPICS: 'টপিক',
  CATEGORIES: 'ধরন',
  PRODUCTS: 'পণ্য',
  PRODUCT: 'প্রোডাক্ট',
  LANGUAGE_IS_REQUIRED: 'ভাষা প্রয়োজন',
  LANGUAGE: 'ভাষা',
  TARGET_CUSTOMERS: 'লক্ষ্য গ্রাহকদের',
  TYPE_IS_REQUIRED: 'প্রকারের প্রয়োজন',
  TYPE: 'আদর্শ',
  TITLE_IS_REQUIRED: 'শিরোনাম প্রয়োজন',
  DEFAULT_REFERENCE_IS_CREATED_:
    'ডিফল্ট রেফারেন্স ভ্যাঙ্কুবার স্টাইল দিয়ে তৈরি করা হয়। আপনি যদি ওভাররাইড করতে চান তবে পুরো রেফারেন্সটি এখানে প্রবেশ করুন',
  REFERENCE: 'উল্লেখ',
  DOI: 'ডোই',
  PUBMED_ID: 'প্রকাশিত আইডি',
  PAGES: 'পেজ',
  ISSUE: 'সমস্যা',
  VOLUME: 'আয়তন',
  JOURNAL: 'সাময়িক পত্রিকা',
  AUTHORS: 'লেখক',
  ABSTRACT: 'বিমূর্ত',
  REFERENCE_IS_REQUIRED: 'রেফারেন্স প্রয়োজন',
  THIS_DOC_IS_APPROVED: 'এই নথি অনুমোদিত। খুলতে এখানে ক্লিক করুন',
  ORIGINAL_DOCUMENT_AND_: 'মূল নথি এবং আপনার মন্তব্য সন্নিবেশ করান',
  EDIT_FILE: 'ফাইল সম্পাদনা করুন',
  PROPERTIES: 'প্রোপার্টি',
  OPEN: 'খোলা',
  LOCALIZED_DOCUMENTS: 'স্থানীয় নথি',
  ORIGINAL_DOCUMENTS: 'আসল কাগজপত্র',
  INTERNAL_APPROVAL_NUMBER: 'অভ্যন্তরীণ অনুমোদনের নম্বর',
  CREATED_BY: 'দ্বারা সৃষ্টি',
  EXPIRATION_DATE: 'মেয়াদ শেষ হওয়ার তারিখ',
  VERSION_NUMBER: 'সংস্করণ সংখ্যা',
  NO_VERSION_AVAILABLE: 'কোনও সংস্করণ উপলভ্য নয়',
  ARCHIVED_IF_NO_DRAFT: 'সংরক্ষণাগারভুক্ত: যদি কোনও খসড়া / অনুমোদিত সংস্করণ উপস্থিত না থাকে।',
  APPROVED_FOR_USERS: 'অনুমোদিত: ব্যবহারকারীদের জন্য',
  DRAFT_FOR_AUTHORS_: 'খসড়া: লেখক এবং সম্পাদকদের জন্য।',
  VERSION_LEGEND: 'সংস্করণ কিংবদন্তি',
  VERSION: 'সংস্করণ',
  SELECT_ONE_LANG: 'একটি ভাষা নির্বাচন করুন।',
  SELECT_ONE_TYPE: 'এক প্রকার নির্বাচন করুন।',
  INTERNAL_NUMBER_REQUIRED: 'অভ্যন্তরীণ অনুমোদনের নম্বর প্রয়োজন।',
  FILE: 'ফাইল',
  ATTACH_FILE: 'ফাইল সংযুক্ত',
  LITERATURE: 'সাহিত্য',
  SLIDE_DECK: 'স্লাইড ডেক',
  PIL: 'PIL',
  FAQ: 'প্রায়শই জিজ্ঞাসিত প্রশ্নাবলী',
  SRD: 'SRD',
  ADD_APPROVAL_STEP: 'অনুমোদনের পদক্ষেপ যুক্ত করুন',
  ADD_PARALLEL_STEP: 'সমান্তরাল পদক্ষেপ যুক্ত করুন',
  DELETE_STEP: 'পদক্ষেপ মুছুন',
  DELETE_ALL_PARALLEL_STEPS: 'সমস্ত সমান্তরাল পদক্ষেপ মুছুন',
  CHANGE_STATUS_NAME: 'স্থিতির নাম পরিবর্তন করুন',
  SET_REQUIRED_ROLE: 'প্রয়োজনীয় ভূমিকা সেট করুন',
  SET_TEAM_MEMBER: 'দলের সদস্য সেট করুন',
  YOU_DO_NOT_HAVE_THE_PERMISSION_TO_: 'আপনার এই প্রক্রিয়াটি পরিবর্তন করার অনুমতি নেই।',
  SAVE_ROLE: 'ভূমিকা সংরক্ষণ করুন',
  CREATE_NEW_WORKFLOW: 'নতুন ওয়ার্কফ্লাউ তৈরি করুন',
  CREATE_NEW_STEP: 'নতুন পদক্ষেপ তৈরি করুন',
  PLEASE_ENTER_THE_NUMBER_OF_:
    'সমান্তরাল অনুমোদনের পদক্ষেপের সংখ্যা লিখুন, অর্থ একই ধাপে অনুমোদিত যা পদক্ষেপগুলি (ক্রমানুসারে নয়)। আপনার যদি কোনও সমান্তরাল অনুমোদনের পদক্ষেপ না থাকে তবে 1 টি প্রবেশ করুন You আপনি পরে সর্বদা অনুমোদনের পদক্ষেপগুলি পরিবর্তন করতে পারেন।',
  APPROVAL_MUST_BE_AT_LEAST: 'অনুমোদন কমপক্ষে হতে হবে',
  INSERT_THE_NEW_STATUS_NAME: 'নতুন স্থিতির নাম .োকান',
  INSERT_THE_NEW_WORKFLOW_NAME: 'নতুন কর্মপ্রবাহের নাম .োকান।',
  INSERT_THE_NEW_STEP_NAME: 'নতুন পদক্ষেপের নাম .োকান।',
  NEW_DOCUMENT: 'নতুন দলিল',
  CREATE: 'সৃষ্টি',
  DOCUMENT_MANAGEMENT: 'নথি ব্যবস্থাপনা',
  CONFIRM: 'নিশ্চিত করুন',
  CONFIRM_PROCESS: 'কনফার্ম প্রসেস',
  DEFINE_APPROVAL_STEPS_AND_TEAM: 'অনুমোদিত পদক্ষেপ এবং দল নির্ধারণ করুন',
  SET_DETAILS: 'বিবরণ সেট করুন',
  SELECT_TYPE: 'টাইপ নির্বাচন করুন',
  COMPLETE_LOCALIZATION_PROCESS: 'সম্পূর্ণ স্থানীয়করণ প্রক্রিয়া',
  CREATE_NEW_VERSION: 'নতুন সংস্করণ তৈরি করুন',
  CREATE_NEW_DOCUMENT: 'নতুন ডকুমেন্ট তৈরি করুন',
  IS_PRIVATE: 'বেসরকারী',
  SHARED_WITH_OTHER_TEAMS: 'অন্যান্য দলের সাথে ভাগ করে নেওয়া',
  PLEASE_ENTER_THE_NUMBER_IF_SEQ_:
    'প্রয়োজনীয় ক্রম অনুমোদনের পদক্ষেপের সংখ্যা লিখুন। দুটি অনুমোদন সমান্তরাল হয়, এটি একটি পদক্ষেপ হিসাবে গণনা। আপনি সর্বদা অনুমোদনের পদক্ষেপগুলি পরে পরিবর্তন করতে পারেন।',
  DONE: 'সম্পন্ন',
  SUBMIT_FOR_APPROVAL: 'অনুমোদনের জন্য জমা দাও',
  YOU_HAVE_TO_CONFIRM_THE_: 'আপলোডের আগে আপনাকে প্রক্রিয়াটি নিশ্চিত করতে হবে।',
  SELECT_YOUR_DEC_TYPE: 'আপনার নথির প্রকারটি নির্বাচন করুন',
  TARGET_AUDIENCE: 'নির্ধারিত শ্রোতা',
  PROCESS_DETAILS: 'প্রক্রিয়া বিশদ',
  START_DATE: 'শুরুর তারিখ',
  DOC_DETAILS: 'নথি বিবরণ',
  CONFIRMATION: 'অনুমোদন',
  APPROVAL_PROCESS: 'অনুমোদন প্রক্রিয়া',
  SCHEDULED_EXP_DATE: 'তফসিল সমাপ্তির তারিখ',
  SCHEDULED_PUBLISHING_DATE: 'তফসিল প্রকাশের তারিখ',
  NO_PROCESS_SELECTED: 'কোনও প্রক্রিয়া বাছাই করা হয়নি',
  COUNTRIES: 'দেশ',
  DOC_ID: 'নথি আইডি',
  THERAPEUTIC_AREA: 'থেরাপিউটিক অঞ্চল',
  FILTER: 'ছাঁকনি',
  BACK: 'পেছনে',
  ATTACH: 'জোড়া',
  COPY_ANSWER: 'উত্তর কপি করুন',
  SIMILAR_INQUIRIES: 'অনুরূপ অনুসন্ধান',
  SDR_FAQ: 'এসআরডি / এফএকিউ',
  SLIDE_DECKS: 'স্লাইড ডেকস',
  MEDICAL_DOC: 'মেডিকেল ডকুমেন্ট',
  SHOW: 'প্রদর্শনী',
  LOAD_MORE: 'আর ঢুকাও',
  NO_RESULT_FOUND: 'কোন ফলাফল পাওয়া যায়নি.',
  CAN_BE_USED_TO_SEARCH_FOR_AN_:
    'একটি সম্পূর্ণ বাক্য অনুসন্ধান করতে ব্যবহার করা যেতে পারে - - নির্দিষ্ট শব্দটির মধ্যে অনুসন্ধান ফলাফল থেকে বাদ দিতে ব্যবহৃত হতে পারে',
  SEARCH_FOR_MEDICAL_SIMILAR_INQ: 'মেডিকেল ডকুমেন্টস বা অনুরূপ অনুসন্ধানের জন্য অনুসন্ধান করুন',
  SEARCH: 'অনুসন্ধান',
  Preview: 'পূর্বরূপ',
  PREVIEW_AS_PDF: 'পিডিএফ হিসাবে পূর্বরূপ',
  PUBLISHED_VERSION: 'প্রকাশিত সংস্করণ',
  NEW_VERSION: 'নতুন সংস্করণ',
  THE_DOC_IS_STILL_IN_APPROVAL_:
    'আপনি যদি নথির সামগ্রী পরিবর্তন করতে চান তবে অন্য ফাইলটি আপলোড করুন, দস্তাবেজটি এখনও অনুমোদনে রয়েছে।',
  OUT_TO: 'বাহিরে',
  NEXT: 'পরবর্তী',
  SELECTED_SLIDES: 'নির্বাচিত স্লাইড',
  GO_TO_SLIDE: 'স্লাইডে যান',
  PREV: 'পূর্ববর্তী',
  SELECT_SLIDE: 'স্লাইড নির্বাচন করুন',
  ANSWER: 'উত্তর',
  BACK_TO_STANDARD_VIEW: 'স্ট্যান্ডার্ড ভিউতে ফিরে যান।',
  UPLOAD_NEW_FILE: 'নতুন ফাইল আপলোড করুন',
  CREATE_FILE: 'ফাইল তৈরি করুন',
  EDITING_WORD_DOC: 'ওয়ার্ড ডকুমেন্ট সম্পাদনা করা হচ্ছে',
  INSERT_FILE_NAME: 'ফাইলের নাম .োকান',
  PROCEED_WITH_THE_LINK_: 'ফাইলটির লিঙ্কটি নিয়ে এগিয়ে যান',
  A_NEW_WINDOW_WILL_BE_:
    'খালি ফাইল দিয়ে একটি নতুন উইন্ডো খোলা হবে, সামগ্রীটি লিখুন এবং ট্যাবটি বন্ধ করুন',
  INSERT_THE_NAME_FOR_:
    'আপনি যে সংযুক্তিটি তৈরি করতে চান তার নাম সন্নিবেশ করুন এবং ফাইল তৈরির সাথে এগিয়ে যান',
  INSTRUCTIONS: 'নির্দেশনা',
  CREATE_NEW_WORD_FILE_FOR: 'এর জন্য নতুন ওয়ার্ড ফাইল তৈরি করুন',
  FROM: 'থেকে',
  YOU_CAN_FIND_HERE_THE_LAST_INQ_:
    'জমা দেওয়া এবং এখনও জমা না দেওয়া উভয়ই সিস্টেমে আপনি সন্নিবেশ করানো শেষ অনুসন্ধানগুলি এখানে পাবেন here আপনি আরও অনুরূপ অনুসন্ধান তৈরি করতে sertedোকানো অনুসন্ধানগুলি পুনরায় ব্যবহার করতে পারেন বা সন্নিবেশ শেষ না করে অনুসন্ধানগুলিতে ফিরে আসতে পারেন।',
  MY_DRAFT_INQ: 'আমার খসড়া অনুসন্ধান',
  WARNING_CHANGES_NOT_SAVED: 'সতর্কতা: পরিবর্তনগুলি সংরক্ষণ করা হয়নি',
  PAGE_THAT_WILL_BE_LOST: 'আপনি যদি চালিয়ে যাওয়ার সিদ্ধান্ত নেন তবে পৃষ্ঠাটি হারিয়ে যাবে।',
  YOU_HAVE_UNUSED: 'এতে আপনার সংরক্ষিত পরিবর্তন রয়েছে',
  ARE_YOU_SURE_YOU_WANT_TO_: 'আপনি কি নিশ্চিত যে আপনি এই পৃষ্ঠাটি সংরক্ষণ না করে ছেড়ে যেতে চান?',
  LEAVE_THIS_PAGE: 'এই পাতাটি ত্যাগ কর',
  STAY_ON: 'থাকার',
  ASSIGNED_TO: 'নির্ধারিত',
  THIS_INQ_HAS_BEEND_INSERTED_: 'এই তদন্তটি ইভেন্টের সময় inোকানো হয়েছে।',
  STATUS_IN_APPROVAL: 'স্থিতি: অনুমোদনে',
  STATUS_DRAFT: 'স্থিতি: খসড়া',
  IF_YOU_WANT_CREATE_THE_ACTUAL_INQ_:
    'আপনি যদি সত্যিকারের তদন্তটি তৈরি করতে চান তবে ট্যাব জমা দিতে যান এবং তৈরি বোতামটিতে ক্লিক করুন।',
  INQ_IMPORTED_FROM: 'থেকে অনুসন্ধান আমদানি করা হয়েছে',
  SEND_NEW_ANSWER: 'নতুন উত্তর পাঠান',
  RETURN_TO_INBOX: 'ইনবক্সে ফিরে আসুন',
  SUBMITTED_FOR_APPROVAL: 'অনুমোদনের জন্য জমা দেওয়া হয়েছে',
  ANSWER_SUBMITTED_ON: 'উত্তর জমা দেওয়া হয়েছে',
  BY: 'দ্বারা',
  INQ_HAS_BEEN_CLOSED: 'উত্তর না দিয়ে অনুসন্ধান বন্ধ করা হয়েছে।',
  BCC: 'bcc',
  ANSWER_GIVEN_ON: 'উত্তর দেওয়া হয়েছে',
  ANSWER_SENT_ON: 'উত্তর পাঠানো হয়েছে',
  INBOX: 'ইনবক্স',
  OPEN_ORIGINAL_INQ: 'মূল তদন্ত খুলুন',
  CLOSE_ANSWER_NOT_NEEDED: 'বন্ধ: আনসার দরকার নেই',
  HISTORY: 'ইতিহাস',
  ADD_A_NOTE: 'একটি নোট যোগ করো',
  ADD_A_NOTE_INTO_: 'অনুসন্ধানের ইতিহাসে একটি নোট যুক্ত করুন।',
  SHARE_OR_START_A_FOLLOW_UP: 'ভাগ করুন বা একটি ফলো আপ শুরু করুন',
  SEE_ALL_INQ_RELATED_TO: 'সম্পর্কিত সমস্ত অনুসন্ধান দেখুন See',
  SRD_NEEDED: 'এসআরডি দরকার',
  ANSWER_UNAVAILABLE: 'উত্তর অনুপলব্ধ',
  QUESTION: 'প্রশ্ন',
  SUBMIT: 'জমা',
  ANSWER_UPPERCASE: 'উত্তর',
  DETAILS: 'বিশদ বিবরণ',
  SPLIT_INQ: 'বিভক্ত অনুসন্ধান',
  ADD_QUESTION: 'প্রশ্ন যুক্ত করুন',
  ENTER_QUESTION: 'প্রশ্ন লিখুন',
  MARK_AS_ANSWERED: 'উত্তর হিসাবে চিহ্নিত করুন',
  TO_BE_ANSWERED: 'উত্তর দেওয়া',
  FOLLOW_UP_RECEIVED: 'ফলো আপ প্রাপ্তি',
  ERROR: 'ত্রুটি',
  SHOW_ORIGINAL_MESSAGE: 'আসল বার্তাটি দেখান',
  SEND: 'পাঠান',
  DO_NO_SEND: 'প্রেরণ করোনা',
  OK: 'ঠিক আছে',
  MAX_LENGTH_FOR_ZIP_CODE: 'জিপ কোডের সর্বাধিক দৈর্ঘ্য',
  CHARACTERS: 'চরিত্র',
  ADDRESS_1_IS_: 'ঠিকানা 1',
  LABEL_IS: 'লেবেল হল',
  NO_QUESTION_INSERTED: 'কোনও প্রশ্ন .োকানো হয়নি',
  WARNING: 'সতর্কতামূলক',
  FOLLOW_UP_IS_NOT_AVAILABLE_: 'এই মিথস্ক্রিয়াটির জন্য ফলোআপ পাওয়া যায় না।',
  INSERT_INQ_TEXT: 'তদন্তের পাঠ্য সন্নিবেশ করান',
  SHOW_MORE: 'আরো দেখুন',
  OUT_OF: 'এর বাইরে',
  SHARE: 'ভাগ',
  HCP_PROFILE_OF: 'এর HCP প্রোফাইল',
  INQUIRER_DOES_NOT_HAVE_AN_EMAIL:
    'নীচের সমস্ত বাধ্যতামূলক ক্ষেত্র এবং অন্তত একটি ইমেল ঠিকানা পূরণ করুন এবং তারপর GDPR রসিদ বিজ্ঞপ্তির পূর্বরূপ দেখতে সংরক্ষণ করুন।',
  CONFIRMATION_SENT_BY: 'বিজ্ঞপ্তি পাঠানো হয়েছে',
  COUNTRY_IS: 'দেশ হচ্ছে',
  COUNTRY: 'দেশ',
  AT_LEAST_ONE_CONTACT_IS_: 'কমপক্ষে একটি যোগাযোগ হয়',
  EMAIL_IS: 'ইমেল হয়',
  EMAIL_LABEL_IS: 'ইমেল লেবেল হয়',
  PHONE_FAX_IS: 'ফোন / ফ্যাক্স লেবেল হয়',
  PHONE_FAX_NUMBER_IS: 'ফোন / ফ্যাক্স নম্বরটি',
  ADDRESS: 'ঠিকানা',
  DELETE_PERSONAL_DATA: 'ব্যক্তিগত ডেটা মুছুন',
  HCP_VERIFIED: 'এইচসিপি যাচাই করা হয়েছে',
  KEY_OPINION_LEADER: 'মূল মতামত নেতা',
  HOSPITAL_BASED: 'হাসপাতাল ভিত্তিক',
  HCP_RELATED_INFORMATION: 'এইচসিপি সম্পর্কিত তথ্য',
  ACCEPT: 'গ্রহণ করা',
  REJECT: 'প্রত্যাখ্যান',
  DO_YOU_WANT_TO_ACCEPT_THESE_Q: 'আপনি কি এই পরামর্শগুলি গ্রহণ করতে চান?',
  SALES_REP: 'বিক্রয় প্রতিনিধির',
  SUGGEST_A_TOPIC: 'একটি বিষয়ের পরামর্শ দিন',
  TEAM_SPECIFIC_FIELDS: 'টিম স্পেসিফিক ফিল্ডস',
  ADR_RELATED: 'এডিআর সম্পর্কিত',
  NOT_ADR_RELATED: 'এডিআর সম্পর্কিত নয়',
  PLEASE_REVIEW_THE_MESSAGE_AND_:
    'দয়া করে বার্তাটি পর্যালোচনা করুন এবং এ্যাডভার্স ড্রাগ ড্রাগ বিক্রিয়া সম্পর্কিত বা না হিসাবে শ্রেণিবদ্ধ করুন।',
  ADVERSE_DRUG_REACTION: 'প্রতিকুল ওষুধ প্রতিক্রিয়া',
  CHANGE_DETAILS: 'বিশদ বিবরণ পরিবর্তন করুন',
  YES: 'হ্যাঁ',
  NO: 'না',
  DUE_DATE: 'নির্দিষ্ট তারিখ',
  CRITICAL: 'সংকটপূর্ণ',
  NORMAL: 'সাধারণ',
  PRIORITY: 'অগ্রাধিকার',
  SELECT_DETAILS: 'বিশদ নির্বাচন করুন',
  SAVE_REVIEW: 'পর্যালোচনা সংরক্ষণ করুন',
  SET_TO_ALL_INQ: 'সমস্ত জিজ্ঞাসা সেট করুন',
  PRODUCT_QUALITY_COMPLAINT: 'পণ্য মানের অভিযোগ',
  PQC_PC_A_PRODUCT_:
    'পিকিউসি / পিসি একটি পণ্য মানের অভিযোগ (কিউসি) বা সমস্যাটিকে কোনও ওষুধের পণ্যের ত্রুটি বা ত্রুটিযুক্ত বা কোনও ড্রাগ পণ্যটির গুণমান, কার্য সম্পাদন, সুরক্ষা সম্পর্কিত যে কোনও উদ্বেগ হিসাবে সংজ্ঞায়িত করা হয়, উদাঃ। পরিবর্তিত গন্ধ এবং / বা স্বাদ, দুর্বল বা ত্রুটিযুক্ত প্যাকেজিং, সন্দেহজনক দূষণ ইত্যাদি',
  OFF_LABEL_USE_OF_PHARMA___:
    'অ-অনুমোদনপ্রাপ্ত ইঙ্গিতের জন্য বা অ-অনুমোদনপ্রাপ্ত বয়সী গোষ্ঠী, ডোজ, বা প্রশাসনের রুটে ফার্মাসিউটিক্যাল ওষুধের অফ-লেবেল ব্যবহার।',
  A_RESPONSE_TO_A_MEDICINAL_:
    'কোনও inalষধি পণ্য যা একটি উদ্বেগজনক এবং অযৌক্তিক এবং medicষধি পণ্য এবং একটি বিরূপ ইভেন্টের মধ্যে কার্যকারক সম্পর্কের প্রতিক্রিয়া অন্তত একটি যুক্তিসঙ্গত সম্ভাবনা। এছাড়াও বিশেষ পরিস্থিতির ক্ষেত্রে (যেমন: গর্ভাবস্থার কেসগুলি, লেবেল ব্যবহারের বাইরে, অতিরিক্ত মাত্রায়) এডিআর হিসাবে চিহ্নিত করা দরকার।',
  NORMAL_IS_7_WORKING_DAYS:
    'সাধারণ 7 কার্যদিবস, উচ্চ হয় 48 ঘন্টা এবং সমালোচক (রোগী সম্পর্কিত) 24 ঘন্টা',
  CATEGORY_AND_TOPIC_ARE: 'বিভাগ এবং বিষয় হয়',
  PRODUCT_IS: 'পণ্য হয়',
  DO_YOU_WANT_TO_ACCEPT_: 'আপনি কি এই পরামর্শগুলি গ্রহণ করতে চান?',
  MERGE: 'একত্রিত করা',
  Duplicate: 'প্রতিলিপি',
  Other: 'অন্যান্য',
  Spam: 'স্প্যাম',
  CLOSE_INTERACTION: 'মিথস্ক্রিয়া বন্ধ করুন',
  RE_OPEN_THE_TARGET_: 'মার্জ হওয়ার পরে লক্ষ্য অনুসন্ধানটি পুনরায় খুলুন',
  MARK_AS_INTERNAL_: 'বিদ্যমান তদন্তের জন্য অভ্যন্তরীণ বার্তা হিসাবে চিহ্নিত করুন।',
  MARK_AS_AN_ERROR_: 'বিদ্যমান তদন্তের জন্য একটি ত্রুটি বার্তা হিসাবে চিহ্নিত করুন।',
  THIS_MESSAGE_WILL_BE_EXISTING_:
    'এই বার্তাটি একটি বিদ্যমান তদন্তের ফলোআপ প্রতিক্রিয়া হিসাবে চিহ্নিত করা হবে।',
  THANK_YOU: 'ধন্যবাদ',
  AN_INTERNAL_MESSAGE: 'একটি অভ্যন্তরীণ বার্তা',
  AN_ERROR_MESSAGE: 'একটি ত্রুটি বার্তা',
  A_FOLLOW_UP_RESPONSE: 'একটি ফলো আপ প্রতিক্রিয়া',
  THIS_INQ_IS: "এই তদন্তটি হ'ল",
  SPLIT_INTERACTION: 'বিভাজন ইন্টারঅ্যাকশন',
  SAVE_AND_CLOSE: 'সংরক্ষণ করেন এবং বন্ধ করেন',
  SAVE_INQ: 'অনুসন্ধান সংরক্ষণ করুন',
  REVIEW_THE_TEXT_AND: 'পাঠ্য এবং শ্রেণিবিন্যাস পর্যালোচনা করুন, এবং নিশ্চিত করুন',
  CHOOSE_THE_RELEVANT_PRODUCT: 'নির্বাচিত পাঠ্যের জন্য প্রাসঙ্গিক পণ্য, বিভাগ এবং বিষয় চয়ন করুন',
  SELECT_THE_TEXT_THAT_:
    'একটি পণ্য / বিষয় সম্পর্কে প্রশ্ন চিহ্নিত করে এমন পাঠ্যটি নির্বাচন করুন (শুভেচ্ছা, স্বাক্ষর, অস্বীকৃতি বা অন্যান্য অ-প্রাসঙ্গিক তথ্য অন্তর্ভুক্ত করবেন না)',
  REPEAT_THE_PREVIOUS_: 'আরও অনুসন্ধান যুক্ত করতে পূর্ববর্তী পদক্ষেপগুলি পুনরাবৃত্তি করুন',
  READ_MORE: 'আরও পড়ুন',
  READ_LESS: 'কম পড়ুন',
  DO_NOT_INCLUDE_:
    'অভিবাদন, স্বাক্ষর, অস্বীকৃতি বা অন্যান্য অ-প্রাসঙ্গিক তথ্য অন্তর্ভুক্ত করবেন না',
  SELECT_THE_TEXT_THAT_IDENT:
    'একটি পণ্য / বিষয় সম্পর্কে প্রশ্ন চিহ্নিত করে এমন পাঠ্যটি নির্বাচন করুন',
  IF_YOU_HAVE_MULTIPLE_: 'আপনার যদি একাধিক পণ্য বা বিষয় থাকে তবে তদন্তটি বিভক্ত করতে হবে।',
  THE_FOLLOWING_PROCESS_:
    'নিম্নলিখিত প্রক্রিয়া আপনাকে প্রতিটি পণ্য / বিষয়ের জন্য প্রাসঙ্গিক পাঠ সনাক্ত করতে সহায়তা করবে: ',
  EXPORT_TO_PDF: 'পিডিএফ রফতানি করুন',
  Added: 'যোগ করা হয়েছে',
  Deleted: 'মুছে ফেলা',
  From: 'থেকে',
  To: 'প্রতি',
  BACK_TO_INBOX: 'ইনবক্সে ফিরে যান',
  PERMISSION_DENIED: 'অনুমতি অস্বীকৃত',
  THIS_INQ_IS_LOCKED_BY: 'এই তদন্ত দ্বারা লক করা হয়',
  FORCE_UNLOCK: 'বাধ্যতামূলকভাবে আনলক করুন',
  INQUIRIES: 'অনুসন্ধান',
  Download: 'ডাউনলোড',
  Event: 'ঘটনা',
  INSERTED_BY: 'Sertedোকানো হয়েছে',
  LAST_EDITED_ON: 'সর্বশেষ সম্পাদিত',
  LAST_EDITED_BY: 'সর্বশেষ সম্পাদিত',
  CREATED_ON: 'তৈরি',
  TOPIC: 'বিষয়',
  TABLE_OPTIONS: 'টেবিল বিকল্প',
  DEPARTMENT: 'বিভাগ',
  User: 'ব্যবহারকারী',
  ADD_THERAPEUTIC_AREA: 'থেরাপিউটিক এরিয়া যুক্ত করুন',
  CREATE_THERAPEUTIC_AREA: 'নতুন থেরাপিউটিক অঞ্চল তৈরি করুন',
  ADD_NEW: 'নতুন যুক্ত করুন',
  SELECT_TEAM_TO_PROCEED: 'এগিয়ে যেতে টিম নির্বাচন করুন',
  MIM: 'এমআইএম',
  MA: 'এম এ',
  MSL: 'MSL',
  SALES: 'বিক্রয়',
  CALL_CENTER: 'কল সেন্টার',
  ALL: 'সব',
  Warning: 'সতর্কতা',
  clear: 'স্পষ্ট',
  THESE_CHANGES_WILL_BE_: 'এই পরিবর্তনগুলি নির্বাচিত ব্যবহারকারীদের জন্য প্রয়োগ করা হবে',
  INSERT_A_FOLLOW_UP_RESP: 'একটি ফলো আপ প্রতিক্রিয়া sertোকান',
  INSERT_A_NOTE: 'একটি নোট .োকান',
  MERGED_FROM: 'থেকে মার্জ করা',
  CHANGES: 'পরিবর্তনগুলি',
  SHOW_LESS: 'প্রদর্শন কম',
  COMPLETE: 'সম্পূর্ণ',
  MISSING: 'নিখোঁজ',
  CLOSE_AS_FORWARDED: 'ফরওয়ার্ড হিসাবে বন্ধ করুন',
  CLOSE_EXT_TEAM: 'বাহ্যিক দলের কাছে পাঠানো হিসাবে তদন্ত বন্ধ করুন',
  THE_INQ_BELONG_TO_A_:
    'এই তদন্তটি একটি দেশের অন্তর্গত যা বাহ্যিক দলের দায়িত্ব। এরপরে তদন্তটি স্বয়ংক্রিয়ভাবে এই দলে প্রেরণ করা হবে।',
  RESOLVE: 'সমাধান',
  TERRITORIES: 'অঞ্চল',
  EXTERNAL_COMMUNICATION: 'বাহ্যিক যোগাযোগ',
  FOR_INQ_COMING_DIRECTLY_:
    'ইমেলটির মাধ্যমে এইচসিপি থেকে সরাসরি আসা জিজ্ঞাসাবাদের জন্য, তদন্তটি প্রাপ্ত হয়েছে তা অবহিত করার জন্য স্বয়ংক্রিয়ভাবে একটি বিজ্ঞপ্তি প্রেরণ করুন।',
  INTERNAL_COMMUNICATION: 'অভ্যন্তরীণ যোগাযোগ',
  SALESREP_RECEIVES_: 'সেলসরেপ কেবলমাত্র বিজ্ঞপ্তিগুলি গ্রহণ করে যা তদন্তের উত্তর দেওয়া হয়েছিল',
  SALESREP_RECEIVES_FULL_ANSWER_: 'অন লেবেল অনুসন্ধানের জন্য সেলসরেপ পুরো উত্তর পেয়েছে',
  SALESREP_RECEIVES_ONLY_NOTIF_:
    'সেলসরেপ কেবলমাত্র বিজ্ঞপ্তিগুলি গ্রহণ করে যা তদন্তের উত্তর দেওয়া হয়েছিল',
  Name: 'নাম',
  Module: 'মডিউল',
  Function: 'ক্রিয়া',
  LAST_RUN: 'শেষ রান',
  End: 'শেষ',
  Every: 'প্রতি',
  Period: 'কাল',
  Active: 'সক্রিয়',
  Resolved: 'স্থিরপ্রতিজ্ঞ',
  ERROR_PREVIEW: 'ত্রুটি পূর্বরূপ',
  FAILURE_COUNT: 'ব্যর্থতা গণনা',
  COMPLETED_ON: 'সম্পন্ন',
  STARTED_ON: 'শুরু হয়েছে',
  TASK_NAME: 'কার্যের নাম',
  PRODUCT_SPECIFIC: 'পণ্য বিশেষ',
  THERAPEUTIC_AREAS: 'থেরাপিউটিক অঞ্চল',
  PRODUCT_GROUPS: 'পণ্য গ্রুপ',
  LICENSING_PARTNER: 'পার্টনার লাইসেন্সিং',
  PRODUCT_CODE: 'পণ্য কোড',
  EXTERNAL_ID: 'বাহ্যিক আইডি',
  OBJECT_TYPE: 'অবজেক্টের ধরণ',
  MIGRATION_NAME: 'মাইগ্রেশন নাম',
  MIGRATION_ERRORS: 'অভিবাসন ত্রুটি',
  ADD_SALES_REP: 'বিক্রয় প্রতিনিধি যুক্ত করুন',
  ASSIGNED_TO_MSL: 'এমএসএলে নিযুক্ত',
  NOT_ASSIGNED: 'নির্ধারিত না',
  ASSIGNED_TO_OTHER_MSL: 'অন্যান্য এমএসএলকে নিয়োগ দেওয়া হয়েছে',
  ASSIGNED_TO_THIS_MSL: 'এই এমএসএলকে নিয়োগ দেওয়া হয়েছে',
  SHOW_ONLY: 'প্রদর্শন শুধুমাত্র',
  SALES_REP_LIST: 'বিক্রয় প্রতিনিধি তালিকা',
  ACTIVE_TASK: 'সক্রিয় কার্য',
  CHOOSE_USER_AND_SET_THE_SHIFT_: 'ব্যবহারকারী চয়ন করুন এবং ইভেন্টের জন্য শিফট সেট করুন',
  MANAGE_SHIFTS: 'শিফট পরিচালনা করুন',
  CREATE_NEW_USER: 'নতুন ব্যবহারকারী তৈরি করুন',
  THIS_TEMPLATE_WILL_BE_AUTO_: 'এই টেমপ্লেটটি স্বয়ংক্রিয়ভাবে আপনার দলের সদস্যকে যুক্ত করা হবে।',
  TEMPLATE_FOR: 'জন্য টেমপ্লেট',
  Day: 'দিন',
  Week: 'সপ্তাহ',
  Month: 'মাস',
  Today: 'আজ',
  Archive: 'সংরক্ষাণাগার',
  PARTICIPANTS: 'PARTICIPANTS এর',
  EVENT_MANAGEMENT: 'ইভেন্ট ম্যানেজমেন্ট',
  ADD_TASK: 'টাস্ক যোগ করুন',
  EDIT_TASK: 'সম্পাদনা কার্য',
  IS_ACTIVE: 'সক্রিয়',
  Months: 'মাস',
  Days: 'দিন',
  Hours: 'ঘন্টার',
  Minutes: 'মিনিট',
  HCP_ADMINISTRATION: 'এইচসিপি প্রশাসন M',
  AUTH_REQ_ADMINISTRATION: 'অ্যাথ প্রশাসনের অনুরোধ',
  THE_PRODUCT_WILL_BE_HIGHLIGHTED_IN_: 'পণ্যটি নির্বাচিত থেরাপিউটিক অঞ্চলগুলিতে হাইলাইট করা হবে।',
  PRODUCT_GROUPS_DEFINE_:
    'পণ্য গোষ্ঠীগুলি এই পণ্য অনুসন্ধানের উত্তর দেওয়ার জন্য দায়ীকে সংজ্ঞায়িত করে।',
  USER_ADMINISTRATION: 'ব্যবহারকারীদের প্রশাসন',
  IS_PRODUCT_SPECIFIC: 'পণ্য নির্দিষ্ট',
  USER_TEMPLATES_ADMIN: 'ব্যবহারকারী টেম্পলেট প্রশাসন',
  SET_DEFAULT_VALUES: 'ডিফল্ট মূল্য নির্ধারণ করুন',
  SET_DEFAULT_VALUES_FOR_: 'ইভেন্টের জন্য ডিফল্ট মান সেট করুন',
  INQUIRY_DEFAULT_VAL: 'ডিফল্ট মানগুলি অনুসন্ধান করুন',
  CREATE_NEW_EVENT: 'নতুন ইভেন্ট তৈরি করুন',
  EDIT_EVENT: 'ইভেন্ট সম্পাদনা করুন',
  SET_DETAILS_TO_CREATE_NEW_EVENT: 'নতুন ইভেন্ট তৈরি করতে বিশদ সেট করুন',
  ADD_DOC_LINKED_TO_: 'ইভেন্টের সাথে যুক্ত নথি যুক্ত করুন',
  INC_CHANNEL: 'ইনক। চ্যানেল',
  CHANGE_DETAILS_OF_THE_EVENT: 'ইভেন্টের বিবরণ পরিবর্তন করুন',
  DESCRIPTION: 'বিবরণ',
  CREATE_APPROVAL_PROCESS: 'অনুমোদনের প্রক্রিয়া চয়ন করুন',
  CREATE_NEW: 'নতুন তৈরী করা',
  CUSTOMIZE_PROCESS: 'প্রক্রিয়া কাস্টমাইজ করুন',
  SELECTED_PROCESS: 'নির্বাচিত প্রক্রিয়া',
  CLONE: 'ক্লোন',
  PREFERENCES_TITLE: 'পছন্দসমূহ',
  NOTIFICATIONS_TITLE: 'বিজ্ঞপ্তিগুলি',
  TEMPLATES: 'টেমপ্লেট',
  LETTER_TEMPLATES: 'চিঠির টেমপ্লেট',
  USER_TEMPLATES: 'ব্যবহারকারী টেম্পলেট',
  TEAM_MEMBERS: 'দলের সদস্যরা',
  NEW_PASSWORD: 'নতুন পাসওয়ার্ড',
  REPEAT_NEW_PASSWORD: 'নতুন পাসওয়ার্ড পুনরাবৃত্তি করুন',
  AT_LEAST_1_LOWERCASE_CHARACTER: 'কমপক্ষে 1 টি লোয়ার কেস লেটার',
  AT_LEAST_1_UPPERCASE_CHARACTER: 'কমপক্ষে ১ টি বড় হাতের অক্ষর',
  AT_LEAST_1_DIGIT: 'কমপক্ষে 1 ডিজিট',
  AT_LEAST_1_SPECIAL_CHARACTER: 'কমপক্ষে 1 টি বিশেষ চরিত্র',
  MINIMUM_8_CHARACTERS: 'সর্বনিম্ন 8 টি অক্ষর',
  SHOULD_NOT_CONTAIN_EMAIL_OR_NAMES: 'ই-মেইল বা নাম থাকা উচিত নয়',
  SHOULD_MATCH_PASSWORD: 'পাসওয়ার্ড মেলে উচিত',
  USER_MANUAL: 'ব্যবহার বিধি',
  SUPPORT: 'সমর্থন',
  BACK_TO_LOGIN: 'প্রবেশ করতে পেছান',
  PASSWORD_CONFIRMATION: 'পাসওয়ার্ড নিশ্চিতকরণ',
  HAVE_AN_ACCOUNT: 'ইতিমধ্যে একটি অ্যাকাউন্ট আছে?',
  Submit: 'জমা দিন',
  Gender: 'লিঙ্গ',
  PROFESSION: 'পেশা',
  MEDICAL_INQ_ROLES: 'চিকিত্সা অনুসন্ধান প্রক্রিয়াজাতকরণ ভূমিকা',
  MEDICAL_INFO_MANAGER: 'মেডিকেল ইনফরমেশন ম্যানেজার',
  MEDICAL_SCIENCE_LIAISON: 'চিকিত্সা বিজ্ঞান যোগাযোগ',
  NOT_PROCESSING_INQUIRIES: 'প্রক্রিয়াজাতকরণ অনুসন্ধানগুলি নয়',
  ACTIVE_HEALTH_CARE_SPEC: 'সক্রিয় স্বাস্থ্যসেবা বিশেষজ্ঞ',
  NOT_ACTIVE_HEALTH_CARE_SPEC: 'অ্যাক্টিভ হেলথ কেয়ার বিশেষজ্ঞ নয়',
  PASS_IS_REQUIRED: 'পাসওয়ার্ড প্রয়োজন!',
  EMAIL_IS_REQUIRED: 'ইমেল প্রয়োজন!',
  VALID_EMAIL: 'একটি বৈধ ইমেইল ঠিকানা লিখুন!',
  FIELD_IS_REQUIRED: 'ঘরটি অবশ্যই পূরণ করতে হবে!',
  ARE_YOU_daiichi_EMPLOYEE: 'আপনি কি দাইচি সাঁকিও কর্মচারী?',
  here: 'এখানে',
  CREATE_AN_ACCOUNT: 'একটি অ্যাকাউন্ট তৈরি করুন',
  FORGOT: 'ভুলে গেছেন?',
  Medical: 'চিকিৎসা',
  Medical_not_resp: 'মেডিকেল - এমআইআরগুলিতে সাড়া দেওয়ার জন্য দায়বদ্ধ নয়',
  Commercial: 'ব্যবসায়িক',
  Market_access: 'বাজারে প্রবেশ',
  Pharmacovigilance: 'Pharmacovigilance',
  PR: 'জনসংযোগ',
  Please_specify: 'নির্ধারন করুন',
  Medical_information_manager: 'মেডিকেল ইনফরমেশন ম্যানেজার',
  Medical_advisor: 'স্বাস্থ্য উপদেষ্টা',
  Are_Medical_inquiries: 'আপনি কি মেডিকেল অনুসন্ধানের উত্তর দেওয়ার জন্য দায়বদ্ধ?',
  Are_Health_care_professional: 'আপনি কি অনুশীলনকারী স্বাস্থ্যসেবা পেশাদার?',
  Health_care_Industry: 'স্বাস্থ্যসেবা শিল্প',
  Health_Authority: 'স্বাস্থ্য কর্তৃপক্ষ',
  Journalist: 'সাংবাদিক',
  Lawyer: 'আইনজীবী',
  Patient: 'রোগী',
  Payor: 'প্রদানের',
  Scientist: 'বিজ্ঞানী',
  Angiologist: 'Angiologist',
  Cardiologist: 'হৃদরোগ বিশেষজ্ঞ',
  Cardiovascular_Surgeon: 'কার্ডিওভাসকুলার সার্জন',
  Dentist: 'দাঁতের',
  Gastroenterologist: 'অন্ত্রবিদ',
  General_Practitioner: 'জেনারেল প্র্যাকটিশনার',
  Geriatrician: 'বার্ধক্যজনিত রোগ বিশারদকে',
  Gynecologist: 'স্ত্রীরোগবিশারদ',
  Haematologist: 'Haematologist',
  Internist: 'ইন্টারনাল মেডিসিন বিশেষজ্ঞ',
  Medical_Student: 'মেডিকেলের ছাত্র',
  Nephrologist: 'কিডনি রোগ বিশেষজ্ঞ',
  Neurologist: 'স্নায়ুবিশেষজ্ঞ',
  Neurosurgeon: 'নিউরোসার্জন',
  Nurse: 'নার্স',
  Oncologist: 'ক্যান্সার বিশেষজ্ঞ',
  Oncology_Nurse: 'অনকোলজি নার্স',
  Other_HCP: 'অন্যান্য এইচসিপি',
  Other_Surgeon: 'অন্যান্য সার্জন',
  Pediatrician: 'শিশুরোগ',
  Pharmacist: 'কম্পউণ্ডার',
  Pharmacologist: 'Pharmacologist',
  Pharmacy_Technician: 'ফার্মেসী',
  Pneumologist: 'Pneumologist',
  Radiologist: 'রেডিত্তল্যাজিস্ট',
  Rheumatologist: 'রিউমেটোলজিস্ট',
  USER_PASS_INCORRECT: 'ব্যাবহারকারীর নাম অথবা গোপন নাম্বারটি ভুল',
  SUBMITTED_REQUEST: 'আপনার অনুরোধ সফলভাবে জমা দেওয়া হয়েছে।',
  Reset: 'রিসেট',
  Reset_pass: 'পাসওয়ার্ড রিসেট করুন',
  New_pass: 'নতুন পাসওয়ার্ড',
  RESET_PASS_EMAIL: 'আপনার ইমেলটিতে একটি পুনরায় সেট পাসওয়ার্ড লিঙ্ক পাঠানো হয়েছিল',
  NEW_PASS_SUCCESS: 'আপনার পাসওয়ার্ডটি সফলভাবে পুনরায় সেট করা হয়েছে',
  SOMETHING_WENT_WRONG: 'কিছু ভুল হয়েছে, দয়া করে সহায়তার সাথে যোগাযোগ করুন।',
  EVENTS: 'ঘটনাবলী',
  Login_here: 'এখানে লগইন করুন',
  REGISTRATION: 'নিবন্ধন',
  Create_an_account: 'একটি অ্যাকাউন্ট তৈরি করুন',
  NO_RECORDS: 'কোনও রেকর্ড নেই',
  INTERACTION: 'মিথষ্ক্রিয়া',
  SPECIALIZATION: 'বিশেষায়িত ক্ষেত্র',
  SPECIALIZATION_TYPE: 'বিশেষত্ব প্রকার',
  SAVED: 'সংরক্ষিত',
  SAVING: 'সেভিং',
  TYPING: 'টাইপিং',
  CREATE_NEW_INTERACTION: 'নতুন ইন্টারঅ্যাকশন তৈরি করুন',
  FILTER_BY: 'দ্বারা ফিল্টার করুন',
  SEND_EMAIL: 'ইমেইল পাঠান',
  SAVE_WITHOUT_EMAIL: 'ইমেল ছাড়াই সংরক্ষণ করুন',
  SUBMIT_TO_INBOX: 'ইনবক্সে জমা দিন',
  Create_my_acc: 'আমার একাউন্ট তৈরি কর',
  NEW_QUESTION: 'নতুন প্রশ্ন',
  LABEL: 'লেবেল',
  SELECT_A_LABEL: 'একটি লেবেল নির্বাচন করুন',
  CITY: 'শহর',
  SUGGEST_A_NEW_CATEGORY_OR_SELECT_: 'একটি নতুন বিভাগের পরামর্শ দিন বা বিদ্যমান একটি নির্বাচন করুন',
  SUGGEST_CATEGORY_OR_TOPIC: 'নতুন বিভাগ বা বিষয় সম্পর্কে পরামর্শ দিন',
  URGENT: 'জরুরী',
  EXISTING_CATEGORY: 'বিদ্যমান বিভাগ',
  NEW_CATEGORY_NAME: 'নতুন বিভাগের নাম',
  NEW_TOPIC_NAME: 'নতুন টপিকের নাম',
  CATEGORY: 'বিভাগ',
  MSLA: 'MSLA',
  OUT_CHANNEL: 'বহির্গামী চ্যানেল',
  LOGIN_TO_CONTINUE: 'ম্যাডজেন্টায় চালিয়ে যেতে লগইন করুন',
  Email_address: 'ইমেল ঠিকানা',
  FORGOT_PASS: 'পাসওয়ার্ড ভুলে গেছেন?',
  Remember_me: 'আমাকে মনে কর',
  YOU_WILL_BE_NOTIFIED:
    'আপনার নিবন্ধকরণের জন্য আরও নির্দেশাবলী সহ নিশ্চিতকরণ ইমেল প্রেরণ করা হয়েছিল',
  SUCCESS_EMAIL_CONFIRMATION: 'আপনার ই-মেইল সফলভাবে নিশ্চিত করা হয়েছে।',
  SUCCESS_EMAIL_CONFIRMATION_SUB: 'আপনার অনুরোধটি পর্যালোচনা করা হলে আপনাকে জানানো হবে',
  FAILED_EMAIL_CONFIRMATION: 'আমরা আপনার ইমেলটি নিশ্চিত করতে পারছি না are',
  FAILED_EMAIL_CONFIRMATION_SUB:
    'দয়া করে আবার চেষ্টা করুন এবং যদি সমস্যাটি বজায় থাকে তবে সমস্যাটির বিবরণ সহ সমর্থন করতে লিখুন',
  RESET_FILTERS: 'ফিল্টারগুলি পুনরায় সেট করুন',
  NEW_PASS_RESET_SUCCESS: 'সফলভাবে পাসওয়ার্ড পুনরায় সেট!',
  Cardiovascular: 'কার্ডিওভাসকুলার',
  Oncology: 'অনকোলজি',
  Due_On: 'কারণে',
  Follow_Up_Sent_On: 'ফলো আপ প্রেরণ অন',
  Follow_Up_Received_On: 'ফলো আপ রিসিভ অন',
  Closed_On: 'বন্ধ আছে',
  Reopened_On: 'আবার চালু হয়েছে',
  Inquiry_ID: 'তদন্ত আইডি',
  Search: 'অনুসন্ধান করুন',
  Assigned_to_Now: '(এখন) এ নিয়োগ',
  Edited_By: 'সম্পাদিত',
  Assigned_to_Any_Time: 'নিযুক্ত (যে কোনও সময়)',
  Closed: 'বন্ধ',
  Foreign: 'বিদেশী',
  Complex_search_Sentence_: 'জটিল অনুসন্ধান: বাক্য: "বাক্য অনুসন্ধান করতে", নয়: -শব্দ',
  Search_inquiries: 'অনুসন্ধান অনুসন্ধান',
  SEARCH_REPOSITORY: 'অনুসন্ধানের সংগ্রহস্থল',
  MIR_INBOX: 'মির ইনবক্স',
  Filters: 'ফিল্টার',
  Active_Filters: 'অ্যাক্টিভ ফিল্টার',
  CONTACT_SUPPORT: 'যোগাযোগ সমর্থন',
  MedicalInformationRequests: 'অনুসন্ধান (পিডিএফ)',
  UserProfileNavigation: 'ব্যবহারকারী প্রোফাইল (পিডিএফ)',
  UserManualChanges: 'ব্যবহারকারীর ম্যানুয়াল পরিবর্তনসমূহ (ডক্স)',
  iPad_How_To: 'আইপ্যাড (পিডিএফ)',
  MedIS_Assign_Inquiry: 'তদন্তকে বরাদ্দ করুন',
  MedIS_Documentation_Approval_Process: 'ডকুমেন্টেশন অনুমোদনের প্রক্রিয়া',
  MedIS_Documentation_Overview: 'ডকুমেন্টেশন ওভারভিউ',
  MedIS_Inquiry_Documentation: 'অনুসন্ধান ডকুমেন্টেশন',
  MedIS_MIR_Inbox_Filters: 'এমআইআর ইনবক্স ফিল্টার',
  MedIS_Merge_MIR: 'এমআইআর মার্জ করুন',
  MedIS_New_Documentation: 'নতুন ডকুমেন্টেশন',
  MedIS_New_Inquiry: 'নতুন জিজ্ঞাসা',
  MedIS_Out_Of_Office: 'অফিসের বাইরে',
  MedIS_Prepare_Custom_Answer: 'কাস্টম উত্তর প্রস্তুত করুন',
  MedIS_Split_MIR_Part_I: 'বিভক্ত এমআইআর পার্ট I',
  MedIS_Split_MIR_Part_II: 'বিভক্ত এমআইআর দ্বিতীয় খণ্ড',
  I_AGREE_TO: 'আমি রাজী',
  TermsAndConditionsLink: 'শর্তাবলী',
  Title: 'শিরোনাম',
  INTERACTION_TITLE: 'মিথস্ক্রিয়া শিরোনাম',
  Move: 'পদক্ষেপ',
  Left: 'বাম',
  Right: 'অধিকার',
  Requestor: 'Requestor',
  Assigned_To: 'নির্ধারিত',
  Status: 'অবস্থা',
  Note: 'বিঃদ্রঃ',
  Confirm: 'নিশ্চিত করুন',
  rejectConfirm: 'আপনি কি নিশ্চিত যে আপনি অনুরোধটি প্রত্যাখ্যান করতে চান?',
  approveConfirm: 'আপনি কি নিশ্চিত যে আপনি অনুরোধটি অনুমোদন করতে চান?',
  newUser: 'নতুন ব্যবহারকারী',
  editUser: 'ব্যবহারকারী সম্পাদনা করুন',
  deleteUser: 'ব্যবহারকারী মুছুন',
  newProduct: 'নতুন পণ্য',
  editProduct: 'পণ্য সম্পাদনা করুন',
  deleteProduct: 'পণ্য মুছুন',
  newCategory: 'নতুন বিভাগ',
  editCategory: 'বিভাগ সম্পাদনা করুন',
  deleteCategory: 'বিভাগ মুছুন',
  newTopic: 'নতুন বিষয়',
  editTopic: 'বিষয় সম্পাদনা করুন',
  deleteTopic: 'বিষয় মুছুন',
  userRequest: 'ব্যবহারকারী অনুরোধ',
  productRequest: 'পণ্য অনুরোধ',
  categoryRequest: 'বিভাগ অনুরোধ',
  categoryTopicRequest: 'বিভাগ / বিষয় অনুরোধ',
  topicRequest: 'বিষয় অনুরোধ',
  Suggestion: 'প্রস্তাবনা',
  tradeName: 'বাণিজ্যিক নাম',
  activeSubstance: 'সক্রিয় পদার্থ',
  productName: 'পণ্যের নাম',
  productSpecific: 'এটি কি পণ্য নির্দিষ্ট?',
  EMAIL_IN_USE: 'এই ই-মেইলে বিদ্যমান অ্যাকাউন্ট রয়েছে',
  fromDate: 'তারিখ হইতে',
  toDate: 'এখন পর্যন্ত',
  applyFilter: 'ফিল্টার প্রয়োগ করুন',
  requestReceivedFrom: 'থেকে অনুরোধ পেয়েছি',
  on: 'চালু',
  at: 'এ',
  to: 'প্রতি',
  add: 'যোগ',
  from: 'থেকে',
  approve: 'অনুমোদন করা',
  reject: 'প্রত্যাখ্যান',
  withTheFollowingRole: 'নিম্নলিখিত ভূমিকা সহ',
  forTheFollowingTeam: 'নিম্নলিখিত দলের জন্য',
  request: 'অনুরোধ',
  submittedBy: 'জমাদানকারী',
  INVALID_OR_EXPIRED_RESET_REQUEST: 'পাসওয়ার্ড পুনরায় সেট করার অনুরোধটি অবৈধ বা মেয়াদোত্তীর্ণ',
  PASS_IS_RECENT: 'পাসওয়ার্ডটি সম্প্রতি ব্যবহৃত হয়েছে',
  Assigned_to_me: 'আমাকে অর্পণ',
  My_drafts: 'আমার খসড়া',
  REPORTS: 'প্রতিবেদন',
  Pending_approval: 'অনুমোদন অপেক্ষারত',
  ADMINISTRATION: 'প্রশাসন',
  Show_all: 'সব দেখাও',
  In_progress: 'চলমান',
  Table_Settings_Columns: 'সারণী সেটিংস - কলাম',
  Change_column_visibility: 'কলামের দৃশ্যমানতা পরিবর্তন করুন',
  Reassign_to: 'পুনরায় নিযুক্ত করুন',
  set_priority: 'অগ্রাধিকার নির্ধারন কর',
  set_status: 'স্থিতি সেট করুন',
  Spam_list: 'স্প্যাম তালিকা',
  Institution: 'প্রতিষ্ঠান',
  DUE_ON: 'কারণে',
  Profile_settings: 'প্রোফাইল সেটিংস',
  Preferences: 'পছন্দসমূহ',
  Role_settings: 'ভূমিকা সেটিংস',
  Notification_settings: 'বিজ্ঞপ্তি সেটিংস',
  User_profile: 'ব্যাবহারকারীর বিস্তারিত',
  Select_person: 'ব্যক্তি নির্বাচন করুন',
  FROM_DATE: 'তারিখ হইতে',
  TO_DATE: 'এখন পর্যন্ত',
  JOB_DESC: 'কাজের বিবরণী',
  INQ_Management: 'অনুসন্ধান ম্যানেজমেন্ট',
  DOCS_Management: 'নথি ব্যবস্থাপনা',
  USER_Management: 'ইউজার ম্যানেজমেন্ট',
  Define_Frequency: 'ফ্রিকোয়েন্সি সংজ্ঞায়িত করুন',
  Search_people_here: 'এখানে লোকদের অনুসন্ধান করুন',
  Search_team_here: 'এখানে দল অনুসন্ধান করুন',
  people: 'মানুষ',
  teams: 'দলগুলি',
  empty: '(খালি)',
  can_not_be_assigned: 'এই ব্যবহারকারীর কোনও দল নেই এবং নিয়োগ দেওয়া যাবে না',
  select_a_team: 'দয়া করে এর জন্য একটি দল নির্বাচন করুন',
  confirm_team_change: 'টিম পরিবর্তন নিশ্চিত করুন',
  change_the_team_: 'আপনি কি দলটিকে পরিবর্তন করতে চান তা নিশ্চিত?',
  can_not_see_it_anymore: 'আপনি বর্তমানটিতে এটি দেখতে সক্ষম হবেন না',
  filtered_view: 'ফিল্টার করা ভিউ',
  PHONE: 'ফোন',
  FAX: 'ফ্যাক্স',
  LETTER: 'চিঠি',
  F2F_SITE_VISIT: 'F2F (সাইট ভিজিট)',
  F2F_CONGRESS: 'F2F (কংগ্রেস)',
  F2F_ADBOARD: 'F2F (অ্যাডবোর্ড)',
  F2F_OTHER: 'F2F (অন্যান্য)',
  WEBINAR_SITE_VISIT: 'ভার্চুয়াল (সাইট ভিজিট)',
  WEBINAR_CONGRESS: 'ভার্চুয়াল (কংগ্রেস)',
  WEBINAR_ADBOARD: 'ভার্চুয়াল (অ্যাডবোর্ড)',
  WEBINAR_OTHER: 'ভার্চুয়াল (অন্যান্য)',
  CHAT: 'চ্যাট',
  COURIER: 'দূত',
  REP_DELIVERY: 'পুনঃ বিতরণ',
  SYSTEM: 'পদ্ধতি',
  WEB_FORM: 'ওয়েব ফর্ম',
  Signature: 'স্বাক্ষর',
  Disclaimer: 'দাবি পরিত্যাগী',
  STATE: 'রাষ্ট্র',
  ADDRESS_1: 'ঠিকানা 1',
  ADDRESS_2: 'ঠিকানা ২',
  ZIP_CODE: 'জিপ কোড',
  HIGH: 'উচ্চ',
  ADVANCED: 'অগ্রসর',
  CLICK_BELOW_TO_POPULATE_ANSWER:
    'উত্তরটি জনপ্রিয় এবং সম্পাদনা করতে নীচের পাঠ্য এবং ক্ষেত্রগুলিতে ক্লিক করুন',
  DOCUMENT_VIEW: 'নথি দর্শন',
  FORM_VIEW: 'ফর্ম ভিউ',
  YOU_DONT_HAVE_RIGHTS_TO_ANSWER: 'উত্তর পাঠানোর অধিকার আপনার নেই।',
  ANSWER_TEXT: 'উত্তর পাঠ্য',
  CONTACT_INFORMATION: 'যোগাযোগের তথ্য',
  COMPANY: 'প্রতিষ্ঠান',
  SRD_NEEDED_TOOLTIP: 'এই তদন্তের উত্তরের জন্য নতুন এসআরডি ডকুমেন্ট দরকার',
  ENABLE_ALL: 'সমস্ত সক্ষম করুন',
  DISABLE_ALL: 'সব বিকল করে দাও',
  BEFORE: 'আগে',
  AFTER: 'পরে',
  EDIT: 'সম্পাদন করা',
  MY_TEMPLATES: 'আমার টেম্পলেটগুলি',
  TEAM_TEMPLATES: 'দল টেম্পলেট',
  TEAM_TEMPLATES_HINT: 'পুরো দলের জন্য মেডিকেল ইনফরমেশন ম্যানেজার দ্বারা তৈরি টেমপ্লেট',
  SOURCE: 'উৎস',
  TEMPLATE_NO_MATCH_INTERACTION: 'এই টেম্পলেটটি ইন্টারঅ্যাকশন বিশদের সাথে মেলে না এবং প্রযোজ্য নয়',
  EMAIL_SUBJECT: 'ইমেইল বিষয়',
  INQUIRY_SUBMIT_HINT: 'দয়া করে তদন্তের জবাব দেওয়ার জন্য সমস্ত প্রয়োজনীয় ক্ষেত্র পূরণ করুন',
  SCHEDULED_PUBLISH_TO_EXP_DATE: 'মেয়াদোত্তীর্ণ তারিখের নির্ধারিত প্রকাশনা',
  COPY_ATTACHMENT_TO_ANSWER: 'উত্তরে সংযুক্তি অনুলিপি করুন',
  COPY_TEXT_TO_ANSWER: 'উত্তরে পাঠ্য অনুলিপি করুন',
  SELECT_INQUIRY: 'অনুসন্ধান নির্বাচন করুন',
  EDIT_INQ: 'প্রশ্ন সম্পাদনা করুন',
  SPLIT_ANOTHER: 'অন্য বিভক্ত',
  DELETE_INQ: 'অনুসন্ধান মুছুন',
  SELECT_TEXT_FOR_SPLIT: 'একই ইন্টারঅ্যাকশনটিতে নতুন প্রশ্ন যুক্ত করুন',
  SELECT_TEXT_FOR_SPLIT_NEW_INTERACTION: 'পৃথক মিথস্ক্রিয়া / কেস তৈরি করুন',
  SPLIT_ORIGINAL_TEXT: 'আসল পাঠ্য (বিভাজনের জন্য পাঠ্য নির্বাচন করুন)',
  SPLIT_TEXT_TO_REMAIN_: 'প্রাথমিক তদন্তে থাকা পাঠ্য',
  SPLIT_TEXT_TO_SPLIT_: 'পাঠ্যকে বিভক্ত করতে হবে',
  SPLIT_SELECTED_SECTIONS: 'বিভাজনের জন্য নির্বাচিত বিভাগগুলি',
  SPLIT_NO_MODIFICATIONS_HINT:
    'আপনি মূল পাঠ্য থেকে বিভাগগুলি নির্বাচন করার সাথে সাথে পাঠ্যটি স্বয়ংক্রিয়ভাবে পপুলেশন হবে',
  SPLIT_MODIFICATIONS_HINT:
    'পাঠ্যটি ম্যানুয়ালি পরিবর্তিত হয়েছে এবং আসল পাঠ্য থেকে পরবর্তী কোনও নির্বাচন প্রয়োগ করা হবে না।',
  TEMPLATE_NO_MODIFICATIONS_HINT:
    'তদন্তের বিশদটিতে পরিবর্তনগুলি সহ পাঠ্যটি স্বয়ংক্রিয়ভাবে আপডেট হবে।',
  SELECT_DIFFERENT_TEMPLATE: 'একটি ভিন্ন টেম্পলেট নির্বাচন করুন',
  APPROVE: 'অনুমোদন',
  LAST_EDIT_ON: 'শেষ সম্পাদনা করুন',
  LAST_EDIT_BY: 'সর্বশেষ সম্পাদনা দ্বারা',
  INTERNAL_COMM: 'অভ্যন্তরীণ যোগাযোগ',
  INACTIVE: 'নিষ্ক্রিয়',
  DEACTIVATE: 'নিষ্ক্রিয় করা',
  ARCHIVED: 'সংরক্ষণাগারভুক্ত',
  REPUBLISH: 'পুনরায় প্রকাশ করুন',
  INTERACTION_TEMPLATE_HINT:
    'স্বাক্ষর, শুভেচ্ছা এবং অস্বীকৃতি লিখিত যোগাযোগের অংশ যেমন ইমেল, চিঠি এবং ফ্যাক্স।',
  CONFIRM_AUTH_REQUEST: 'অনুমোদনের অনুরোধটি নিশ্চিত করুন',
  VIEW_AUTH_REQUEST: 'অনুমোদনের অনুরোধ দেখুন',
  QUESTION_TITLE: 'প্রশ্নের শিরোনাম: ',
  NO_TITLE_INQUIRY: '-না-শিরোনাম-এনকোয়ারি-',
  EXISTING_CATEGORIES: 'বিদ্যমান বিভাগসমূহ',
  EXISTING_TOPICS: 'বিদ্যমান বিষয়সমূহ',
  DO_NOT_ASSIGN_PRODUCT: 'পণ্য বরাদ্দ করবেন না',
  QUESTION_TEXT: 'প্রশ্ন পাঠ্য',
  DATE: 'তারিখ',
  REJECTED: 'প্রত্যাখ্যাত',
  FILTER_AUTH_REQUESTS_BY: 'ফিল্টার লেখক অনুরোধ দ্বারা',
  ALL_REQUESTS_ALL_TEAMS: 'সমস্ত দলের জন্য সমস্ত অনুরোধ',
  ALL_TEAMS: 'সমস্ত দল',
  YOU_DONT_HAVE_RIGHTS_TO_EDIT_REQUEST:
    'বিভাগ / বিষয়গুলির অনুরোধগুলি সম্পাদনা করার আপনার অধিকার নেই।',
  SEND_FOLLOW_UP: 'ফলো-আপ পাঠান',
  RECEIPT_NOTIFICATION_CUSTOMER: 'গ্রাহকের কাছে রসিদ বিজ্ঞপ্তি',
  GDPR_DISCLAIMER: 'জিডিপিআর দাবিত্যাগ',
  ACTIONS: 'কর্ম',
  SUBMISSION_CONDITIONS: 'জমা দেওয়ার শর্ত',
  TYPE_OF_NOTIFICATION: 'বিজ্ঞপ্তির ধরন',
  DIRECT_SUBMISSION: 'সরাসরি জমা',
  DIRECT_SUBMISSION_TOOLTIP:
    'ইমেলের মাধ্যমে গ্রাহকের কাছ থেকে সরাসরি জমা দেওয়া অনুসন্ধানের জন্য, গ্রাহককে তাদের ব্যক্তিগত ডেটা পরিচালনার বিষয়ে অবহিত করার জন্য একটি বিজ্ঞপ্তি পাঠান।',
  COMPANY_EMPLOYEE_SUBMISSION: 'কোম্পানি কর্মচারী মাধ্যমে জমা',
  ENABLE_NOTIFICATION: 'বিজ্ঞপ্তি সক্রিয়',
  NOTIFICATION_SENDER: 'প্রেরকের কাছে বিজ্ঞপ্তি',
  SALES_REP_DELIVERED_RESPONSE: 'বিতরণ প্রতিক্রিয়া জন্য বিক্রয় প্রতিনিধি',
  NOTIFICATION_DELIVERED_RESPONSE: 'বিতরণ প্রতিক্রিয়া জন্য বিজ্ঞপ্তি',
  NOTIFICATION_DELIVERED_RESPONSE_TOOLTIP:
    'কোম্পানির প্রতিনিধি (বিক্রয় প্রতিনিধি, MSL, বা অন্য) মাধ্যমে জমা দেওয়া অনুসন্ধানের জন্য, গ্রাহক যখন তাদের অনুসন্ধানের উত্তর পান তখন প্রেরককে অবহিত করুন',
  FULL_ANSWER: 'সম্পূর্ণ উত্তর',
  NOTIFICATION: 'বিজ্ঞপ্তি',
  NAME: 'নাম',
  COLUMN_INIT_QUESTION_TEXT: 'মূল প্রশ্নের পাঠ্য',
  NOTIFICATION_ENABLED: 'বিজ্ঞপ্তি সক্রিয় করা হয়েছে',
  DEFAULT_TEMPLATE: 'ডিফল্ট টেমপ্লেট',
  SET_AS_DEFAULT: 'ডিফল্ট হিসেবে সেট করুন',
  DEFAULT_TEMPLATE_TOOLTIP: 'টগল অক্ষম করা হয়েছে কারণ অন্তত একটি ডিফল্ট টেমপ্লেট থাকতে হবে!',
  DEFAULT_TEMPLATE_TOOLTIP_DELETION_NOT_POSSIBLE: 'ডিফল্ট টেমপ্লেট মুছে ফেলা যাবে না!',
  BATCH_UPDATE_ONLY: 'শীর্ষস্থানীয় চেকবক্সের মাধ্যমে শুধুমাত্র একটি ব্যাচ আপডেট সম্ভব।',
  ACCESS_DENIED: 'প্রবেশাধিকার অস্বীকৃত!',
  YOU_DO_NOT_HAVE_PERMISSION_TO_ACCESS_THIS_PAGE: 'আপনার এই পৃষ্ঠাটি অ্যাক্সেস করার অনুমতি নেই৷',
  USER_ROLES: 'ব্যবহারকারীর ভূমিকা',
  MANAGE_USER_ROLES: 'ব্যবহারকারীর ভূমিকা পরিচালনা করুন',
  ARCHIVE_ROLE: 'আর্কাইভ ভূমিকা',
  ROLE_ARCHIVE_CONFIRMATION: 'আপনি কি সত্যিই এই ভূমিকা সংরক্ষণাগার করতে চান?',
  ROLES_FOR: 'জন্য ভূমিকা',
  AUDIT_TRAIL: 'অডিট ট্রেইল',
  ACTION_BY_USER: 'ব্যবহারকারী দ্বারা অ্যাকশন',
  ACTION: 'অ্যাকশন',
  ORIGINAL_VALUE: 'মূল মান',
  CHANGE: 'পরিবর্তন',
  CONDITION: 'শর্ত',
  IP: 'IP',
  GEOLOCATION: 'ভৌগলিক অবস্থান',
  NEW_VALUE: 'নতুন মান',
  FILTER_BY_ACTION: 'ক্রিয়া দ্বারা ফিল্টার করুন',
  SELECT_DATE: 'তারিখ নির্বাচন করুন',
  TEAM_SAVE: 'সংরক্ষণ',
  TEAM_DELETE: 'মুছে ফেলা',
  TEAM_CREATE: 'সৃষ্টি',
  TEAM_CLONE: 'অনুলিপি',
  TEAM_NAME: 'নাম',
  TEAM_CODE: 'কোড',
  TEAM_ACTION_DELETE_CONFIRM: 'আপনি কি সত্যিই মুছতে চান?',
  TEAM_ACTION_DELETE_CONFIRM_TITLE: 'সাবধান হও!',
  TEAM_ACTION_DELETE_CONFIRM_EXTENDED: 'একবার মুছে ফেলা হলে এটি পুনরুদ্ধার করা যায় না!',
  TEAM_FULL_ANSWER_ON_LABEL: 'লেবেলে সম্পূর্ণ উত্তর',
  TEAM_RESTRICTED_TEAM: 'সীমাবদ্ধ',
  TEAM_SEGREGATED_DATA: 'বিচ্ছিন্ন ডেটা',
  TEAM_PRIVATE_TEAM: 'বেসরকারী দল',
  TEAM_AUTO_ASSIGN: 'অটো বরাদ্দ',
  TEAM_MAILBOX_IN: 'ইনবাউন্ড মেলবক্স',
  TEAM_MAILBOX_OUT: 'আউটবাউন্ড মেলবক্স',
  TEAM_DEFAULT_LANGUAGE: 'নির্ধারিত ভাষা',
  TEAM_COUNTRIES: 'নির্ধারিত দেশ',
  TEAM_PRODUCT_GROUPS: 'পণ্য গোষ্ঠী বরাদ্দ করুন',
  TEAM_PARENT: 'মূল দল',
  TEAM_OVERRIDE_CLUSTER_ROOT: 'ওভাররাইড ক্লাস্টার রুট টিম',
  TEAM_AFFILIATIONS: 'অনুমোদিত দল',
  TEAM_TIMEZONE: 'সময় অঞ্চল',
  UPDATE: 'হালনাগাদ',
  AUTO_ASSIGN_BY: 'অটো দ্বারা নির্ধারিত',
  AUTO_ASSIGNMENTS: 'অটো অ্যাসাইনমেন্ট',
  NO_ONE: 'কেউ না',
  NOT_APPLICABLE: 'প্রযোজ্য নয়',
  OVERRIDE_NOTIFICATIONS: 'বিজ্ঞপ্তি ওভাররাইড',
  OVERRIDE_NOTIFICATIONS_HINT:
    'যখন ওভাররাইড বিজ্ঞপ্তি সহ ব্যবহারকারী টেম্পলেট ব্যবহারকারীর কাছে সেট করা থাকে, তখন এটি কোনও প্রাইক্সিস্টিং বিজ্ঞপ্তি সেটিংস অক্ষম করবে এবং ব্যবহারকারী টেম্পলেটটিতে তালিকাভুক্ত কেবলমাত্র বিজ্ঞপ্তি সেটিংস সক্ষম করবে',
  CREATED_MANUALLY: 'ম্যানুয়ালি তৈরি',
  DOCUMENT_TITLE_FOR_WORD:
    'ওয়ার্ড ডকুমেন্টগুলির জন্য শিরোনামটি সাধারণত ডকুমেন্টের মধ্যেই শিরোনাম বিন্যাসযুক্ত বিভাগ থেকে নেওয়া হয়। যদি নথিতে শিরোনাম না থাকে তবে আপনি এটি এখানে নির্দিষ্ট করতে পারেন। আপনি যদি এখানে শিরোনাম নির্দিষ্ট না করেন এবং ওয়ার্ড ডকুমেন্টে শিরোনাম বিন্যাসযুক্ত বিভাগ থাকে না, তবে ডকুমেন্ট আইডি শিরোনামের জন্য ব্যবহৃত হবে।',
  ORDER_BY: 'আদেশ দ্বারা',
  FORMAT: 'ফর্ম্যাট',
  SELECT_QUESTION: 'প্রশ্ন নির্বাচন করুন',
  SELECT_QUESTION_FOR_NOTE: 'আপনি নোট যুক্ত করতে চান এমন প্রশ্ন নির্বাচন করুন',
  ADD_NOTE_FOR_WHOLE_ENQUIRY: 'পুরো তদন্তের জন্য নোট যুক্ত করুন',
  REQUESTED_DATE: 'অনুরোধ তারিখ',
  UNASSIGNED: 'স্বীকৃত',
  BLANK: 'ফাঁকা',
  ANSWER_SAVED_WITHOUT_EMAIL: 'উত্তর ইমেল ছাড়াই সংরক্ষণ করা',
  INQ_RELATED_TO: 'সম্পর্কিত অনুসন্ধান',
  QUESTION_TEXT_BEFORE_SPLIT: 'বিভক্ত হওয়ার আগে প্রশ্ন পাঠ্য',
  SPLIT_MERGE: 'বিভক্ত এবং মার্জ',
  CLARIFICATION_QUESTION: 'স্পষ্টতা প্রশ্ন',
  ACTIVE_ON: 'সক্রিয়',
  DISMISS: 'বরখাস্ত',
  GO_TO_ENQUIRY: 'তদন্তে যান',
  NO_TEAMS: 'দল ছাড়া',
  NO_TEAMS_TIP:
    'ব্যবহারকারী রয়েছে, যা কোনও দলে বরাদ্দ করা হয়নি। এর মধ্যে কিছু আপনার দলের কাছ থেকে এসেছেন কিনা তা পরীক্ষা করে দেখতে পারেন এবং তাদের উপযুক্ত ভূমিকা প্রদান করতে পারেন।',
  TASKS: 'কাজ',
  Therapeutic_Areas: 'থেরাপিউটিক অঞ্চল',
  Product_Groups: 'পণ্য গোষ্ঠী',
  Licensing_Partner: 'লাইসেন্সিং পার্টনার',
  Product_Code: 'পণ্য কোড',
  SELECT_EXISTING_ONE: 'বিদ্যমান একটি নির্বাচন করুন',
  OR: 'বা',
  MANAGE_FORM: 'ফর্ম পরিচালনা করুন',
  EVENT_FORM_ADDRESS: 'ইভেন্ট ফর্ম ঠিকানা',
  EVENT_FORM_PREVIEW_WARING:
    'কংগ্রেস ফর্মটি খোলার আগে বা এর ঠিকানাটি অনুলিপি করার আগে আপনি কোনও পরিবর্তন সংরক্ষণ করেছেন তা নিশ্চিত করুন',
  ONLY_LETTERS: 'শুধুমাত্র চিঠি লিখুন!',
  SEARCH_SAVED_SEARCHES: 'সংরক্ষণ করা অনুসন্ধানগুলি অনুসন্ধান করুন',
  GO: 'যাওয়া',
  ASSIGN: 'বরাদ্দ',
  CERTIFICATION: 'শংসাপত্র',
  SORT: 'সাজান',
  ADD_MAILBOX: 'মেলবক্স যুক্ত করুন',
  UNSET_INCOMING_MAILBOX: 'আনসেট আগত মেলবক্স',
  SET_INCOMING_MAILBOX: 'আগত মেলবক্স সেট করুন',
  UNSET_OUTGOING_MAILBOX: 'আউটগোয়িং মেলবক্স আনসেট করুন',
  SET_OUTGOING_MAILBOX: 'বহির্গামী মেলবক্স সেট করুন',
  ALL_TEAMS_WITH_CURRENT_MAILBOX: 'বর্তমান মেলবক্স সহ সমস্ত দল',
  ALL_TEAMS_WITH_MAILBOX: 'মেলবক্স সহ সমস্ত দল',
  MAILBOX: 'মেলবক্স',
  EMAIL_POLLING: 'ইমেল পোলিং',
  ADVANCED_SEARCH: 'উন্নত অনুসন্ধান',
  SELECT_FIELDS: 'ক্ষেত্রগুলি নির্বাচন করুন',
  SEARCH_TERM: 'অনুসন্ধানের শর্ত',
  COLUMN_INTERACTION_ID: 'মিথস্ক্রিয়া আইডি',
  COLUMN_QUESTION_ID: 'প্রশ্ন আইডি',
  COLUMN_CREATED_ON: 'তৈরি',
  COLUMN_LAST_EDITED_DATE: 'সর্বশেষ সম্পাদিত',
  COLUMN_ASSIGNED_TO: 'ধার্য',
  COLUMN_DUE_DATE: 'নির্দিষ্ট তারিখ',
  COLUMN_FOLLOW_SENT_DATE: 'ফলো-আপ প্রেরিত তারিখ',
  COLUMN_FOLLOW_RECEIVED_DATE: 'ফলোআপ প্রাপ্ত তারিখ',
  COLUMN_ANSWER_DATE: 'উত্তর তারিখ',
  COLUMN_CLOSED_ON_DATE: 'তারিখে বন্ধ',
  COLUMN_REOPENED_DATE: 'পুনরায় খোলা তারিখ',
  COLUMN_INSERTED_BY: 'দ্বারা serted োকানো',
  COLUMN_LAST_EDITED_BY: 'সর্বশেষ সম্পাদিত',
  COLUMN_ANSWERED_BY: 'উত্তর দ্বারা',
  COLUMN_APPROVED_BY: 'দ্বারা স্বীকৃত',
  COLUMN_INT_COMM_USER: 'অভ্যন্তরীণ যোগাযোগ ব্যবহারকারী',
  COLUMN_INT_COMM_DATE: 'অভ্যন্তরীণ যোগাযোগের তারিখ',
  COLUMN_MY_TEAM: 'আমার দল',
  COLUMN_PQC_RELATED: 'পিকিউসি সম্পর্কিত',
  COLUMN_INCOMING_CHANNEL: 'আগত চ্যানেল',
  COLUMN_OUTGOING_CHANNEL: 'বহির্গামী চ্যানেল',
  COLUMN_OFF_LABEL: 'অফ লেবেল',
  COLUMN_HCP_SPEC: 'এইচসিপি + বিশেষীকরণ',
  COLUMN_NON_HCP_SPEC: 'নন-এইচসিপি + পেশা',
  COLUMN_KEE: 'কি',
  COLUMN_HOSPITAL_BASED: 'হাসপাতাল ভিত্তিক',
  COLUMN_CITY: 'শহর',
  COLUMN_INSTITUTION: 'প্রতিষ্ঠান',
  COLUMN_TERRITORY: 'এলাকা',
  CASE_ID: 'কেস আইডি',
  OPTION_ADD: 'যোগ করুন',
  OPTION_AND: 'এবং',
  OPTION_OR: 'বা',
  OPTION_NOT: 'না',
  OPTION_YES: 'হ্যাঁ',
  OPTION_NO: 'না',
  QUERY: 'প্রশ্ন',
  EXPORT_LABEL: 'রফতানি কলামগুলি সংজ্ঞায়িত করুন',
  SAVE_SEARCH: 'অনুসন্ধান সংরক্ষণ করুন',
  SHARE_SEARCH: 'অনুসন্ধান ভাগ করুন',
  SEARCH_BTN: 'অনুসন্ধান',
  BACK_ACTION: 'পেছনে',
  SAVE_CHANGES: 'পরিবর্তনগুলোর সংরক্ষন',
  SAVED_SEARCH: 'সংরক্ষণ অনুসন্ধান',
  NEW: 'নতুন',
  SAVE_AS: 'নাম হিসাবে সংরক্ষণ করুন',
  ENTER_RECIPIENTS: 'প্রাপকদের প্রবেশ করান',
  CLEAR_SAVED_SEARCH: 'সাশ্রয়ী অনুসন্ধানগুলি সাফ করুন',
  NO_SAVED_SEARCH: 'কোনও সংরক্ষিত অনুসন্ধান পাওয়া যায় নি',
  INT_COMM_TEXT: 'অভ্যন্তরীণ যোগাযোগ পাঠ্য',
  FOLLOW_UP_TEXT: 'ফলো-আপ পাঠ্য',
  CONFIRM_DELETION: 'মুছে ফেলার বিষয়টি নিশ্চিত করুন',
  ARE_SURE: 'আপনি কি নিশ্চিত যে আপনি সংরক্ষিত অনুসন্ধান মুছতে চান?',
  PERMANENTLY_DELETED: 'অনুসন্ধান স্থায়ীভাবে <b> মুছে ফেলা হবে </b>।',
  NOTHING_SAVE: 'সংরক্ষণ করার মতো কিছুই নেই।',
  NOTHING_SEARCH: 'অনুসন্ধান করার মতো কিছুই নেই।',
  NOTHING_EXPORT: 'অনুসন্ধানের রফতানির কোনও রেকর্ড নেই।',
  SHARE_SUCCESS: 'অনুসন্ধান সফলভাবে ভাগ করা হয়েছে',
  SHARE_FAILURE: 'অনুসন্ধান ভাগ করা যায়নি',
  DELETED_SUCCESS: 'সফলভাবে সংরক্ষণ করা অনুসন্ধান মুছে ফেলা',
  ALL_DELETED_SUCCESS: 'সফলভাবে সমস্ত সংরক্ষিত অনুসন্ধান মুছে ফেলা হয়েছে',
  VIEW_SAVED_SEARCH: 'সংরক্ষণ করা অনুসন্ধান দেখুন',
  EDIT_SAVED_SEARCH: 'সংরক্ষণ করা অনুসন্ধান সম্পাদনা করুন',
  EXPORT_SAVED_SEARCH: 'রফতানি সংরক্ষিত অনুসন্ধান',
  EXPORT_SEARCH: 'রফতানি অনুসন্ধান',
  DELETE_SAVED_SEARCH: 'সংরক্ষণ করা অনুসন্ধান মুছুন',
  SELECT_EXPORT_COLUMNS: 'রফতানি করতে কলাম নির্বাচন করুন',
  SAVED_SEARCH_ALREADY_EXIST: 'ইতিমধ্যে বিদ্যমান নামটি অনুসন্ধান করুন।',
  CHARACTER_LIMIT: 'মিনিট 3 অক্ষর। সর্বোচ্চ 35 অক্ষর।',
  CHARACTER_LEFT: 'চরিত্রগুলি বাকি আছে',
  ACTIVATE: 'সক্রিয়',
  INACTIVE_USER:
    'এই ব্যবহারকারী আর সক্রিয় নেই। আপনার সিস্টেম অ্যাডমিনস্ট্রেটরের সঙ্গে যোগাযোগ করুন।',
  FOLLOW_UP: 'ফলোআপ',
  USER_NOT_FOUND: 'ব্যবহারকারী খুঁজে পাওয়া যায় না',
  DEPARTMENTS: 'বিভাগ',
  EMAIL: 'ইমেল',
  ADD_NEW_DEPARTMENT: 'নতুন বিভাগ যুক্ত করুন',
  DEPARTMENT_COUNTRY: 'দেশ',
  DEPARTMENT_COMPANY: 'প্রতিষ্ঠান',
  DEPARTMENT_TEAM: 'টীম',
  DEPARTMENT_TA: 'থেরাপিউটিক অঞ্চল',
  DEPARTMENT_PRODUCT: 'পণ্য',
  DEPARTMENT_ACTIONS: 'ক্রিয়া',
  DEPARTMENT_CONFIRMATION_1: 'মুছে ফেলার বিষয়টি নিশ্চিত করুন?',
  DEPARTMENT_CONFIRMATION_2: 'এই ক্রিয়াটি অপরিবর্তনীয়।',
  DEPARTMENT_NAME: 'বিভাগ নাম',
  TYPE_DEPARTMENT: 'প্রকার',
  COUNTRY_PLEASE_SELECT_TEAM_FIRST: 'দেশ - প্রথমে দল নির্বাচন করুন',
  DEP_NAME_CHARACTER_LIMIT: 'সর্বাধিক 200 অক্ষর।',
  AUTH_REQUESTS: {
    TITLE: {
      UserRequest: 'ব্যবহারকারীর অনুরোধ',
      CategoryTopicRequest: 'বিভাগ/বিষয় অনুরোধ',
      ProductRequest: 'পণ্য অনুরোধ',
      UserUpdateRequest: 'ব্যবহারকারী আপডেট অনুরোধ',
    },
    TYPE: {
      NewUser: 'নতুন ব্যবহারকারী',
      NewCategoryTopic: 'নতুন বিভাগ/বিষয়',
      NewUserUpdate: 'ব্যবহারকারী আপডেট',
    },
  },
  ADMINISTRATION_SECTION: {
    SPECIALIZATION: {
      DEFINE_COUNTRY_SPECIFIC_SPECIALIZATIONS: 'দেশের নির্দিষ্ট বিশেষীকরণের তালিকা সংজ্ঞায়িত করুন',
      IS_COUNTRY_SPECIFIC: 'দেশ নির্দিষ্ট',
      TYPE: 'অনুসন্ধানকারীর ধরন',
      SPECIALIZATION_EXISTS:
        '"Enquirer Type/Specialization" এর জন্য কনফিগারেশন ইতিমধ্যেই বিদ্যমান।',
    },
    AUTHORIZATION_REQUESTS: { GENDER: 'লিঙ্গ', COUNTRY: 'দেশ', TITLE: 'শিরোনাম' },
  },
  hcp: 'এইচসিপি',
  emp: 'কর্মচারী',
  cons: 'নন-এইচসিপি',
  OOO_DEPUTY: 'ওও ডেপুটি',
  NO_DEPUTY: 'কোন ডেপুটি',
  UNSPECIFIED_END_DATE: 'অনির্ধারিত শেষ তারিখ',
  DELEGATORS: 'প্রতিনিধি',
  NO_DELEGATORS: 'কোনও প্রতিনিধি নেই',
  PREVIEW_DELEGATORS: 'পূর্বরূপ প্রতিনিধি',
  REMOVE_DEPUTY: 'ডেপুটি অপসারণ',
  USERS_WHO_SET_YOU_AS_DEPUTY: 'ব্যবহারকারী যারা আপনাকে ডেপুটি হিসাবে সেট করেছেন',
  CHANGED_USER: 'পরিবর্তিত ব্যবহারকারী',
  DEFAULT: 'ডিফল্ট',
  SPECIALIZATIONS: 'বিশেষীকরণ',
  ENQUIRERS_LIST: 'Enquires তালিকা',
  EDIT_THE_RECORD: 'রেকর্ড সম্পাদনা করুন',
  DEACTIVATE_THE_RECORD: 'রেকর্ডটি নিষ্ক্রিয় করুন',
  USER_PROFILE: {
    OUT_OF_OFFICE: {
      OUT_OF_OFFICE: 'অফিসের বাইরে',
      HINT: 'কেবল সক্রিয় ব্যবহারকারীরা, তদন্ত প্রক্রিয়াজাতকরণ ভূমিকা সহ, যারা অফিসের বাইরে নেই তারা নির্বাচনের জন্য উপলব্ধ।',
      MODAL_TITLE: 'অফিস সেটিংসের বাইরে নিশ্চিত করুন',
      MODAL_MSG_1:
        'আপনি তাত্ক্ষণিক প্রভাবের সাথে অফিস বিকল্পের বাইরে চলে গেছেন, আপনার সমস্ত অনুসন্ধানগুলি অবিলম্বে ডেপুটিকে অর্পণ করা হবে।',
      MODAL_MSG_2:
        'আপনি অফিস বিকল্পের বাইরে চলে গেছেন, আপনার সমস্ত অনুসন্ধানগুলি অফিসের বাইরে চলে যাওয়ার সময় ডেপুটিকে বরাদ্দ করা হবে।',
    },
  },
  GENDER_MALE: 'জনাব.',
  GENDER_FEMALE: 'জনাবা.',
  TITLE_DR: 'ডাঃ.',
  TITLE_PHD: 'পিএইচডি',
  TITLE_PROF: 'অধ্যাপক',
  TITLE_PROF_DR: 'অধ্যাপক ড।',
  HOME: 'বাড়ি',
  HOSPITAL: 'হাসপাতাল',
  OFFICE: 'দপ্তর',
  WORK: 'কাজ',
  INSERT_IMAGE: 'চিত্র sert োকান',
  USER_ADMIN: 'ব্যবহারকারী অ্যাডমিন',
  MAILBOXES: 'মেলবক্স',
  AUTHORIZATION_REQUESTS: 'অনুমোদনের অনুরোধ',
  METADATA: 'মেটাডেটা',
  NOTIFICATIONS_PAGE: {
    TITLES: {
      ASSIGNMENT: 'অ্যাসাইনমেন্ট',
      FORWARD: 'ফরোয়ার্ড',
      FOLLOW_UP: 'ফলোআপ',
      DUE_DATE_SETTINGS: 'নির্ধারিত তারিখের সেটিংস',
      CLOSE: 'বন্ধ',
      DELIVERY_ERROR: 'বিতরণ ত্রুটি',
      SCHEDULER: 'সময়সূচী',
      USER_REPORTS: 'ব্যবহারকারী রিপোর্ট',
      DOCUMENT_APPROVAL: 'নথি অনুমোদন',
      AUTHORIZATION_REQUEST: 'অনুমোদনের অনুরোধ',
      NEW_INQUIRY: 'নতুন অনুসন্ধান',
      INQUIRY_APPROVAL: 'তদন্ত অনুমোদন',
    },
    TYPES: {
      AUTHORIZATION_REQUEST_1: 'একটি ভূমিকা অনুরোধ অনুমোদিত/প্রত্যাখ্যান করা হয়েছিল',
      AUTHORIZATION_REQUEST_3: 'একটি ভূমিকা আমার অনুমোদনের জন্য অপেক্ষা করছে',
      DOCUMENT_APPROVAL_1: 'একটি নথি আপনার অনুমোদনের জন্য অপেক্ষা করছে',
      DOCUMENT_APPROVAL_2: 'একটি নথি অনুমোদিত/প্রত্যাখ্যান করা হয়েছিল',
      DOCUMENT_APPROVAL_3: 'একটি নথি সংরক্ষণাগারভুক্ত করা হয়েছিল',
      DOCUMENT_APPROVAL_4: 'পরিবর্তনগুলি সহ একটি নথি অনুমোদিত হয়েছিল',
      DOCUMENT_APPROVAL_5: 'একটি নথি সংরক্ষণাগারভুক্ত করা হবে',
      DOCUMENT_APPROVAL_6: 'অনুমোদিত নথি তালিকা',
      DUE_DATE_2: 'অতীতের পরে পুনরাবৃত্তি',
      ERROR_1: 'বিতরণ বিজ্ঞপ্তি ব্যর্থতা (উদাঃ ভুল ইমেল)',
      FOLLOW_UP_1: 'একটি ফলোআপ বা অনুস্মারক গ্রাহকের কাছে প্রেরণ করা হয়েছিল',
      FOLLOW_UP_4: 'একটি ফলোআপ প্রাপ্ত হয়েছিল',
      IMPORT_DATA_1: 'আমদানির সময় একটি নতুন বস্তু তৈরি করা হয়েছে',
      IMPORT_DATA_4: 'আমদানির সময় একটি নতুন ব্যবহারকারী তৈরি করা হয়েছে',
      IMPORT_DATA_5:
        'একজন ব্যবহারকারী ইতিমধ্যে বিদ্যমান একটির সাথে মিলে যাচ্ছিল তবে আগের আমদানি থেকে আসেনি',
      IMPORT_DATA_6: 'বাহ্যিক উত্স থেকে ডেটা আমদানির সময় একটি ত্রুটি ঘটেছে',
      NEW_DOCUMENT_1: 'আমার দেশে নতুন অনুমোদিত নথি উপলব্ধ',
      NEW_DOCUMENT_2: 'স্থানীয় অনুমোদনের জন্য নতুন নথি উপলব্ধ',
      NEW_DOCUMENT_3: 'ইউরোপীয় / বৈশ্বিক অনুমোদনের জন্য নতুন স্থানীয় দলিল উপলব্ধ',
      NEW_DOCUMENT_4: 'নতুন গ্লোবাল ডকুমেন্ট উপলব্ধ',
      NEW_DOCUMENT_5: 'নতুন ইউরোপীয় নথি উপলব্ধ',
      NEW_DOCUMENT_6: 'নতুন ইউরোপীয় নথি বিশ্বব্যাপী অনুমোদনের জন্য উপলব্ধ',
      SCHEDULER_TASK_1: 'কোনও কার্য সম্পাদনের সময় একটি ত্রুটি ঘটেছিল',
      USER_REPORTS_1: 'সক্রিয় ব্যবহারকারীদের তালিকা',
      TEXT_GUIDES: {
        AUTHORIZATION_REQUEST_3:
          'যখন কোনও ব্যবহারকারীর ভূমিকার অনুরোধ অনুমোদিত/প্রত্যাখ্যান করা দরকার তখন আপনাকে অবহিত করে বিজ্ঞপ্তিগুলি পান',
        AUTHORIZATION_REQUEST_2:
          'যখন নতুন ব্যবহারকারী তৈরি করা হয়েছিল বা আপনার দলের কোনও ব্যবহারকারী দ্বারা কোনও ভূমিকা অনুরোধ করা হয়েছিল তখন বিজ্ঞপ্তিগুলি পান',
        AUTHORIZATION_REQUEST_4:
          'যখন কোনও নতুন বিষয় এবং/অথবা বিভাগের প্রস্তাব দেওয়া হয়েছিল তখন বিজ্ঞপ্তিগুলি পান',
        FOLLOW_UP_4:
          'জিজ্ঞাসাবাদের জন্য বিজ্ঞপ্তি পান যে আমাকে নিয়োগ দেওয়া হয়েছে যেটি অনুসন্ধানকারীর কাছ থেকে একটি ফলো আপ প্রতিক্রিয়া পেয়েছে',
        NEW_INQUIRY_1:
          'আপনার দেশে প্রাপ্ত নতুন অনুসন্ধানের জন্য বিজ্ঞপ্তি পান, একজন অনুসন্ধানকারী/ব্যবহারকারীর দ্বারা সন্নিবেশিত বা অন্য দল থেকে ফরওয়ার্ড করা হয়েছে',
        ANSWER_2:
          'অনুসন্ধানের জন্য বিজ্ঞপ্তিগুলি পান যেগুলির উত্তর দেওয়া হয়েছিল যে আমি একজন সহযোগী হিসাবে কাজ করেছি, কিন্তু সরাসরি অনুসন্ধানে নিযুক্ত নই',
        DUE_DATE_1:
          'এই বিকল্পটি নির্বাচন করলে নীচের সমস্ত বিজ্ঞপ্তি সক্রিয় হয়ে যাবে। বিজ্ঞপ্তির সংখ্যা কমাতে, প্রয়োজন নেই সেগুলি অনির্বাচন করুন৷',
        INQUIRY_APPROVAL_5:
          'আপনার প্রস্তাবিত উত্তরটি প্রত্যাখ্যাত হয়েছে অথবা ইন্টারঅ্যাকশনের মাধ্যমে বিভক্তির কারণে ইন্টারঅ্যাকশন স্ট্যাটাস অনুমোদনের জন্য জমা দেওয়া থেকে অগ্রগতিতে আপডেট করা হয়েছে, নতুন অনুসন্ধান হিসাবে মার্জ করার ফলে বিভক্তি ঘটেছে, অথবা পরবর্তী অনুসন্ধান অনুমোদনের প্রয়োজনে টিম সেটিংয়ে কোনও পরিবর্তন হয়েছে।',
        INQUIRY_APPROVAL_4: 'আপনার প্রস্তাবিত উত্তর অনুমোদিত হয়েছে।',
        METADATA_MANAGEMENT_2: 'সৃষ্টির কারণ থেকে স্বাধীনভাবে নতুন তৈরি পণ্যের জন্য বিজ্ঞপ্তি পান।',
        METADATA_MANAGEMENT_3:
          'সৃষ্টির কারণ থেকে স্বাধীনভাবে নতুন তৈরি বিভাগের জন্য বিজ্ঞপ্তি পান।',
        METADATA_MANAGEMENT_4: 'সৃষ্টির কারণ নির্বিশেষে নতুন তৈরি বিষয়ের জন্য বিজ্ঞপ্তি পান।',
        FORWARD_1:
          'ইন্টারঅ্যাকশন ডেটা সম্পাদক এবং অ্যাসিগনি ব্যবহারকারী এবং/অথবা বিভাগের দিকে এগিয়ে যাওয়া ব্যর্থতার ক্ষেত্রে Assignee এ বিজ্ঞপ্তি কাছে ফরোয়ার্ড করা হয়।',
      },
      FREQUENCIES: {
        INSTANTLY: 'তাত্ক্ষণিকভাবে',
        DAILY: 'প্রতিদিন',
        WEEKLY: 'সাপ্তাহিক',
        QUARTERLY: 'ত্রৈমাসিক',
        MONTHLY: 'মাসিক',
        SECOND_DAY: 'প্রতি দ্বিতীয় দিন',
        THIRD_DAY: 'প্রতি তৃতীয় দিন',
        ONE_WEEK: 'এক সপ্তাহ',
        ONE_MONTH: 'এক মাস',
        TWO_MONTHS: 'দুই মাস',
        THREE_MONTHS: 'তিন মাস',
        SIX_MONTHS: 'ছয় মাস',
        NINE_MONTHS: 'নয় মাস',
        EVERY_TWO_WEEKS: 'প্রতি দুই সপ্তাহ',
        EVERY_THREE_WEEKS: 'প্রতি তিন সপ্তাহে',
        EVERY_FOUR_WEEKS: 'প্রতি চার সপ্তাহে',
      },
      OPTIONS: {
        ALMOST_DUE: 'প্রায় কারণে',
        DUE_NOW: 'বাকী আছে',
        DUE_SOON: 'খুব শ্রীঘই',
        EARLY_ON: 'সকাল সকাল',
        TEXT_GUIDES: {
          DUE_NOW: 'তদন্তের সময় হলে বিজ্ঞপ্তি পান',
          EARLY_ON:
            'বকেয়া থাকা অনুসন্ধানের জন্য তাড়াতাড়ি বিজ্ঞপ্তি পান: সাধারণ 4 দিন - উচ্চ 2 দিন - নির্ধারিত তারিখের 24 ঘন্টা আগে গুরুতর',
          DUE_SOON:
            'শীঘ্রই যে অনুসন্ধানগুলি শেষ হবে তার জন্য বিজ্ঞপ্তিগুলি পান: সাধারণ 48 ঘন্টা - উচ্চ 12 ঘন্টা - নির্ধারিত তারিখের 6 ঘন্টা আগে গুরুতর',
          ALMOST_DUE:
            'প্রায় বকেয়া থাকা অনুসন্ধানগুলির জন্য বিজ্ঞপ্তিগুলি পান: সাধারণ 24 ঘন্টা - উচ্চ 6 ঘন্টা - নির্ধারিত তারিখের 3 ঘন্টা আগে গুরুতর',
        },
      },
      AUTHORIZATION_REQUEST_4: 'আমার দলে একটি বিভাগ/ বিষয় অনুরোধ করা হয়েছিল',
      AUTHORIZATION_REQUEST_2: 'ব্যবহারকারীর অনুরোধ',
      IMPORT_DATA_2: 'আমদানির সময় একটি নতুন অনুসন্ধানকারী তৈরি করা হয়েছে',
      IMPORT_DATA_3:
        'একজন অনুসন্ধানকারী ইতিমধ্যে বিদ্যমান একটির সাথে মিলেছে কিন্তু আগের আমদানি থেকে আসছে না',
      INTERNAL_1: 'আমার নির্ধারিত অনুসন্ধানে একটি নোট যোগ করা হয়েছে',
      ANSWER_1: 'আমার দ্বারা উত্তর দেওয়া ইন্টারঅ্যাকশনে উত্তরের অনুলিপি পান',
      ANSWER_6: 'আমার দ্বারা অনুমোদিত মিথস্ক্রিয়ায় উত্তরের অনুলিপি পান',
      INQUIRY_APPROVAL_5: 'আপনার প্রস্তাবিত উত্তরটি প্রত্যাখ্যান করা হয়েছে।',
      INQUIRY_APPROVAL_4: 'আপনার প্রস্তাবিত উত্তর অনুমোদিত হয়েছে।',
      METADATA_MANAGEMENT_1: 'সিআরএম আমদানির সময় ভূগোল এবং সারিবদ্ধকরণ',
      METADATA_MANAGEMENT_2: 'একটি নতুন পণ্য তৈরি করা হয়েছে',
      METADATA_MANAGEMENT_3: 'একটি নতুন বিভাগ তৈরি করা হয়েছে।',
      METADATA_MANAGEMENT_4: 'একটি নতুন বিষয় তৈরি করা হয়েছে।',
      ANSWER_2: 'আমি জড়িত যে মিথস্ক্রিয়াটির উত্তর দেওয়া হয়েছিল',
      ASSIGNMENT_1: 'মিথস্ক্রিয়া আমাকে বরাদ্দ করা হয়েছিল',
      ASSIGNMENT_5: 'আমার ডেপুটিকে মিথস্ক্রিয়া বরাদ্দ করা হয়েছিল',
      CLOSE_1: 'মিথস্ক্রিয়া বন্ধ ছিল',
      DUE_DATE_1:
        'যে মিথস্ক্রিয়াটি আমাকে অর্পণ করা হয়েছে তা নির্ধারিত তারিখের কাছাকাছি, আমাকে অবহিত করুন',
      FOLLOW_UP_7: 'মিথস্ক্রিয়া অনুসরণ করতে হারানো হয়েছিল',
      FORWARD_1: 'মিথস্ক্রিয়া ফরোয়ার্ড',
      INQUIRY_APPROVAL_1: 'মিথস্ক্রিয়া আপনার অনুমোদনের জন্য অপেক্ষা করছে',
      NEW_INQUIRY_1: 'আমার দেশে নতুন মিথস্ক্রিয়া প্রাপ্ত হয়েছিল',
      SPAM_1: 'স্প্যামে নতুন মিথস্ক্রিয়া পেয়েছে',
    },
  },
  NEW_TEAM: 'নতুন দল',
  SmPc: 'এসএমপিসি',
  USERS: 'ব্যবহারকারীরা',
  PAGINATOR_NEXT_PAGE_LABEL: 'পরবর্তী পৃষ্ঠা',
  PAGINATOR_PREV_PAGE_LABEL: 'আগের পৃষ্ঠা',
  PAGINATOR_FIRST_PAGE_LABEL: 'প্রথম পৃষ্ঠা',
  PAGINATOR_LAST_PAGE_LABEL: 'শেষ পৃষ্ঠা',
  PAGINATOR_ITEMS_PER_PAGE_LABEL: 'প্রতি পাতায় আইটেম',
  Date: 'তারিখ',
  Action: 'কর্ম',
  'Action by user': 'ব্যবহারকারী দ্বারা ক্রিয়া',
  'Changed user': 'পরিবর্তিত ব্যবহারকারী',
  'Original value': 'মূল মান',
  'New value': 'নতুন মান',
  REGION: 'অঞ্চল',
  TEAM_REGION_LABEL: 'অঞ্চল - দয়া করে প্রথমে সংস্থা নির্বাচন করুন',
  TEAM_THERAPEUTIC_AREA_LABEL: 'থেরাপিউটিক অঞ্চলগুলি - প্রথমে সংস্থাটি নির্বাচন করুন',
  TEAM_PRODUCT_LABEL: 'পণ্য - দয়া করে প্রথমে থেরাপিউটিক অঞ্চল নির্বাচন করুন',
  TEAM_COUNTRIES_LABEL: 'দেশগুলি - দয়া করে প্রথমে অঞ্চল নির্বাচন করুন',
  DEFINE_PRODUCTS_FOR_EACH_COUNTRY: 'প্রতিটি দেশের জন্য পণ্য সংজ্ঞায়িত করুন',
  PLEASE_SELECT_AT_LEAST_ONE_PRODUCT_FOR: 'দয়া করে কমপক্ষে একটি পণ্য নির্বাচন করুন',
  VIEW_CHANGES: 'পরিবর্তন দেখুন',
  Sales_Representative: 'বিক্রয় প্রতিনিধি',
  Other_Commercial: 'অন্যান্য বাণিজ্যিক',
  REGIONS: 'অঞ্চল',
  MAIN_CLIENT: 'প্রধান ক্লায়েন্ট',
  LICENSE_PARTNER: 'লাইসেন্স অংশীদার',
  TEAM_SET_UP: 'দল সেট আপ',
  LIST_TEAMS: 'দল দল',
  COMPANIES: 'সংস্থাগুলি',
  DOMAINS: 'ডোমেন',
  TOKEN_REFRESH_NOTICE:
    'আপনি আপনার এনকিউএমড অ্যাকাউন্ট নিবন্ধকরণের জন্য একটি নতুন নিশ্চিতকরণ ইমেলের অনুরোধ করেছেন।',
  TOKEN_REFRESH_SUCCESS: 'আপনার ইমেলটিতে একটি নতুন নিশ্চিতকরণ লিঙ্ক প্রেরণ করা হয়েছে।',
  TOKEN_REFRESH_ERROR:
    'একটি ত্রুটি ঘটেছে. আপনার আরও সহায়তার প্রয়োজন হলে সমর্থনের সাথে যোগাযোগ করুন।',
  PLEASE_ENTER_MAX: 'সর্বাধিক প্রবেশ করুন',
  COMPANY_ACTION_EDIT_CONFIRM_TITLE:
    'দয়া করে নীচের পরিবর্তনগুলি পর্যালোচনা করুন, যদি থাকে তবে এবং সংস্থাটি পরিবর্তন করা উচিত কিনা তা নিশ্চিত করুন।',
  COMPANY_ACTION_EDIT_WARNING_MESSAGE:
    'দয়া করে সাবধানতার সাথে এগিয়ে যান কারণ পরিবর্তনগুলি দলগুলিকে প্রভাবিত করবে।',
  THERE_ARE_NO_CHANGES: 'কোন পরিবর্তন নেই।',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'ডায়ালগের পিছনে টেবিলের বিদ্যমান সংস্থাগুলি পরীক্ষা করুন কারণ আমাদের ইতিমধ্যে একই মানগুলির সাথে সংস্থা রয়েছে।',
  SIMPLE_TEXT:
    'দয়া করে কেবল অক্ষর (এ-জেড, এ-জেড), সংখ্যা (0-9) এবং বিশেষ চিহ্ন যেমন (ড্যাশ, পিরিয়ড, স্পেস এবং কোলন) লিখুন।',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_REGIONS: 'অঞ্চলের জন্য কমপক্ষে একটি দেশ নির্বাচন করুন',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_THERAPEUTIC_AREAS:
    'থেরাপিউটিক অঞ্চলের জন্য দয়া করে কমপক্ষে একটি পণ্য নির্বাচন করুন',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_PRODUCTS:
    'আপনি পণ্য/গুলি নির্বাচন করেছেন যা একাধিক থেরাপিউটিক অঞ্চলের অন্তর্গত, দয়া করে প্রয়োজনীয় সমস্ত থেরাপিউটিক অঞ্চল নির্বাচন করুন।',
  HTML_CHANGES: 'এইচটিএমএল পরিবর্তন হয়',
  SIDE_BY_SIDE_VIEW: 'পাশাপাশি ভিউ',
  DOMAIN_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'ডায়ালগের পিছনে টেবিলের বিদ্যমান ডোমেনগুলি পরীক্ষা করুন কারণ আমাদের ইতিমধ্যে একই মানগুলির সাথে ডোমেন রয়েছে।',
  OUT_CHANNEL_ERROR_MSG: 'বহির্গামী চ্যানেল লেটার এনকায়ারারের ডাক ঠিকানা',
  OUT_CHANNEL_ERROR_MSG_LONG:
    'বহির্গামী চ্যানেল চিঠি হিসাবে নির্বাচিত। দয়া করে এনকায়ারার অঞ্চলের মধ্যে এনকায়ারারের ডাক ঠিকানা লিখুন',
  LABEL_FOR: 'জন্য লেবেল',
  male: 'পুরুষ',
  female: 'মহিলা',
  ARCHIVE_ROLE_DIALOG_WARNING_MESSAGE:
    'এই ভূমিকা সংরক্ষণাগার করার অধিকার আপনার নেই। দলের জন্য কেবল <b> মিমস </b> তাদের দলের সদস্যদের জন্য ভূমিকা সংরক্ষণাগার করতে পারে।',
  SEND_EMAIL_UPON_APPROVAL_OR_REJECTION:
    'অনুরোধ অনুমোদন/প্রত্যাখ্যানের ভিত্তিতে ব্যবহারকারীকে ইমেল প্রেরণ করুন',
  APPROVE_REQUEST_QUESTION: 'আপনি কি অনুরোধটি অনুমোদন করতে চান?',
  NEW_USER_REQUEST: 'নতুন ব্যবহারকারীর অনুরোধ',
  OPEN_REQUESTS: 'অনুরোধ খোলা',
  APPROVED_REQUESTS: 'অনুমোদিত অনুরোধ',
  REJECTED_REQUESTS: 'প্রত্যাখ্যান অনুরোধ',
  USER_REQUESTS: 'ব্যবহারকারীর অনুরোধ',
  REQUEST_FOR: 'জন্য অনুরোধ',
  CATEGORY_TOPIC_REQUESTS: 'বিভাগ/বিষয় অনুরোধ',
  WITH_THE_FOLLOWING_PAIRS: 'নিম্নলিখিত জোড়া সহ:',
  MY_TEAM_OPEN_REQUESTS: 'অনুরোধ খোলা',
  MY_TEAM_APPROVED_REQUESTS: 'অনুমোদিত অনুরোধ',
  MY_TEAM_REJECTED_REQUESTS: 'প্রত্যাখ্যান অনুরোধ',
  DOCUMENT_APPROVAL_WITHIN_ENQMED: 'এনকিউমেডের মধ্যে নথির অনুমোদন',
  DOCUMENTS_APPROVAL: 'নথি অনুমোদন',
  DOCUMENTS_APPROVAL_TITLE: 'নির্বাচিত দলের জন্য নথি অনুমোদনের প্রক্রিয়া জন্য সেটিংস',
  OUT_CHANNEL_FAX_ERROR_MSG_LONG:
    'বহির্গামী চ্যানেল ফ্যাক্স হিসাবে নির্বাচিত। দয়া করে এনকায়ারারের ফ্যাক্স নম্বরটি এনকায়ারার অঞ্চলে প্রবেশ করুন',
  OUT_CHANNEL_TELEPHONE_ERROR_MSG_LONG:
    'বহির্গামী চ্যানেল ফোন হিসাবে নির্বাচিত। দয়া করে এনকায়ারারের ফোন নম্বরটি এনকায়ারার অঞ্চলে প্রবেশ করুন',
  OUT_CHANNEL_FAX_ERROR_MSG: 'বহির্গামী চ্যানেল ফ্যাক্স এনকায়ারারের মান',
  OUT_CHANNEL_TELEPHONE_ERROR_MSG: 'বহির্গামী চ্যানেল ফোন এনকায়ারারের মান',
  COPYRIGHT_PART_ONE: 'কপিরাইট',
  COPYRIGHT_PART_TWO: 'ম্যাডজেন্টা লিমিটেড সমস্ত অধিকার সংরক্ষিত।',
  REVIEWED_DATE: 'পর্যালোচনা তারিখ',
  OUT_CHANNEL_EMAIL_ERROR_MSG_LONG:
    'বহির্গামী চ্যানেল ইমেল হিসাবে নির্বাচিত। দয়া করে এনকায়ারারের ইমেলটি এনকায়ারার অঞ্চলে প্রবেশ করুন',
  OUT_CHANNEL_EMAIL_ERROR_MSG: 'বহির্গামী চ্যানেল ইমেল এনকায়ারারের মান',
  ENTER_USER_COMMENT_OPTIONAL: 'ব্যবহারকারীর কাছে মন্তব্য লিখুন (al চ্ছিক)',
  SELECT_ANOTHER_CATEGORY: 'অন্য বিভাগ নির্বাচন করুন',
  SELECT_ANOTHER_PRODUCT: 'অন্য পণ্য নির্বাচন করুন',
  SUGGESTED_TOPIC: 'প্রস্তাবিত বিষয়',
  EDIT_USER_REQUEST: 'ব্যবহারকারীর অনুরোধ সম্পাদনা করুন',
  NEW_TOPIC_REQUEST: 'নতুন বিষয় প্রস্তাবিত',
  EDIT_TOPIC_REQUEST: 'প্রস্তাবিত বিষয় সম্পাদনা করুন',
  NEW_CATEGORY_REQUEST: 'নতুন বিভাগ প্রস্তাবিত',
  EDIT_CATEGORY_REQUEST: 'প্রস্তাবিত বিভাগ সম্পাদনা করুন',
  REVIEW_COMMENT: 'মন্তব্য পর্যালোচনা',
  DOCUMENTS_APPROVAL_WORKFLOW: {
    PAGE_TITLE: 'ডকুমেন্ট অনুমোদনের কর্মপ্রবাহ',
    CREATE_NEW: 'নতুন কর্মপ্রবাহ তৈরি করুন',
    CUSTOMIZE: 'কাস্টমাইজ ওয়ার্কফ্লো',
    NAME: 'কর্মপ্রবাহের নাম',
    ERROR_MSG_DUPLICATE_NAME: 'এই নাম সহ ওয়ার্কফ্লো ইতিমধ্যে বিদ্যমান।',
    SAVE: 'কর্মপ্রবাহ সংরক্ষণ করুন',
    DELETE: 'ওয়ার্কফ্লো মুছুন',
    STEP: 'পদক্ষেপ',
    ALL_USERS_WITH_DOC_AUTHOR_ROLE: 'ডকুমেন্ট লেখকের ভূমিকা সহ সমস্ত ব্যবহারকারী।',
    APPROVAL_DEADLINE: 'অনুমোদনের সময়সীমা',
    SELECT_APPROVAL_TYPE: 'অনুমোদনের ধরণ নির্বাচন করুন',
    SELECT_APPROVAL_TYPE_FIRST: 'প্রথমে অনুমোদনের ধরণ নির্বাচন করুন',
    SELECT_REVIEWERS: 'পর্যালোচক নির্বাচন করুন',
    ACTION_ON_APPROVE_WITH_CHANGES: 'পরিবর্তনগুলি অনুমোদনের উপর ক্রিয়া',
    ACTION_ON_REJECT: 'প্রত্যাখ্যান উপর ক্রিয়া',
    FILL_ALL_REQUIRED_FIELDS:
      'নতুন পদক্ষেপ যুক্ত করতে দয়া করে সমস্ত প্রয়োজনীয় ক্ষেত্রগুলি পূরণ করুন।',
    ALL_APPROVAL_TYPES_USED:
      'আপনি বর্তমান পদক্ষেপের জন্য উপলব্ধ সমস্ত ধরণের ব্যবহার করার সাথে সাথে আপনি আরও অনুমোদনের সাব পদক্ষেপগুলি যুক্ত করতে পারবেন না।',
    CONFIRMATION_ROW_1:
      'আপনি দলের জন্য ডকুমেন্ট অনুমোদনের কর্মপ্রবাহ মুছে ফেলার চেষ্টা করছেন {{ NAME }}.',
    CONFIRMATION_ROW_2: 'আপনি কি সত্যিই অনুমোদনের কর্মপ্রবাহ মুছে ফেলতে চান?',
    CONFIRMATION_TITLE: 'ডকুমেন্ট অনুমোদনের কর্মপ্রবাহ মুছে ফেলা',
    TEAM_VIEW_SELECT_EXISTING:
      'নীচের তালিকা থেকে একটি বিদ্যমান ডকুমেন্ট অনুমোদনের কর্মপ্রবাহ নির্বাচন করুন বা',
    DOC_VIEW_SELECT_EXISTING: 'একটি বিদ্যমান ডকুমেন্ট অনুমোদনের কর্মপ্রবাহ নির্বাচন করুন',
    ONLY_SINGLE_APPROVAL_STEP_CAN_BE_LISTED_AS_FINAL:
      'কেবলমাত্র একক অনুমোদনের পদক্ষেপ চূড়ান্ত হিসাবে তালিকাভুক্ত করা যেতে পারে।',
    APPROVAL_TYPE_TOOLTIP:
      'এই দলের জন্য ডকুমেন্ট অনুমোদনের ভূমিকা সহ কোনও ব্যবহারকারী নেই। প্রথমে অনুমোদনের ভূমিকা যুক্ত করুন।',
    INFO_DIALOG_TITLE: 'এই দলের জন্য কোনও ডকুমেন্ট অনুমোদনের ওয়ার্কফ্লো সেট নেই।',
    INFO_DIALOG_MSG:
      'দলের জন্য ডকুমেন্ট অনুমোদনের কর্মপ্রবাহ তৈরি করতে দয়া করে আপনার স্থানীয় এমআইএমের সাথে যোগাযোগ করুন।',
    STEP_LIMIT_REACHED: 'আপনি {{ MAX_STEPS }}টি অনুমোদনের ধাপের সীমায় পৌঁছেছেন৷',
  },
  DOCUMENTS_WITHOUT_APPROVAL: 'ডকুমেন্ট প্রকারগুলি যা অনুমোদনের প্রয়োজন হয় না',
  PLEASE_SELECT_TYPE: 'প্রকার নির্বাচন করুন',
  ABORT_FILE_UPLOAD: 'বাতিল ফাইল আপলোড',
  USERS_REPORT: 'ব্যবহারকারীদের রিপোর্ট',
  DOCUMENT_CREATE_CONFIRM_CLOSE_MSG: 'এই উইন্ডোটি বন্ধ করে আপনি ইতিমধ্যে ভরাট তথ্য আলগা করবেন।',
  DO_YOU_WANT_TO_PROCEED: 'আপনি কি এগিয়ে যেতে চান?',
  USERS_REPORT_DESCRIPTION:
    'সমস্ত অতিরিক্ত দলের ব্যবহারকারীদের তালিকা যেখানে দাইচি সানকিও ইউরোপ অনকোলজি এবং কার্ডিওভাসকুলার জন্য এমআইএমের একটি এমআইএম ভূমিকা রয়েছে।',
  TEMPLATE_CONFIRM_MSG: 'টেমপ্লেটটি মুছে ফেলা হয়ে গেলে এটি পুনরুদ্ধার করা যায় না!',
  EVENT_CONFIRM_MSG: 'ইভেন্টটি সংরক্ষণাগারভুক্ত হয়ে গেলে, এটি পুনরুদ্ধার করা যায় না!',
  CREATE_DRAFT: 'খসড়া তৈরি করুন',
  CREATE_USER: 'ব্যবহারকারী তৈরি করুন',
  ADD_ROLE: 'ভূমিকা যুক্ত করুন',
  DOMAIN_ADDRESS_INVALID_FOR: 'ডোমেন ঠিকানা অবৈধ',
  AVAILABLE_ADDRESSES: 'উপলভ্য ঠিকানা',
  IN_TEAM: 'দলে',
  TO_THE_SELECTED_USER: 'নির্বাচিত ব্যবহারকারীর কাছে',
  USER_INFORMATION: 'ব্যবহারকারীর তথ্য',
  LOGIN_VERIFY_EMAIL_ADDRESS:
    'আপনি যে ইমেল ঠিকানাটি ব্যবহার করছেন তা আপডেট করা দরকার যেহেতু ডোমেন ঠিকানাটি টিমের সাথে মিল নেই/এস এর সাথে সম্পর্কিত নয় <br> <br> দয়া করে সহায়তার জন্য আপনার সিস্টেম প্রশাসকের সাথে পরামর্শ করুন বা সহায়তা দলের সাথে যোগাযোগ করুন।',
  MESSAGE: 'বার্তা',
  IN_APPROVAL: 'অনুমোদনে',
  IN_REVIEW: 'পর্যালোচনা',
  DOCUMENT_APPROVAL: 'নথি অনুমোদন',
  DOCUMENT_TITLE: 'নথির শিরোনাম',
  SEARCH_DOCUMENTS: 'নথি অনুসন্ধান করুন',
  APPROVAL_INBOX: 'অনুমোদনের ইনবক্স',
  FILTER_DOCUMENTS_BY: 'ফিল্টার ডকুমেন্টস দ্বারা',
  submit_to_approval: 'অনুমোদনে জমা দিন',
  create_document_metadata: 'ডকুমেন্ট মেটাডেটা তৈরি করুন',
  archive: 'সংরক্ষণাগার',
  I_ACKNOWLEDGE_THAT_IT_IS_:
    'আমি স্বীকার করি যে স্থানীয় প্রয়োজনীয়তা অনুসারে সমস্ত চিকিত্সা পণ্যগুলির সমস্ত প্রতিকূল ড্রাগ প্রতিক্রিয়া (এডিআর) রিপোর্ট করা আমার বাধ্যবাধকতা। এএনকিউএমইডিই বিরূপ ওষুধের প্রতিক্রিয়া সম্পর্কে প্রতিবেদনগুলি ক্যাপচার করার উদ্দেশ্যে নয় - স্থানীয় প্রয়োজনীয়তা অনুসারে এডিআরগুলি সরাসরি ফার্মাকোভিজিলেন্সে রিপোর্ট করতে হবে',
  VERSION_DIALOG: {
    TITLE: 'নতুন সংস্করণ উপলব্ধ',
    MESSAGE:
      'সফটওয়্যারটির নতুন সংস্করণ পাওয়া যাচ্ছে!\n আপনাকে নতুন সংস্করণটি পেতে হবে, এটির উন্নতিগুলি থেকে উপকৃত হওয়ার জন্য এবং পুরানো সংস্করণ ব্যবহার থেকে সমস্যাগুলি এড়াতে, আপনাকে এটি পেতে হবে৷',
    SAVE_BUTTON: 'নতুন সংস্করণ পান',
  },
  ACTIVE_INACTIVE: 'সক্রিয় / নিষ্ক্রিয়',
  CATEGORY_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'অনুগ্রহ করে ডায়ালগের পিছনে থাকা সারণীতে বিদ্যমান বিভাগগুলি পরীক্ষা করুন কারণ আমরা ইতিমধ্যে একই মান সহ বিভাগ করেছি৷',
  ERROR_EMAIL_COULD_NOT_BE_DELIVERED: 'ত্রুটি! ব্যর্থ ডেলিভারি',
  VIA: 'মাধ্যমে',
  INSERTED_MANUALLY: 'ম্যানুয়ালি ঢোকানো',
  AS_A: 'হিসেবে',
  PRODUCT_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'অনুগ্রহ করে ডায়ালগের পিছনে থাকা সারণীতে বিদ্যমান পণ্যগুলি পরীক্ষা করুন কারণ আমাদের কাছে ইতিমধ্যে একই মান সহ পণ্য রয়েছে৷',
  NOT_PRODUCT_SPECIFIC: 'পণ্য নির্দিষ্ট না',
  TOPIC_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'অনুগ্রহ করে ডায়ালগের পিছনে থাকা সারণীতে বিদ্যমান বিষয়গুলি পরীক্ষা করুন কারণ আমাদের কাছে ইতিমধ্যেই একই মান সহ বিষয় রয়েছে৷',
  PLEASE_ENTER_MAX_CHARACTERS: 'অনুগ্রহ করে সর্বাধিক {{ MAX_CHARS }}টি অক্ষর লিখুন৷',
  THANK_YOU_MESSAGE: 'ধন্যবাদ বার্তা',
  AS: 'হিসাবে',
  THERAPEUTIC_AREA_PARENT: 'অভিভাবক এলাকা',
  MAIN_THERAPEUTIC_AREA: 'প্রধান থেরাপিউটিক এলাকা',
  CHOOSE_AN_IMAGE: 'একটি ইমেজ চয়ন করুন',
  ALLOWED_FORMATS: 'শুধুমাত্র {{ FORMATS }} ফর্ম্যাট অনুমোদিত৷',
  REMOVE_IMAGE: 'ছবি সরান',
  THERAPEUTIC_AREA_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'অনুগ্রহ করে ডায়ালগের পিছনে থাকা সারণীতে বিদ্যমান থেরাপিউটিক ক্ষেত্রগুলি পরীক্ষা করুন কারণ আমাদের ইতিমধ্যেই একই মান সহ এলাকা রয়েছে৷',
  PLAIN_TEXT_VIEW: 'প্লেইন টেক্সট ভিউ',
  RICH_TEXT_VIEW: 'রিচ টেক্সট ভিউ',
  EDIT_ITEM: '{{ ITEM }} সম্পাদনা করুন৷',
  CREATE_NEW_ITEM: 'নতুন {{ ITEM }} তৈরি করুন৷',
  ADD_NEW_ITEM: 'নতুন {{ ITEM }} যোগ করুন',
  ADD_ITEM: '{{ ITEM }} যোগ করুন',
  AND_HENCE_STATUS_CHANGED_FROM: 'এবং তাই অবস্থা থেকে পরিবর্তিত',
  FOLLOW_UP_WITH_ENQUIRER: 'অনুসন্ধানকারীর সাথে ফলো-আপ',
  _MUST_BE_INSERTED_TO_SAVE_REVIEW: 'পরিবর্তন সংরক্ষণ করতে নিম্নলিখিত সন্নিবেশ করা আবশ্যক:',
  FOLLOW_UP_IS_NOT_POSSIBLE_FOR_CLOSED_INTERACTION:
    'একটি বন্ধ মিথস্ক্রিয়া জন্য অনুসরণ করা সম্ভব নয়.',
  STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_REVIEW:
    'পর্যালোচনা স্থিতি মধ্যে মিথস্ক্রিয়া. এই মেনু সক্রিয় করতে পর্যালোচনা সংরক্ষণ করুন.',
  QUESTION_TEXT_MIN_SIX_CHARS: 'প্রশ্ন পাঠ্য (সর্বনিম্ন 6 অক্ষর)',
  PLEASE_PROVIDE_AN_ANSWER_:
    'অনুগ্রহ করে একটি উত্তর প্রদান করুন (ন্যূনতম 6 অক্ষর বা একটি সংযুক্তি)।',
  USER_MUST_RECOVER_PASSWORD_MESSAGE:
    "অনুগ্রহ করে মনে রাখবেন যে ব্যবহারকারীকে 'পাসওয়ার্ড ভুলে গেছেন' থেকে পাসওয়ার্ড পুনরুদ্ধার করতে হবে।",
  ENABLE_USER_LOGIN_MESSAGE: 'এটি ব্যবহারকারী লগইন সক্ষম করবে।',
  DISABLE_USER_LOGIN_AND_NOTIFICATIONS_MESSAGE:
    'এটি ব্যবহারকারীর লগইন এবং বিজ্ঞপ্তিগুলিকে অক্ষম করবে৷',
  SET_NOTIFICATIONS_PREFERENCES_MESSAGE:
    'আপনার ব্যবহারকারী প্রোফাইল থেকে আপনার বিজ্ঞপ্তি পছন্দ সেট করুন!',
  SET_PASSWORD: 'আপনার পাসওয়ার্ড সেট করুন',
  ERASED_PASSWORD_AFTER_ACCOUNT_REACTIVATION:
    'আপনার অ্যাকাউন্ট পুনরায় সক্রিয় করার পরে, আপনার পাসওয়ার্ড মুছে ফেলা হয়েছে।',
  SPECIALIZATION_DEACTIVATION_CONFIRMATION: 'বিশেষীকরণ নিষ্ক্রিয়করণ নিশ্চিত করুন!',
  REMOVE_DEACTIVATED_SPECIALIZATION_FROM_ACTIVE_LIST_MESSAGE:
    'এটি সক্রিয় বিশেষীকরণ সহ তালিকা থেকে মুছে ফেলবে৷',
  GO_TO_ROLES: 'ভূমিকাতে যান',
  CONTACT_INFORMATION_USERNAME_CHANGE_MESSAGE:
    'ব্যবহারকারীর যোগাযোগের তথ্যে আপনার পরিবর্তনগুলি লগইন ইমেলটি {{ OLD_USERNAME }} থেকে {{ NEW_USERNAME }}-এ পরিবর্তনের সূত্রপাত করেছে',
  ASSIGN_ROLE_AND_NOTIFICATIONS_MESSAGE: 'অনুগ্রহ করে ভূমিকা এবং বিজ্ঞপ্তিগুলি বরাদ্দ করুন৷',
  DO_YOU_WANT_TO_CONTINUE: 'আপনি কি অবিরত করতে চান?',
  COPY_ANSWER_TO_NEW_INTERACTION: 'নতুন মিথস্ক্রিয়া উত্তর অনুলিপি',
  NEW_INQUIRIES_HAVE_BEEN_IMPORTED_MESSAGE:
    'নতুন অনুসন্ধান আমদানি করা হয়েছে. সমস্ত অনুসন্ধানের বৈশিষ্ট্য এবং উত্তর দিতে ভুলবেন না।',
  LEAVE_WITHOUT_SAVING: 'সংরক্ষণ ছাড়াই ছেড়ে দিন',
  PENDING_CHANGES_MESSAGE: 'মুলতুবি পরিবর্তনগুলি কি {{ CHANGES }}?',
  SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_QUESTION:
    'আপনি কি নিশ্চিত যে আপনি নির্বাচিত পাঠ্যটিকে নতুন ইন্টারঅ্যাকশনে বিভক্ত করতে চান?',
  SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_MESSAGE:
    'এটি মিথস্ক্রিয়া থেকে নির্বাচিত পাঠ্যটিকে সরিয়ে দেবে এবং এটি থেকে একটি নতুন তৈরি করবে।',
  DOWNLOAD_ERROR: 'ডাউনলোড ত্রুটি!',
  DOWNLOAD_INQUIRIES_ERROR_MESSAGE:
    'আপনি 1000 টিরও বেশি অনুসন্ধানগুলি ডাউনলোড করার চেষ্টা করছেন৷ সর্বাধিক সমর্থিত সংখ্যা হল 1000৷ অনুগ্রহ করে আপনার নির্বাচন পরিমার্জন করুন৷',
  TRYING_TO_CHANGE_QUESTION_CHARACTERIZATION:
    'আপনি প্রশ্নের চরিত্রায়ন পরিবর্তন করার চেষ্টা করছেন!',
  CHANGING_QUESTION_CHARACTERIZATION_NOT_ALLOW_CHANGES_IN_ANSWER_MESSAGE:
    'অনুগ্রহ করে মনে রাখবেন প্রশ্নের চরিত্রগত পরিবর্তন পূর্বে প্রদত্ত উত্তরে কোনো পরিবর্তন বা একটি নতুন উত্তর পাঠানোর অনুমতি দেবে না!',
  RE_OPEN_CLOSED_INTERACTION_MESSAGE:
    'আপনি একটি নতুন উত্তর পাঠাতে একটি বন্ধ ইন্টারঅ্যাকশন পুনরায় খুলতে চলেছেন৷',
  CONVERT_FOLLOWUP_TO_QUESTION_MESSAGE: 'আপনি কি এই ফলো আপকে একটি প্রশ্নে রূপান্তর করতে চান?',
  ARE_YOU_SURE: 'তুমি কি নিশ্চিত?',
  USER_DEACTIVATION_CONFIRMATION_QUESTION: '{{ USER_INFO }} এর নিষ্ক্রিয়করণ নিশ্চিত করুন',
  USER_REACTIVATION_CONFIRMATION_QUESTION: '{{ USER_INFO }} এর পুনরায় সক্রিয়করণ নিশ্চিত করুন',
  USER_REACTIVATED_MESSAGE: 'ব্যবহারকারী {{ USER_INFO }} সফলভাবে পুনরায় সক্রিয় হয়েছে!',
  DELETE_NOT_POSSIBLE: 'মুছে ফেলা সম্ভব নয়!',
  SEARCH_NO_LONGER_EXIST:
    'এই অনুসন্ধানটি আর বিদ্যমান নেই কারণ এটি প্রেরকের দ্বারা মুছে ফেলা হয়েছে৷',
  LOGIN_CREDENTIALS_CHANGED: 'লগইন শংসাপত্র পরিবর্তিত!',
  EDIT_TEXT: 'লেখা সম্পাদনা',
  FOLLOW_UP_ATTEMPTS: 'ফলো-আপ প্রচেষ্টা',
  FIRST_FOLLOW_UP_INFO_TEXT: 'প্রথম ফলো-আপ পৃথক মিথস্ক্রিয়া মধ্যে পাঠানো',
  SECOND_AND_THIRD_FOLLOW_UP_INFO_TEXT:
    'প্রাথমিক অনুরোধের পরে যদি নীচের যেকোনটি নিষ্ক্রিয় করা হয় তবে প্রক্রিয়াটি পরবর্তী ধাপে স্থানান্তরিত হবে',
  SECOND_ATTEMPT: 'দ্বিতীয় প্রচেষ্টা (প্রথম অনুস্মারক)',
  THIRD_ATTEMPT: 'তৃতীয় প্রচেষ্টা (প্রথম অনুস্মারক)',
  FINAL_ATTEMPT: 'ফলো-আপে হারিয়ে গেছে (বন্ধ)',
  REMINDER_FREQUENCY: 'অনুস্মারক ফ্রিকোয়েন্সি',
  AUTO_TRANSLATE_FOLLOW_UP_TEMPLATE:
    'অনুসন্ধানকারীর সাথে যোগাযোগ করতে ব্যবহৃত ভাষায় স্বয়ংক্রিয় অনুবাদ প্রয়োগ করুন',
  ON_OFF_SWITCH: 'চালু/বন্ধ',
  DAY: 'দিন',
  DAYS: 'দিন',
  MONTH: 'মাস',
  MONTHS: 'মাস',
  RESET_TEMPLATES_TO_DEFAULTS: 'টেমপ্লেটগুলিকে ডিফল্টে রিসেট করুন',
  SAVE_FREQUENCY: 'ফ্রিকোয়েন্সি সংরক্ষণ করুন',
  SELECT_FREQUENCY: 'ফ্রিকোয়েন্সি নির্বাচন করুন',
  TEMPLATE_MODIFICATIONS_HINT:
    'পাঠ্যটি ম্যানুয়ালি পরিবর্তন করা হয়েছে এবং স্বয়ংক্রিয়ভাবে আর পরিবর্তন করা হবে না। পরিবর্তনগুলি বাতিল করতে এবং স্বয়ংক্রিয় আপডেটগুলি পুনরায় সক্ষম করতে এখানে ক্লিক করুন৷',
  LOGIN_INCORRECT_EMAIL_ADDRESS: 'ভুল ই মেইল ঠিকানা!',
  AUDIT_TRAIL_FOR: '{{ ITEM }} এর জন্য অডিট ট্রেল',
  AUDIT_TRAIL_FOR_ALL: 'সমস্ত {{ ITEM }} এর জন্য অডিট ট্রেল',
  HISTORY_CHANGES_FOR: 'জন্য ইতিহাস পরিবর্তন',
  HOLIDAY_GDPR_DISCLAIMER: 'ছুটির GDPR দাবিত্যাগ',
  MANAGE_HOLIDAY_GDPR_DISCLAIMER: 'ছুটির জিডিপিআর দাবিত্যাগ পরিচালনা করুন',
  MISSING_HOLIDAY_GDPR_TEMPLATE_FOR_TEAMS:
    'নিম্নলিখিত দল/গুলির জন্য ছুটির GDPR টেমপ্লেট অনুপস্থিত৷',
  BULK_CREATE: 'বাল্ক তৈরি করুন',
  LAST_SUCCESS: 'শেষ সাফল্য',
  LAST_FAILURE: 'শেষ ব্যর্থতা',
  FAILURE: 'ব্যর্থতা',
  TASK: 'টাস্ক',
  Weeks: 'সপ্তাহ',
  REPEAT_EVERY: 'প্রতি পুনরাবৃত্তি',
  DEACTIVATE_ITEM: '{{ ITEM }} নিষ্ক্রিয় করুন',
  RESET_FAILURE_COUNT: 'ব্যর্থতার গণনা রিসেট করুন',
  TASK_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'অনুগ্রহ করে ডায়ালগের পিছনে থাকা সারণীতে বিদ্যমান কাজগুলি পরীক্ষা করুন কারণ আমরা ইতিমধ্যে একই মানগুলির সাথে কাজ করেছি৷',
  ACTIVATE_ITEM: 'সক্রিয় করুন {{ ITEM }}',
  PLEASE_ENTER_MIN_NUMBER: 'অনুগ্রহ করে {{ MIN_NUMBER }} এর থেকে বড় নম্বর লিখুন৷',
  MERGE_AS_NEW_INQUIRY: {
    DESCRIPTION_FOR_CLOSED_INTERACTION:
      'মার্জের জন্য নির্বাচিত টার্গেট ইন্টারঅ্যাকশন ইতিমধ্যেই বন্ধ। এই অনুসন্ধানটি একটি নতুন মিথস্ক্রিয়া এবং একত্রীকরণের প্রয়োজন নেই৷',
    DESCRIPTION_FOR_OPEN_INTERACTION:
      'বিদ্যমান অনুসন্ধানে একটি নতুন প্রশ্ন/গুলি যোগ করা হবে, সমস্ত প্রশ্নের উত্তর দিতে হবে। মার্জ করার জন্য প্রশ্ন নির্বাচন করুন.',
    VALIDATION_MSG:
      'একত্রীকরণের সাথে এগিয়ে যেতে দয়া করে মার্জ করা ইন্টারঅ্যাকশন থেকে অন্তত একটি প্রশ্ন নির্বাচন করুন৷',
  },
  MERGED_INTERACTION: 'একত্রিত মিথস্ক্রিয়া',
  TARGET_INTERACTION: 'লক্ষ্য মিথস্ক্রিয়া',
  MERGE_SUGGESTION: 'সাজেশন মার্জ করুন',
  PLEASE_REVIEW_MERGE_SUGGESTION_BELOW: 'নীচের একত্রীকরণ প্রস্তাব পর্যালোচনা করুন',
  INQUIRERS_COMPARISON_TITLE:
    'মার্জড ইন্টারঅ্যাকশন এবং টার্গেট ইন্টারঅ্যাকশনের জন্য অনুগ্রহ করে বিভিন্ন অনুসন্ধানকারীদের নোট করুন',
  INQUIRERS_COMPARISON_QUESTION:
    'এই পার্থক্য থাকা সত্ত্বেও আপনি এগিয়ে যেতে চান কিনা তা নিশ্চিত করুন।',
  TERRITORY: 'এলাকা',
  DOMAIN: 'ডোমেইন',
  IN: 'ভিতরে',
  TEAM_GROUP_FILTER: {
    TITLE: 'অতিরিক্ত দল দেখার অনুমতি নির্ধারণ করুন',
    SAVE_BTN_TOOLTIP:
      'অনুগ্রহ করে দলের জন্য সমস্ত বাধ্যতামূলক ক্ষেত্র লিখুন এবং প্রথমে দলকে বাঁচান।',
  },
  ADR_REF_ID: 'ADR রেফারেন্স আইডি',
  PQC_REF_ID: 'PQC রেফারেন্স আইডি',
  ATTACHMENT_PRESENT_IN_MULTIPLE_INTERACTIONS: 'এই সংযুক্তি একাধিক মিথস্ক্রিয়া উপস্থিত হয়',
  SELECT_CHECKBOX_TO_DELETE_ATTACHMENT_FROM_ALL_INTERACTIONS:
    'আপনি যদি সমস্ত ইন্টারঅ্যাকশন থেকে সংযুক্তি মুছতে চান তাহলে অনুগ্রহ করে চেক-বক্স বিকল্পটি নির্বাচন করুন।',
  CONFIRM_DELETE_ATTACHMENT: 'আপনি এই সংযুক্তি মুছে দিতে চান তাহলে নিশ্চিত করুন.',
  DELETE_FROM_ALL_INTERACTIONS_MENTIONED_ABOVE: 'উপরে উল্লিখিত সমস্ত মিথস্ক্রিয়া থেকে মুছুন',
  ATTACHMENT_DELETED: 'সংযুক্তি মুছে ফেলা হয়েছে৷',
  ROLES: 'ভূমিকা',
  SET_TO_ALL_INQ_TOOLTIP: 'সমস্ত প্রশ্নের জন্য একই ADR, PQC এবং অফ-লেবেল চরিত্রায়ন সেট করুন।',
  TASKS_LIST: 'কাজের তালিকা',
  TASK_LOGS: 'টাস্ক লগ',
  SELECTED: 'নির্বাচিত',
  LICENSE_COUNTER: 'লাইসেন্স কাউন্টার',
  LICENSES_LIMIT: 'লাইসেন্স সংখ্যা সীমা',
  USERS_TO_EXCLUDE: 'ব্যবহারকারীদের বাদ দিতে হবে',
  ENTER_MAX_NUMBER_OF_LICENSES: 'চুক্তিবদ্ধ লাইসেন্স ফি (ব্যবহারকারীর ভূমিকা)।',
  SELECT_USER_ROLES_TO_COUNT: 'লাইসেন্স ফি গণনার জন্য প্রযোজ্য ব্যবহারকারীর ভূমিকা নির্বাচন করুন।',
  SELECT_USERS_TO_EXCLUDE_FROM_COUNT: 'লাইসেন্স ফি গণনা থেকে ব্যবহারকারীদের বাদ.',
  RECEIPT_AND_SALES_NOTIFICATIONS: 'প্রাপ্তি এবং বিক্রয় বিজ্ঞপ্তি',
  REFRESH_MIGRATION_ERRORS: 'মাইগ্রেশন ত্রুটির তালিকা রিফ্রেশ করুন',
  MARKED_AS_RESOLVED_BY: 'দ্বারা সমাধান করা হিসাবে চিহ্নিত',
  MARKED_AS_RESOLVED_ON: 'সমাধান করা হয়েছে হিসেবে চিহ্নিত করা হয়েছে',
  MARK_AS_RESOLVED: 'সমাধান হয়েছে হিসাবে চিহ্নিত করুন',
  ERROR_ON: 'ত্রুটি চালু',
  TEXT_WITH_SPECIAL_CHARS:
    'অনুগ্রহ করে শুধুমাত্র অক্ষর (a-z, A-Z), সংখ্যা (0-9), এবং বিশেষ চিহ্ন যেমন ড্যাশ (-), পিরিয়ড (.), স্পেস, কোলন (:), কমা (,), বন্ধনী ()), এগিয়ে দিন স্ল্যাশ (/), এবং প্লাস (+)।',
  STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_APPROVAL:
    'অনুমোদনের স্থিতির জন্য জমা দেওয়া মিথস্ক্রিয়া। এই মেনু সক্রিয় করতে অনুগ্রহ করে অনুমোদন/প্রত্যাখ্যান করুন।',
  REFRESH_TASK_LOGS: 'টাস্ক লগ তালিকা রিফ্রেশ করুন',
  LICENSE_COUNTER_ACTION_EDIT_CONFIRM_TITLE:
    'দয়া করে নিশ্চিত করুন, যদি আপনি পরিবর্তনগুলি নিয়ে এগিয়ে যেতে চান!',
  DEPUTY_ROLE_ARCHIVE_NOT_ALLOWED: 'নিযুক্ত ভূমিকার জন্য ম্যানুয়াল সংরক্ষণাগার অনুমোদিত নয়৷',
  RUN_COUNTER: 'রান পাল্টা',
  EXPORT_LICENSES: 'রপ্তানি লাইসেন্স',
  LICENSE_COUNT_MESSAGE: 'ব্যবহৃত লাইসেন্সের সংখ্যা হল:',
  QUESTIONS: 'প্রশ্ন',
  CLARIFICATION: 'স্পষ্টীকরণ',
  MERGE_AS_INQUIRY: {
    VALIDATION_MSG: 'মার্জ করে এগিয়ে যেতে লক্ষ্য মিথস্ক্রিয়ায় অন্তত একটি প্রশ্ন নির্বাচন করুন।',
    DESCRIPTION:
      'টার্গেট ইন্টারঅ্যাকশনে নির্বাচিত প্রশ্ন(গুলি) এর অধীনে স্পষ্টীকরণ প্রশ্ন যোগ করা হবে।',
  },
  MERGE_AS_DUPLICATE: {
    DESCRIPTION:
      'বিদ্যমান ইন্টারঅ্যাকশনের সদৃশ হিসাবে মিথস্ক্রিয়া ফ্ল্যাগ করতে এই বিকল্পটি নির্বাচন করুন।',
  },
  DETECTED_CLARIFICATION_QUESTION_WITHOUT_ANSWER:
    'স্পষ্টীকরণ প্রশ্ন হিসাবে পূর্ববর্তী মার্জ শনাক্ত করা হয়েছে। লক্ষ্য মিথস্ক্রিয়ায় পূর্বে নির্বাচিত উত্তর ক্ষেত্রগুলি ছাড়াও সমস্ত নির্বাচিত উত্তর ক্ষেত্রগুলি উত্তরের জন্য পুনরায় খোলা হবে।',
  DISABLED_ANSWER_FIELD_TOOLTIP:
    'অন্য প্রশ্নের উত্তর দেওয়ার জন্য মিথস্ক্রিয়া পুনরায় খোলা হয়েছে। এই প্রশ্নের উত্তর আগেই দেওয়া হয়েছে।',
  TIP: 'টিপ',
  MERGE_TIP_TEXT:
    'আপনি যদি এর পরিবর্তে ইন্টারঅ্যাকশন {{ MERGED_INTO_ID }} পুনরায় খুলতে চান, তাহলে অনুগ্রহ করে no চাপুন এবং লক্ষ্য মিথস্ক্রিয়া {{ MERGED_INTO_ID }} নির্দেশ করে একটি নতুন মার্জ প্রক্রিয়া শুরু করুন৷',
  YOU_ARE_SELECTING_MERGE_WITHOUT_REOPEN:
    'আপনি টার্গেট ইন্টারঅ্যাকশন না খুলেই একটি মার্জ অপারেশন নির্বাচন করছেন।',
  YOU_ARE_SELECTING_MERGE_INTO_INVALID_INTERACTION:
    'আপনি একটি অবৈধ টার্গেট ইন্টারঅ্যাকশন {{ TARGET_INTERACTION_ID }} এ মার্জ অপারেশন নির্বাচন করছেন৷<br>ইন্টার্যাকশন {{ TARGET_INTERACTION_ID }} স্ট্যাটাস {{ STATUS }}{{ LAST_MERGE_INFO }} সহ বন্ধ করা হয়েছে৷',
  CLOSED_INTERACTION_AND_INABILITY_TO_PROVIDE_ANSWER:
    'এর ফলে মিথস্ক্রিয়া বন্ধ হবে এবং উত্তর দিতে অক্ষমতা হবে।',
  INTO_INTERACTION: 'মিথস্ক্রিয়া মধ্যে',
  PLEASE_SELECT_INTERACTION_FOR_MERGE: 'মার্জ করার জন্য মিথস্ক্রিয়া নির্বাচন করুন.',
  PLEASE_SELECT_MERGE_REASON: 'মার্জ কারণ নির্বাচন করুন.',
  QUESTION_CANNOT_BE_DELETED:
    'এই প্রশ্নটি মুছে ফেলা যাবে না কারণ একটি সম্পর্কিত উত্তরহীন স্পষ্টীকরণ প্রশ্ন রয়েছে।',
  MERGE_REASON: 'একত্রিত কারণ',
  WITH_REOPEN: 'পুনরায় খোলার সাথে',
  MERGED_INTO_QUESTION_IDS: 'প্রশ্ন ID(গুলি) এ মার্জ করা হয়েছে',
  MERGED_INTO_QUESTIONS: 'প্রশ্ন(গুলি) মধ্যে একত্রিত',
  MERGED_PREVIOUSLY_FROM: 'থেকে পূর্বে একত্রিত',
  FROM_INTERACTION_ID: 'ইন্টারঅ্যাকশন আইডি থেকে',
  IN_INTERACTION_ID: 'ইন্টারঅ্যাকশন আইডিতে',
  YOU_ARE_ABOUT_TO_CANCEL_MERGE_SUGGESTION: 'আপনি একত্রীকরণ প্রস্তাব বাতিল করতে চলেছেন৷',
  DO_NOT_SHOW_MERGE_SUGGESTION_AGAIN: 'আবার মার্জ সাজেশন দেখাবেন না?',
  PRODUCT_RESOLVED_VIA_AI: 'পণ্য (AI এর মাধ্যমে সমাধান করা হয়েছে)',
  REQUIRED_FIELDS_HINT: 'সমস্ত প্রয়োজনীয় ক্ষেত্রগুলি পূরণ করুন',
  AT_LEAST_ONE_CONTACT_IS_REQUIRED: 'অন্তত একটি যোগাযোগ প্রয়োজন!',
  DISCLAIMER_INFO: 'দাবিত্যাগের তথ্য',
  QUESTION_ID: 'প্রশ্ন আইডি',
  QUESTION_IDS: 'প্রশ্ন আইডি',
  SHOW_TEAMS: 'দল দেখান',
  LAST_POLLING: 'শেষ ভোটগ্রহণ',
  HOST: 'হোস্ট',
  PROTOCOL: 'প্রোটোকল',
  FROM_EMAIL: 'ইমেইল থেকে',
  REASON_FOR_REMOVAL: 'অপসারণের কারণ',
  A_CLOSED_INTERACTION_IS_NOT_EDITABLE: 'একটি বন্ধ মিথস্ক্রিয়া সম্পাদনাযোগ্য নয়।',
  METADATA_DROPDOWNS_TOOLTIP_TEXT:
    'মিথস্ক্রিয়া একাধিক প্রশ্ন আছে. শুধুমাত্র খোলা মিথস্ক্রিয়া থেকে সম্পাদনা সম্ভব।',
  BULK_EDIT: 'বাল্ক সম্পাদনা',
  HOW_TO_ENABLE_BULK_EDIT:
    'বাল্ক আপডেট করার জন্য একই ভাষায় কমপক্ষে দুটি টেমপ্লেট নির্বাচন করতে হবে।',
  REASON_FOR_UPDATE: 'আপডেটের কারণ',
  FOLLOW_UP_RESEND: 'ফলো-আপ আবার পাঠানো হয়েছে',
  SENT_FOLLOW_UP_REMINDER: 'ফলো-আপ রিমাইন্ডার পাঠানো হয়েছে',
  CLOSED_INTERACTION_AND_SENT_LOST_TO_FOLLOW_UP_NOTIFICATION:
    'মিথস্ক্রিয়া বন্ধ এবং ফলো-আপ বিজ্ঞপ্তিতে হারিয়ে গেছে',
  ENTER_TEXT_WITH_MIN_LEN_15_CHARS: 'অনুগ্রহ করে ন্যূনতম 15 অক্ষরের দৈর্ঘ্য সহ পাঠ্য লিখুন।',
  FOLLOW_UP_DISABLED_INSERT_ENQUIRER_FIRST:
    'ফলো-আপ নিষ্ক্রিয়! অনুগ্রহ করে প্রথমে অনুসন্ধানকারীকে প্রবেশ করান।',
  FOLLOW_UP_REQUEST_DISABLED_INSERT_ENQUIRER_EMAIL_FIRST:
    'ফলো-আপ অনুরোধ নিষ্ক্রিয়! অনুগ্রহ করে প্রথমে বৈধ অনুসন্ধানকারীর ইমেল প্রবেশ করান।',
  FOLLOW_UP_FREQUENCY_VALIDATION_MSG:
    'নির্বাচিত ফ্রিকোয়েন্সি প্রকারের জন্য সংখ্যাটি 1 এবং {{ MAX_ALLOWED_FREQUENCY }} এর মধ্যে হওয়া উচিত৷',
  FOLLOW_UP_PLEASE_SELECT_FREQUENCY_TYPE: 'অনুগ্রহ করে ফ্রিকোয়েন্সি প্রকার নির্বাচন করুন।',
  FOLLOW_UP_FREQUENCY_TOOLTIP:
    'প্রাথমিক ফলো-আপ অনুরোধের তারিখ এবং সময় থেকে গণনা শুরু হয়। রূপান্তরের জন্য দিন থেকে সপ্তাহ 7 দ্বারা গুণ করুন, যেমন 2 সপ্তাহ = 14 দিন, দিন থেকে মাসের জন্য 30 দ্বারা গুণ করুন, যেমন 1 মাস = 30 দিন।',
  VALID_PHONE_FAX: 'একটি বৈধ ফোন/ফ্যাক্স নম্বর লিখুন!',
  SELECT_LANG_TO_VIEW_AND_MANAGE_TRANSLATED_TEMPLATES:
    'অনুবাদিত টেমপ্লেটগুলি দেখতে এবং পরিচালনা করতে একটি ভাষা নির্বাচন করুন৷',
  RETRANSLATE: 'পুনরায় অনুবাদ করুন',
  FOLLOW_UP_REMINDERS_INFO_TEXT:
    'নীচের টেমপ্লেটগুলি শুধুমাত্র ইংরেজি ভাষায়, অনুবাদগুলি নীচে পরিচালিত হয়৷',
  FOLLOW_UP_RESET_BTN_TOOLTIP:
    'উপরের সমস্ত টেমপ্লেট ডিফল্টে রিসেট করা হবে (ম্যানুয়াল পরিবর্তন সরানো হবে, ফ্রিকোয়েন্সি রিসেট 14 দিনে, এবং অনুবাদ চেকবক্স অনির্বাচিত), সমস্ত ভাষায় পুনরায় অনুবাদ করা হবে এবং is_manually_modified সাফ হয়ে যাবে।',
  FOLLOW_UP_EDIT_BTN_TOOLTIP:
    'টেমপ্লেট টেক্সট সম্পাদনা করুন এবং ম্যানুয়ালি পরিবর্তিত নয় এমন সমস্ত অনুবাদকে পুনরায় অনুবাদ করুন।',
  FOLLOW_UP_RETRANSLATE_BTN_TOOLTIP:
    'ম্যানুয়ালি পরিবর্তিত সহ মূল টেমপ্লেট টেক্সট পুনরায় অনুবাদ করুন।',
  FOLLOW_UP_REMINDER_TEMPLATES: 'অনুস্মারক টেমপ্লেট অনুসরণ করুন',
  MANUALLY_MODIFIED: 'ম্যানুয়ালি পরিবর্তিত',
  SYNC: 'সুসংগত',
  DIFFERENT: 'ভিন্ন',
  DEFAULT_CONFIRMATION_QUESTION: 'আপনি এগিয়ে যেতে চান তাহলে নিশ্চিত করুন.',
  SEND_WELCOME_MAIL_: 'পাসওয়ার্ড রিসেট সহ স্বাগত ইমেল পাঠান',
  CONFIRM_FU_RESPONSE_WITH_REOPEN:
    'আমি নিশ্চিত করছি যে একটি নতুন ফলো-আপ তথ্য অনুসন্ধানকারীর দ্বারা প্রাপ্ত হয়েছে এবং আমি উত্তরের বিধানের জন্য ইন্টারঅ্যাকশনটি পুনরায় খুলতে চাই।',
  ONLY_LETTERS_SPACE_AND_DASH: 'অনুগ্রহ করে শুধুমাত্র অক্ষর, স্থান এবং - প্রতীক লিখুন!',
  INFO: 'তথ্য',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG:
    'অনুসন্ধানকারী দেশের পরিবর্তন টিম {{ CURRENT_TEAM }} থেকে দল {{ FUTURE_TEAM }}-এ ইন্টারঅ্যাকশনের অ্যাসাইনমেন্ট পরিবর্তন করবে৷',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG_REMOVE_ASSIGNMENT:
    'অনুসন্ধানকারী দেশের পরিবর্তন টিম {{ CURRENT_TEAM }} থেকে দল {{ FUTURE_TEAM }}-এ ইন্টারঅ্যাকশনের অ্যাসাইনমেন্ট পরিবর্তন করবে৷ ব্যবহারকারী {{ ASSIGNED_TO }} এর কাছে {{ FUTURE_TEAM }} টিমের অনুমতি নেই এবং তাদের ব্যক্তিগত অ্যাসাইনমেন্ট সরিয়ে দেওয়া হবে৷',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG_FOR_RELATED_INTERACTIONS:
    'পরিবর্তনটি নিম্নলিখিত অতিরিক্ত ইন্টারঅ্যাকশনগুলিতে টিম অ্যাসাইনমেন্টগুলিকে প্রভাবিত করবে, যেখানে ব্যক্তিগত অ্যাসাইনমেন্টগুলি সরানো হবে: {{ RELATED_INTERACTIONS_TEAMS_AND_USERS }}',
  INQUIRER_HCP_COUNTRY_CHANGE_CONFIRM_MSG_FOR_RELATED_INTERACTIONS:
    'অনুসন্ধানকারী দেশের পরিবর্তন নিম্নলিখিত অতিরিক্ত ইন্টারঅ্যাকশনগুলিতে টিম অ্যাসাইনমেন্ট পরিবর্তন করবে, যেখানে ব্যক্তিগত অ্যাসাইনমেন্ট সরানো হবে। {{ RELATED_INTERACTIONS_TEAMS_AND_USERS }}',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_RELATED_INTERACTIONS_DATA:
    'ইন্টারঅ্যাকশন আইডি: {{ INTERACTION_ID }} {{ CURRENT_TEAM }} থেকে {{ FUTURE_TEAM }}, ব্যবহারকারী {{ ASSIGNED_TO }} থেকে ব্যক্তিগত অ্যাসাইনমেন্ট সরানো হয়েছে',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'ব্যবহারকারী {{ EDITING_USER }} ইন্টারঅ্যাকশন আইডিতে জিজ্ঞাসাকারীর {{ ENQUIRER }} দেশ পরিবর্তন করেছেন: {{ UPDATED_INTERACTION_ID }}, যা বর্তমান ইন্টারঅ্যাকশনের অ্যাসাইনমেন্ট {{ OLD_TEAM }} থেকে দল {{ NEW_TEAM }}-এ পরিবর্তন করেছে৷',
  INQUIRER_HCP_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'ব্যবহারকারী {{ EDITING_USER }} HCP প্রশাসনে প্রশ্নকারীর {{ ENQUIRER }} দেশ পরিবর্তন করেছেন, যা বর্তমান ইন্টারঅ্যাকশনের অ্যাসাইনমেন্ট {{ OLD_TEAM }} থেকে দল {{ NEW_TEAM }}-এ পরিবর্তন করেছে৷',
  INQUIRER_COUNTRY_CHANGE_ADDITIONAL_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'ব্যবহারকারী {{ INTERACTION_ASSIGN_TO }} এর কাছে {{ NEW_TEAM }} টিমের অনুমতি নেই এবং তাদের ব্যক্তিগত অ্যাসাইনমেন্ট সরানো হয়েছে৷',
  PERSONAL_ASSIGNMENT: 'ব্যক্তিগত অ্যাসাইনমেন্ট',
  DUE_TO_FU_REQUEST: 'ফলো-আপ অনুরোধের কারণে',
  DUE_TO_FU_RESPONSE: 'ফলো-আপ প্রতিক্রিয়ার কারণে',
  DUE_TO_FU_REMINDERS_FREQ_UPDATE:
    'অ্যাডমিন পৃষ্ঠা থেকে ফলো-আপ অনুস্মারক ফ্রিকোয়েন্সি আপডেটের কারণে',
  UPDATED_FROM_INBOX: 'ইনবক্স থেকে আপডেট করা হয়েছে',
  ARCHIVED_ROLE: 'আর্কাইভ ভূমিকা',
  USER_ROLE_ARCHIVE_CONFIRM_MSG_INTERACTIONS_DATA:
    'ব্যবহারকারীর ভূমিকা আর্কাইভ করা নিম্নলিখিত অতিরিক্ত ইন্টারঅ্যাকশনগুলিতে অ্যাসাইনমেন্টগুলিকে পরিবর্তন করবে, যেখানে ব্যক্তিগত অ্যাসাইনমেন্টগুলি সরানো হবে: {{ USER_INTERACTIONS }}',
  USER_ROLE_ARCHIVE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'ব্যবহারকারী {{ EDITING_USER }} {{ INTERACTION_ASSIGN_TO }} ব্যবহারকারীকে সম্পাদনা করেছেন, {{ TEAM }} টিমে {{USER_ROLE }} হিসাবে তাদের ভূমিকা সংরক্ষণ করেছেন৷ ব্যবহারকারী {{ INTERACTION_ASSIGN_TO }} এর কাছে {{ TEAM }} টিমের অনুমতি নেই এবং তাদের ব্যক্তিগত অ্যাসাইনমেন্ট সরানো হয়েছে৷',
  ASSIGNMENT: 'অ্যাসাইনমেন্ট',
  PERIOD_TO_PERIOD_OVERLAP: 'পিরিয়ড এবং টু পিরিয়ড ওভারল্যাপ হচ্ছে',
  PERIOD_TO_PERIOD_MISMATCH:
    'পিরিয়ড এবং টু পিরিয়ডের আলাদা আলাদা সময়কাল আছে - {{ PERIOD }} {{ INTERVAL }} এবং {{ TO_PERIOD }} {{ TO_INTERVAL }}',
  CREATED_QUESTION_FROM_FU_RESPONSE: 'ফলো-আপ প্রতিক্রিয়া থেকে প্রশ্ন তৈরি করা হয়েছে',
  CREATE_QUESTION_FROM_FU_RESPONSE_TOOLTIP:
    'এই বিকল্পটি ফলো-আপ থেকে একটি নতুন প্রশ্ন তৈরি করার অনুমতি দেয়।',
  ADD_AS_A_NEW_QUESTION: 'একটি নতুন প্রশ্ন হিসাবে যোগ করুন',
  ROLE_ARCHIVE_WITH_USER_DEACTIVATION:
    'অনুগ্রহ করে সচেতন থাকুন আপনি এই ব্যবহারকারীর জন্য সর্বশেষ অনুমোদিত ভূমিকা সংরক্ষণাগার করার চেষ্টা করছেন, যা ব্যবহারকারীকে নিষ্ক্রিয় করে দেবে এবং EnqMed-এ তাদের আরও অ্যাক্সেসকে বাধা দেবে।',
  ATTEMPTED_FOLLOW_UP: 'একটি ফলো-আপ চেষ্টা',
  FAILED_FU_ATTEMPT: 'ব্যর্থ ফলো-আপ প্রচেষ্টা',
  UNABLE_TO_REACH_ENQUIRER: 'অনুসন্ধানকারীর কাছে পৌঁছাতে অক্ষম।',
  FAILED_FU_ATTEMPT_TOOLTIP:
    'এই বিকল্পটি নির্বাচন করুন যদি অনুসন্ধানকারীর কাছে পৌঁছানোর চেষ্টা করা হয় কিন্তু সফল না হয়, যেমন ফোনের মাধ্যমে পৌঁছাতে অক্ষম, ব্যর্থ F2F বা অন্য অ্যাপয়েন্টমেন্ট।\nপ্রচেষ্টা মিথস্ক্রিয়া ইতিহাসে রেকর্ড করা হবে.',
  REDIRECT_TO_PAGE_WITH_PERMISSION_ACCESS:
    'আপনাকে এমন একটি পৃষ্ঠায় পুনঃনির্দেশিত করা হবে যার জন্য আপনার অনুমতি রয়েছে৷',
  FU_STOPPED_DUE_TO_ENQ_CHANGE: 'অনুসন্ধানকারী পরিবর্তনের ফলে ফলো-আপ প্রক্রিয়া বন্ধ হওয়ার কারণে',
  YOU_ARE_ABOUT_TO_SWITCH_ENQ_AND_STOP_FU:
    'আপনি অনুসন্ধানকারীকে স্যুইচ করতে চলেছেন, যা ফলো-আপ প্রক্রিয়া বন্ধ করবে।',
  INQUIRER_DETAILS: 'অনুসন্ধানকারীর বিবরণ',
  INQUIRER_TYPE: 'অনুসন্ধানকারীর ধরন',
  DELETE_INQUIRER_PII: 'অনুসন্ধানকারীর ব্যক্তিগত তথ্য মুছুন',
  MARKED_INQUIRER_PII_DELETE: 'মুছে ফেলার জন্য চিহ্নিত অনুসন্ধানকারীর ব্যক্তিগত তথ্য',
  UNMARKED_INQUIRER_PII_DELETE: 'অচিহ্নিত অনুসন্ধানকারীর ব্যক্তিগত তথ্য মুছে ফেলার জন্য',
  NEW_ENQUIRER: 'নতুন অনুসন্ধানকারী',
  NEW_ENQUIRER_TOOLTIP:
    'একটি সম্পূর্ণ নতুন অনুসন্ধানকারী তৈরি করতে এই বিকল্পটি ব্যবহার করুন। পরিবর্তে বিদ্যমান অনুসন্ধানকারী ডেটা সম্পাদনা করার প্রয়োজন হলে এই বিকল্পটি ব্যবহার করবেন না।',
  CONFIRM_SET_NEW_ENQUIRER:
    'অনুগ্রহ করে নিশ্চিত করুন যে আপনি বিদ্যমান অনুসন্ধানকারীকে একটি নতুন দিয়ে প্রতিস্থাপন করতে চান৷<br>প্রবেশ করা সমস্ত তথ্য হারিয়ে যাবে৷<br>আপনি যদি এর পরিবর্তে বিদ্যমান অনুসন্ধানকারীর ডেটা সম্পাদনা করতে চান তবে ফর্মটিতে সরাসরি ডেটা পরিবর্তন করুন এবং সংরক্ষণ করুন টিপুন৷ .',
  INQUIRER: 'অনুসন্ধানকারী',
  KEEP_INQUIRER_DETAILS_OPEN: 'Enquirer বিবরণ খোলা রাখুন',
  LOST_UNSAVED_CHANGES_ON_INTERACTION_CLOSE_MESSAGE:
    'আপনার অসংরক্ষিত পরিবর্তন আছে, যা আপনি যদি অনুসন্ধানকারীর বিশদ বিবরণ বন্ধ করে দেন তাহলে হারিয়ে যাবে।',
  UPDATED_INQUIRER_DETAILS: 'অনুসন্ধানকারীর বিবরণ আপডেট করা হয়েছে',
  CREATED_INQUIRER: 'অনুসন্ধানকারী তৈরি করা হয়েছে',
  SUGGESTED_INQ: 'প্রস্তাবিত অনুসন্ধানকারী',
  SELECT_INQUIRER: 'অনুসন্ধানকারী নির্বাচন করুন',
  ADR_OBLIGATION_CONSENT_AND_INQUIRER_:
    'মিথস্ক্রিয়া জমা দেওয়ার জন্য ADR বাধ্যবাধকতার সম্মতি এবং অনুসন্ধানকারীকে অবশ্যই নির্বাচন করতে হবে',
  ANSWER_HAS_NOT_BEEN_SENT_TO_THE_INQ_:
    'ভুল ইমেল ঠিকানার কারণে উত্তর অনুসন্ধানকারীকে পাঠানো হয়নি।',
  MSG_NOTIFICATION_WILL_BE_SEND_1:
    'ধন্যবাদ! তদন্ত জমা দেওয়া বা উত্তর দেওয়া হলে বিজ্ঞপ্তি পাঠানো হবে।',
  MSG_NOTIFICATION_WILL_BE_SEND_2: 'ধন্যবাদ! অনুসন্ধানকারী সংরক্ষণ করা হলে বিজ্ঞপ্তি পাঠানো হবে।',
  NOTIFICATION_WILL_BE_SENT_TO_THE_INQ:
    'নিম্নলিখিত GDPR বিজ্ঞপ্তি ইমেলের মাধ্যমে অনুসন্ধানকারীকে পাঠানো হবে।',
  NOTIFICATION_SENT_TO_THE_INQ:
    'নিম্নলিখিত জিডিপিআর বিজ্ঞপ্তিটি ইমেলের মাধ্যমে অনুসন্ধানকারীকে পাঠানো হয়েছে।',
  SEND_A_NOTIFICATION_TO_THE_INQ:
    'নিম্নলিখিত জিডিপিআর বিজ্ঞপ্তিটি ইমেলের মাধ্যমে জিজ্ঞাসাকারীকে পাঠান।',
  INQ_HAS_NO_EMAIL: 'অনুসন্ধানকারীর কোন ইমেল নেই।',
  A_NOTIFICATION_HAS_NOT_BEEN_TO_INQ:
    'অনুসন্ধানকারীকে একটি বিজ্ঞপ্তি পাঠানো হয়নি যে তদন্ত গৃহীত হয়েছে।',
  NOTIFICATION_SENT_TO_THE_INQUIRER: 'অনুসন্ধানকারীকে পাঠানো বিজ্ঞপ্তি যে তদন্ত গৃহীত হয়েছে।',
  THIS_IS_JUST_A_THANK_YOU:
    'এটি বিদ্যমান অনুসন্ধানের অনুসন্ধানকারীর কাছ থেকে শুধুমাত্র একটি ধন্যবাদ বার্তা।',
  SAVE_INQUIRER: 'Enquirer সংরক্ষণ করুন',
  INQ_DEFAULT_VAL: 'Enquirer ডিফল্ট মান',
  COMPANY_EMPLOYEE_SUBMISSION_TOOLTIP:
    'কোম্পানির প্রতিনিধি (বিক্রয় প্রতিনিধি, MSL, বা অন্য) মাধ্যমে জমা দেওয়া অনুসন্ধানের জন্য, তাদের ব্যক্তিগত ডেটা পরিচালনার বিষয়ে অনুসন্ধানকারীকে অবহিত করুন।',
  SELECT_PREFERRED_CONTACT_ERROR:
    'আপনাকে অবশ্যই অনুসন্ধানকারীর জন্য একটি পছন্দের পরিচিতি নির্বাচন করতে হবে।',
  FOLLOW_UP_INITIATED_BY_INQUIRER: 'অনুসন্ধানকারীর দ্বারা ফলো-আপ শুরু হয়েছে',
  NO_INQUIRER: 'কোন অনুসন্ধানকারী',
  'Changed inquirer': 'পরিবর্তিত অনুসন্ধানকারী',
  QUESTION_REQUIRE_ANSWER_MESSAGE:
    'অনুসন্ধান বন্ধ করার জন্য নতুন প্রশ্নের একটি উত্তর প্রয়োজন হবে।',
  IMAGE: 'ছবি',
  THERAPEUTIC_AREA_STATUS_MESSAGE: 'থেরাপিউটিক এলাকা হল {{ STATUS }}',
  THERAPEUTIC_AREA_SELECT_PRODUCT_TO_ACTIVATE:
    ', কোনো সংশ্লিষ্ট পণ্য নেই. থেরাপিউটিক এলাকা সক্রিয় করার জন্য অন্তত একটি পণ্য নির্বাচন করুন.',
  EXPAND_ALL_THERAPEUTIC_AREAS: 'সমস্ত থেরাপিউটিক এলাকা প্রসারিত করুন',
  COLLAPSE_ALL_THERAPEUTIC_AREAS: 'সমস্ত থেরাপিউটিক এলাকায় সঙ্কুচিত',
  THERAPEUTIC_AREA_EDIT_PARENT_HINT:
    'একটি থেরাপিউটিক এরিয়া (TA) কে প্রধান TA হিসাবে সেট করতে, প্যারেন্ট TA নির্বাচন করুন এবং সংরক্ষণ করুন।',
  EXTRA_STEP_REQUIRED_TO_LOGIN: 'লগইন করার জন্য অতিরিক্ত ধাপ প্রয়োজন',
  EXTRA_STEP_DESCRIPTION:
    'লগইন নিশ্চিতকরণ আপনার ইমেল পাঠানো হয়েছে. অনুগ্রহ করে, সিস্টেমে চালিয়ে যাওয়ার জন্য এটির ধাপগুলি অনুসরণ করুন৷',
  ENTER_TEXT_WITH_MIN_LEN_15_CHARS_OR_ATTACHMENT:
    'অনুগ্রহ করে ন্যূনতম 15 অক্ষর বা একটি সংযুক্তি সহ পাঠ্য লিখুন।',
  REASON_FOR_REJECTION: 'প্রত্যাখ্যানের কারণ',
  NOTE_PROPERLY_INSERTED_IN_HISTORY: 'নোট সঠিকভাবে ইতিহাস সন্নিবেশ করা হয়েছে.',
  METADATA_ACTION_EDIT_CONFIRM_TITLE:
    '{{ ACTION_TYPE }} দ্বারা নির্বাচিত {{ METADATA_TYPE }}, নিম্নলিখিত অতিরিক্ত সম্পর্কিত পরিবর্তনগুলি প্রযোজ্য হবে:',
  THERAPEUTIC_AREA_ACTION_EDIT_CONFIRM_TITLE:
    'পণ্যগুলি সরানোর মাধ্যমে, নিম্নলিখিত অতিরিক্ত সম্পর্কিত পরিবর্তনগুলি প্রযোজ্য হবে:',
  TEAM_NOTIFY_INQUIRER: 'অনুসন্ধানকারীকে অবহিত করুন',
  TEAM_NOTIFY_INQUIRER_TOOLTIP:
    'এই বিকল্পটি সক্ষম করলে এই টিমের খোলামেলা ইন্টারঅ্যাকশনে অনুসন্ধানকারীদের কাছে স্বয়ংক্রিয়ভাবে GDPR বিজ্ঞপ্তি পাঠানো হবে যা এখনও অবহিত করা হয়নি।',
  TEAM_NOTIFY_SALES: 'বিক্রয় অবহিত করুন',
  TEAM_NOTIFY_SALES_TOOLTIP:
    'এই বিকল্পটি সক্ষম করলে এই দলে বিক্রয় ভূমিকা সহ সমস্ত ব্যবহারকারীর জন্য উত্তর জড়িত বিজ্ঞপ্তি সক্রিয় হবে৷\n\nএই বিকল্পটি নিষ্ক্রিয় করা এই টিমের বিক্রয় ভূমিকা সহ সমস্ত ব্যবহারকারীর জন্য উত্তর জড়িত বিজ্ঞপ্তি নিষ্ক্রিয় করবে, যদি না তারা বিজ্ঞপ্তি বিক্রয় সেটিং সক্ষম করে অন্য দলে বিক্রয় ভূমিকা না রাখে৷',
  RECEIVES_THE_FOLLOWING_NOTIFICATION: 'নিম্নলিখিত বিজ্ঞপ্তি পায়',
  EMAILS: 'ইমেইল',
  PHONES: 'ফোন',
  USERS_DEACTIVATED_SUCCESSFULLY: 'ব্যবহারকারী/গুলি সফলভাবে নিষ্ক্রিয় করা হয়েছে৷',
  CONFIRM_BULK_DEACTIVATION_OF_USERS: 'ব্যবহারকারীদের বাল্ক নিষ্ক্রিয়করণ নিশ্চিত করুন',
  DEACTIVATE_SELECTED_USERS: 'নির্বাচিত ব্যবহারকারীদের নিষ্ক্রিয় করুন',
  SELECT_USERS_TO_DEACTIVATE: 'নিষ্ক্রিয় করতে ব্যবহারকারীদের নির্বাচন করুন',
  ARCHIVE_FOLDER: 'আর্কাইভ ফোল্ডার',
  EXTRA_PARAMS:
    "অতিরিক্ত প্যারামিটার (k1=v1&k2=v2, 'ফোল্ডার' এবং 'আর্কাইভ' সংরক্ষিত এবং ব্যবহার করা যাবে না)",
  POLL_FOLDER: 'পোল ফোল্ডার',
  POLL_FOLDERS_TEXT:
    'পোল ফোল্ডারগুলি শুধুমাত্র প্রোটোকল imap বা gmail এর জন্য সেট করা যেতে পারে৷ অন্য সব প্রোটোকলের জন্য, ডিফল্ট হল ইনবক্স।',
  POLL_FOLDERS_TOOLTIP_1:
    'ফোল্ডারের নাম টাইপ করুন এবং এন্টার টিপুন বা মেলবক্স থেকে পোলিং করার জন্য একটি কমা ব্যবহার করুন (যদি কোনো ফোল্ডার যোগ করা না হয়, ইনবক্সটি ডিফল্টরূপে পোল করা হবে)।',
  POLL_FOLDERS_TOOLTIP_2: 'এখানে তালিকাভুক্ত ফোল্ডারের নামগুলি মেলবক্স থেকে পোল করা হবে।',
  PERMISSION: 'অনুমতি',
  ROLE_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'অনুগ্রহ করে ডায়ালগের পিছনে থাকা সারণীতে বিদ্যমান ভূমিকাগুলি পরীক্ষা করুন কারণ আমাদের ইতিমধ্যে একই নামের ভূমিকা রয়েছে৷',
  CURR_MAILBOX: 'বর্তমান মেইলবক্স',
  ENQUIRY_ANSWER_APPROVAL: 'তদন্ত উত্তর অনুমোদন',
  REASON_FOR_INCLUSION: 'অন্তর্ভুক্তির কারণ',
  FOLDERS_TO_POLL_FROM_MAILBOX: 'মেইলবক্স থেকে পোল করার জন্য ফোল্ডার',
  VERIFY_SETUP: 'সেটআপ যাচাই করুন',
  HTML_PREVIEW: 'HTML প্রিভিউ',
  IN_REPLY_TO_ID: 'আইডির উত্তরে',
  INCOMING: 'ইনকামিং',
  OUTGOING: 'বহির্গামী',
  VIEW: 'দেখুন',
  ROLE_IN_USE_USERS:
    'ভূমিকাটি বর্তমানে ব্যবহারকারীদের জন্য বরাদ্দ করা হয়েছে এবং এটি সংরক্ষণাগার তাদের অ্যাক্সেসের অধিকার সীমিত করতে পারে',
  ROLE_IN_USE_USER_TEMPLATES:
    'ভূমিকা বর্তমানে ব্যবহারকারী টেমপ্লেট দ্বারা ব্যবহৃত হয় এবং এটি সংরক্ষণাগার তাদের থেকে এটি মুছে ফেলা হবে৷',
  ROLE_IN_USE_LICENSE_COUNTER:
    'ভূমিকাটি বর্তমানে লাইসেন্স কাউন্টারে ব্যবহৃত হয় এবং এটি সংরক্ষণাগার থেকে এটি সরিয়ে ফেলা হবে৷',
  REASON_FOR_REPLACEMENT: 'প্রতিস্থাপনের কারণ',
  QUESTION_TEXT_AFTER_SPLIT: 'বিভক্তির পরে প্রশ্নের টেক্সট',
  TEAM_ENQUIRY_ANSWER_APPROVAL_TOOLTIP:
    'টিমের জন্য অনুসন্ধানের উত্তর অনুমোদনের কর্মপ্রবাহ বন্ধ করে দেওয়া হবে। "অনুমোদনের জন্য জমা দেওয়া" স্ট্যাটাসে থাকা ইন্টারঅ্যাকশনগুলি প্রভাবিত হবে এবং "প্রগতিতে" স্ট্যাটাসে ফিরে যাবে যাতে অনুমোদন ছাড়াই সঠিক উত্তর প্রস্তুতি এবং উত্তর দেওয়া সম্ভব হয়।',
  REVERT_TO_IN_PROGRESS: 'অগ্রগতিতে প্রত্যাবর্তন করুন',
  SPLIT_IN_INTERACTION_IN_APPROVAL_STATE_MESSAGE:
    '{{ SUBMITTED_FOR_APPROVAL }} স্ট্যাটাসে থাকাকালীন প্রশ্ন বিভাজন সম্পন্ন হয়। এই ক্রিয়াটি নতুন তৈরি করা প্রশ্ন/প্রশ্নের উত্তর প্রস্তুতির জন্য স্থিতিটিকে {{ IN_PROGRESS }} এ ফিরিয়ে আনে।',
  MERGE_IN_INTERACTION_IN_APPROVAL_STATE_MESSAGE:
    'লক্ষ্য ইন্টারঅ্যাকশন {{ MERGE_AS_ADDITIONAL_QUESTION }} অবস্থায় থাকাকালীন, বর্তমান ইন্টারঅ্যাকশনে {{ SUBMITTED_FOR_APPROVAL }} ক্রিয়াটি সম্পাদিত হয়। এই ক্রিয়াটি নতুন তৈরি করা প্রশ্ন/গুলি-র উত্তর প্রস্তুতির জন্য স্থিতিটিকে {{ IN_PROGRESS }} এ ফিরিয়ে আনে।',
  ENQUIRY_ANSWER_APPROVAL_TITLE: 'নির্বাচিত দলের জন্য অনুসন্ধান উত্তর অনুমোদন সেট করুন',
  APPROVAL_ASSIGN_TO: 'অনুমোদনের জন্য বরাদ্দ করা হয়েছে',
  APPROVAL_ASSIGN_BY: 'অনুমোদনের জন্য বরাদ্দ করা হয়েছে',
  APPROVAL_ASSIGNMENTS: 'অনুমোদনের অ্যাসাইনমেন্ট',
  APPROVAL_ASSIGNMENTS_TO: 'অনুমোদনের জন্য অ্যাসাইনমেন্ট',
  APPROVAL_ASSIGNMENTS_BY: 'Enq. দ্বারা অনুমোদন অ্যাসাইনমেন্ট',
  My_pending_approvals: 'আমার মুলতুবি অনুমোদনগুলি',
  RULE: 'নিয়ম',
  RULES: 'নিয়ম',
  FIELD: 'মাঠ',
  CRITERIA: 'মানদণ্ড',
  NUMBER: 'সংখ্যা',
  MIN: 'ন্যূনতম',
  MAX: 'সর্বোচ্চ',
  ADD_GROUP: 'গ্রুপ যোগ করুন',
  DELETE_GROUP: 'গ্রুপ মুছুন',
  VALID_FROM_TO: 'থেকে/ থেকে বৈধ',
  NEW_INQ: 'নতুন প্রশ্ন',
  NOT_APPROVING_INQUIRIES: 'অনুসন্ধান অনুমোদন না',
  DOCUMENT_EXTERNAL_URL: 'বাহ্যিক URL ডকুমেন্ট',
  QUESTION_CHARACTERISATION: 'প্রশ্ন বৈশিষ্ট্য',
  MAIL_IMPORT_DOMAIN_SETTINGS_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'সংলাপের পিছনে টেবিলে বিদ্যমান ডোমেনগুলি রেজেক্সটি পরীক্ষা করুন কারণ আমরা ইতিমধ্যে একই মানগুলির সাথে ডোমেন রেজেক্স ট্র্যাক করেছি।',
  TEAMS_DOMAINS: 'দল ডোমেন',
  MAIL_IMPORT_DOMAIN_SETTINGS: 'মেল আমদানি ডোমেন সেটিংস',
  MAIL_IMPORT_DOMAINS_SETTINGS: 'মেল আমদানি ডোমেন সেটিংস',
  THE_ACTION_IS_IRREVERSIBLE: 'কর্মটি অপরিবর্তনীয়!',
  USER_DOMAINS: 'ব্যবহারকারী ডোমেন',
  SPAM_DOMAINS: 'স্প্যাম ডোমেন',
  DOMAIN_REGEX: 'ডোমেন রেজেক্স',
  REGEX_PYTHON_INFORMATION: 'পাইথন সমর্থিত রেজেক্স সম্পর্কিত আরও তথ্যের জন্য ক্লিক করুন।',
  USER_DOMAINS_TOOLTIP:
    'কোন ডোমেনগুলি (নির্দিষ্ট ডোমেন বা কী শব্দের সাথে জমা দেওয়া ইমেলগুলি) সংজ্ঞায়িত করে এনকায়ারার ক্ষেত্রটি ফাঁকা ছেড়ে দেবে।',
  SPAM_DOMAINS_TOOLTIP:
    'বার্তাগুলি সংজ্ঞায়িত করে যার সাথে ডোমেনগুলি (নির্দিষ্ট ডোমেন বা কী শব্দের সাথে জমা দেওয়া ইমেলগুলি) সিস্টেমে প্রবেশের মিথস্ক্রিয়ায় রূপান্তরিত হবে না তবে স্প্যাম হিসাবে পতাকাঙ্কিত থাকবে।',
  EMAIL_SUBJECT_TEMPLATE_HINT_1:
    'ই-মেইল বিষয়টি টিম {{ TEAM }} তে নির্ধারিত প্রতিটি মিথস্ক্রিয়ায় দৃশ্যমান হবে, এমন ক্ষেত্রে যেখানে ই-মেইল বিষয়টি ম্যানুয়ালি সম্পাদনা করা হয়নি এবং ইন্টারঅ্যাকশনটি ম্যানুয়ালি তৈরি করা হয়েছে।',
  EMAIL_SUBJECT_TEMPLATE_HINT_2:
    'ই-মেইলের মাধ্যমে এনকিউএমডেডে জমা দেওয়া মিথস্ক্রিয়ায়, ই-মেইল বিষয়টি ই-মেইল শিরোনাম থেকে নেওয়া হবে।',
  CC: 'সিসি',
  SET_INTERACTION_AS_BEING_PART_OF_AN_EVENT: 'কোনও ইভেন্টের অংশ হিসাবে ইন্টারঅ্যাকশন সেট করুন',
  UPDATED_EVENT: 'আপডেট ইভেন্ট',
  REMOVED_INTERACTION_AS_BEING_PART_OF_AN_EVENT:
    'কোনও ইভেন্টের অংশ হিসাবে ইন্টারঅ্যাকশন সরানো হয়েছে',
  FORWARD: 'ফরোয়ার্ড',
  VALID_FROM: 'থেকে বৈধ',
  VALID_TO: 'বৈধ',
  WATCHERS: 'প্রহরী',
  WATCH_OPTIONS: 'বিকল্প দেখুন',
  START_WATCHING: 'দেখা শুরু করুন',
  STOP_WATCHING: 'দেখা বন্ধ করুন',
  UPDATE_WATCHERS: 'মিথস্ক্রিয়ায় আপডেট হওয়া প্রহরীদের',
  ACTIVATE_PERMISSION_EDIT: 'সক্রিয় অনুমতি সম্পাদনা',
  DEACTIVATE_PERMISSION_EDIT: 'নিষ্ক্রিয় অনুমতি সম্পাদনা',
  SHARE_FROM_HERE_YOU_CAN_:
    'শেয়ার: এখান থেকে আপনি অভ্যন্তরীণ/বাহ্যিক ব্যবহারকারী বা বিভাগগুলিতে ইন্টারঅ্যাকশন ডেটা ফরোয়ার্ড করতে পারেন।',
  QUESTIONS_TO_BE_SENT: 'প্রশ্ন পাঠানো হবে',
  SHOW_QUESTION: 'প্রশ্ন দেখান',
  HIDE_QUESTION: 'প্রশ্ন লুকান',
  SHOW_QUESTION_TEXT: 'প্রশ্ন পাঠ্য দেখান',
  HIDE_QUESTION_TEXT: 'প্রশ্ন পাঠ্য লুকান',
  SEARCH_RECIPIENTS: 'অনুসন্ধান প্রাপক',
  SEARCH_PARTICIPANTS: 'অংশগ্রহণকারীদের অনুসন্ধান করুন',
  SEARCH_USER: 'ব্যবহারকারী অনুসন্ধান',
  SEARCH_RECIPIENTS_TO_SHARE_THE_ENQUIRY:
    'অনুসন্ধানগুলি ভাগ করে নেওয়ার জন্য প্রাপকদের অনুসন্ধান করুন।',
  SEARCH_USERS_WHICH_WILL_PARTICIPATE_TO_THE_EVENT:
    'ব্যবহারকারীদের অনুসন্ধান করুন যা ইভেন্টে অংশ নেবে।',
  SEARCH_USER_WHICH_WILL_APPROVE_THE_STEP: 'অনুসন্ধান ব্যবহারকারী যা পদক্ষেপটি অনুমোদন করবে।',
  RULE_ENQ_ANSWER_APPROVAL_MSG: 'তদন্তের উত্তর অনুমোদন ইতিমধ্যে টিম (গুলি) এ সক্ষম হয়েছে',
  PERMISSION_EDIT_TOOLTIP: 'অনুমতি সম্পাদনা করতে ক্লিক করুন {{name}}',
  ADDITIONAL_REF_ID: '{{ TYPE }} রেফারেন্স আইডি',
  ADDITIONAL_REF_IDS_TITLE: '{{ TYPE }} রেফারেন্স আইডি',
  ADD_ADDITIONAL_REF_IDS_TOOLTIP:
    'সম্পাদনা / যুক্ত / মুছুন একটি অতিরিক্ত {{ TYPE }} রেফারেন্স আইডিএস',
  ADD_ADDITIONAL_REF_IDS: '{{ TYPE }} রেফারেন্স আইডি যুক্ত করুন',
  FWD_TO_DEPT_FAILED: 'বিভাগে ফরোয়ার্ড ব্যর্থ হয়েছে।',
  RESEND: 'পুনরায় পাঠানো',
  GDPR_NOTIF_FAILED: 'রসিদ বিজ্ঞপ্তি/জিডিপিআর ব্যর্থ হয়েছে।',
  INTERACTIONS: 'মিথস্ক্রিয়া',
  NEW_INTERACTION: 'নতুন মিথস্ক্রিয়া',
  INTERACTIONS_INBOX: 'ইন্টারঅ্যাকশন ইনবক্স',
  REFERENCE_SOURCE: 'রেফারেন্স উত্স',
  REFERENCE_ID: 'রেফারেন্স আইডি',
  REFERENCE_TYPE: 'রেফারেন্স টাইপ',
  SAVE_REFERENCE_ID: 'রেফারেন্স আইডি সংরক্ষণ করুন',
  My_open_inquiries: 'আমার উন্মুক্ত মিথস্ক্রিয়া',
  My_closed_inquiries: 'আমার বন্ধ মিথস্ক্রিয়া',
  Filter_inquiries_by: 'ফিল্টার ইন্টারঅ্যাকশন দ্বারা',
  INBOX_NO_FILTERS_HINT:
    'কোনও ফিল্টার নির্বাচিত নেই। আপনি যে ইন্টারঅ্যাকশনটি সন্ধান করছেন তা যদি আপনি খুঁজে না পান তবে আপনি সঠিক ইনবক্স বিভাগে আছেন কিনা তা পরীক্ষা করে দেখুন।',
  My_teams_open_inq: 'আমার দলের উন্মুক্ত মিথস্ক্রিয়া',
  My_teams_closed_inq: 'আমার দলের বন্ধ মিথস্ক্রিয়া',
  All_teams_open_inq: 'সমস্ত দল খোলা মিথস্ক্রিয়া খোলে',
  All_teams_closed_inq: 'সমস্ত দল ইন্টারঅ্যাকশন বন্ধ করে দিয়েছে',
  INTERACTION_REFERENCES: 'মিথস্ক্রিয়া রেফারেন্স',
  INTERACTION_REFERENCE_ID: 'মিথস্ক্রিয়া রেফারেন্স আইডি',
  INSERT_NEW_REF_SOURCE: 'নতুন রেফারেন্স উত্স sert োকান',
  BACK_TO_AVAILABLE_SOURCE: 'উপলব্ধ উত্স ফিরে যান',
  CLICK_TO_ENABLE_REF_ID_EDIT: 'রেফারেন্স আইডি সম্পাদনা সক্ষম করতে ক্লিক করুন',
  DELETE_REF_ID: 'রেফারেন্স আইডি মুছুন',
  DELETE_REF_ID_CONFIRMATION_QUESTION:
    'আপনি কি নিশ্চিত যে আপনি ইন্টারঅ্যাকশন রেফারেন্স আইডি মুছতে চান?',
  DUPLICATED_REF_ID_SOURCE_VALUE_WARNING:
    'ইতিমধ্যে একই মান সহ একটি রেফারেন্স উত্স হিসাবে বিদ্যমান রেফারেন্স উত্সগুলি যাচাই করুন দয়া করে।',
  All_teams_all_inq: 'সমস্ত দলের জন্য সমস্ত মিথস্ক্রিয়া',
  My_teams_all_inq: 'আমার দলের জন্য সমস্ত মিথস্ক্রিয়া',
  CHOOSE_EMAIL_FIELDS: 'ই-মেইল ক্ষেত্রগুলি চয়ন করুন',
  NAMES: 'নাম',
  ADR_REF_IDS: 'এডিআর রেফারেন্স আইডি',
  PQC_REF_IDS: 'পিকিউসি রেফারেন্স আইডি',
  INTERACTION_REF_IDS: 'মিথস্ক্রিয়া রেফারেন্স আইডি',
  ORIGINAL_Q_TEXT: 'মূল প্রশ্ন পাঠ্য',
  ATTACHMENTS: 'সংযুক্তি',
  FWD_QUESTION_TOOLTIP:
    'নীচের বিকল্পগুলি, ফলো-আপ প্রতিক্রিয়া এবং মূল প্রশ্ন পাঠ্য ব্যতীত কেবল তখনই ফরোয়ার্ড করা হবে যদি বিভাগের প্রশ্নগুলিতে এক বা একাধিক প্রশ্ন নির্বাচন করা হয়।',
  FWD_CHAR_TOOLTIP:
    'নীচের বিকল্পগুলি কেবল তখনই ফরোয়ার্ড করা হবে যদি এক বা একাধিক প্রশ্ন পাঠানোর জন্য বিভাগের প্রশ্নগুলিতে নির্বাচন করা হয়।',
};
